<template>
    <div class="employees-container">
        <div class="top-items">
            <div class="search-container">
                <div class="searchbar">
                    <img src="/images/icons/search-icon.svg"
                         title="search-icon">
                    <input :placeholder="ucf$t('global.search')"
                           name="search"
                           v-model="search">
                </div>
            </div>

            <router-link :to="{ name: 'customers.show.staff.create', params: $route.params, query: $route.query }"
                         class="primary"
                         tag="button">
                {{ ucf$t('user.add_employee') }}
            </router-link>
        </div>
        <div class="table">
            <div class="row header">
                <div class="column pointer"
                     @click="setOrderBy('name')">
                    {{ ucf$t("user.name") }}
                </div>
                <div class="column pointer"
                     @click="setOrderBy('phone_number')">
                    {{ ucf$t("user.phone_number") }}
                </div>
                <div class="column">
                    {{ ucf$t("user.email") }}
                </div>
                <div class="column">
                    {{ ucf$t("user.role") }}
                </div>
            </div>

            <router-link :key="user.id"
                         :to="{
                             name: 'customers.show.staff.edit',
                             params: {
                                 ...$route.params,
                                 userId: user.id
                             },
                             query: $route.query
                         }"
                         class="row"
                         v-for="user in filteredUsers">
                <div class="column">
                    <img :src="user.imageUrl"
                         class="profile"
                         v-if="user.imageUrl">
                    <div class="profile with-background"
                         v-else>
                        {{ user.firstName.charAt(0).toUpperCase() }}{{ user.lastName.charAt(0).toUpperCase() }}
                    </div>
                    <span>
                        {{ user.fullName }}
                    </span>
                </div>
                <div class="column">
                    <span>
                        {{ user.phoneNumber }}
                    </span>
                </div>
                <div class="column">
                    <span>
                        {{ user.email }}
                    </span>
                </div>
                <div class="column">
                    <span>
                        {{ user.roles.map((role) => role.name).join(',') }}
                    </span>
                </div>
            </router-link>
        </div>
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
    export default {
        name: 'CustomerShowStaff',
        data() {
            return {
                search: '',
            };
        },
        watch: {
            '$route.query.t': {
                handler() {
                    this.$parent.loadContacts();
                },
            },
        },
        computed: {
            contacts: {
                get() {
                    return this.$parent.contacts;
                },
            },
            filteredUsers: {
                get() {
                    return this.contacts.filter(contact => contact.firstName.toLowerCase().includes(this.search.toLowerCase())
                        || contact.lastName.toLowerCase().includes(this.search.toLowerCase()));
                },
            },
        },
    };
</script>
