<template>
    <router-link v-if="isMainNavbarItem && shouldShow"
                 :to="to"
                 class="navbar-item">
        <img v-if="icon != null"
             :src="icon"
             :alt="iconTitle"
             :title="iconTitle" />
        <span>{{ text }}</span>
    </router-link>
    <router-link v-else-if="isChildRoute && shouldShow"
                 :to="to">
        <span>{{ text }}</span>
    </router-link>
    <nav-bar-dropdown v-else-if="isMainNavbarDropdown && shouldShow"
                      :force-open="shouldForceOpen">
        <template #button="{open}">
            <img v-if="icon != null"
                 :src="icon"
                 :alt="iconTitle"
                 :title="iconTitle" />
            <span>{{ text }}</span>
            <img
                :class="{'dropped' : open}"
                alt="dropdown arrow"
                class="dropdown-img"
                src="/images/icons/dropdown-arrow.svg"
                title="dropdown-arrow" />
        </template>
        <template #dropdown>
            <nav-bar-component v-for="(child, index) of children"
                               v-bind="child"
                               :level="level + 1"
                               :key="`navigation-item-child-${level}-index-${index}`" />
        </template>
    </nav-bar-dropdown>
    <nav-bar-sub-dropdown v-else-if="isNavbarSubDropdown && shouldShow"
                          :force-open="shouldForceOpen">
        <template v-slot:title>
            {{ text }}
        </template>
        <template v-slot:dropdown>
            <nav-bar-component v-for="(child, index) of children"
                               v-bind="child"
                               :level="level + 1"
                               :key="`navigation-item-child-${level}-index-${index}`" />
        </template>
    </nav-bar-sub-dropdown>
</template>

<script>
    import Vue from 'vue';
    import NavBarDropdown from './NavBarDropdown';
    import NavBarSubDropdown from './NavBarSubDropdown';

    export default Vue.extend({
        name: 'NavBarComponent',
        components: { NavBarSubDropdown, NavBarDropdown },
        props: {
            icon: {
                type: String,
                required: false,
                default: () => null,
            },
            iconTitle: {
                type: String,
                required: false,
                default: () => null,
            },
            text: {
                type: String,
                required: false,
                default: () => null,
            },
            to: {
                required: false,
                default: () => null,
            },
            show: {
                required: false,
                default: () => true,
            },
            showNotificationAmount: {
                type: Boolean,
                required: false,
                default: () => false,
            },
            dropdown: {
                type: Boolean,
                required: false,
                default: () => false,
            },
            level: {
                type: Number,
                required: false,
                default: () => 1,
            },
            children: {
                type: Array,
                required: false,
                default: () => [],
            },
        },
        computed: {
            shouldForceOpen() {
                return this.wouldForceOpen(this);
            },
            shouldShow() {
                if (typeof this.show === 'function') {
                    return this.show();
                }

                return this.show;
            },
            isMainNavbarItem() {
                return this.level === 1 && this.children.length === 0;
            },
            isMainNavbarDropdown() {
                return this.level === 1 && this.dropdown;
            },
            isNavbarSubDropdown() {
                return this.level === 2 && this.dropdown;
            },
            isChildRoute() {
                return this.level === 3 && this.children.length === 0;
            },
        },
        methods: {
            wouldForceOpen(navigationItem) {
                if (navigationItem.to != null) {
                    return this.isActiveRoute(navigationItem.to);
                }

                if (navigationItem.children.length > 0) {
                    return navigationItem.children.some((child) => child.to != null ? this.isActiveRoute(child.to) : this.wouldForceOpen(child));
                }

                return false;
            },
            isActiveRoute(route) {
                try {
                    const resolved = this.$router.resolve(route);

                    return resolved.route.name === this.$route.name;
                }
                catch (_) {
                    return false;
                }
            },
        },
    });
</script>

<style scoped
       lang="scss">
</style>
