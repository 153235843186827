import { env } from 'simple-vue-env';

/**
 * The constants of roles.
 *
 * @type {{PROJECT_OWNER: number, PROJECT_GUEST: number, OU_USER_MANAGEMENT: number, OU_APPROVE_QUOTATION: number}}
 */
export const roles = {
    PROJECT_OWNER: 1,
    PROJECT_GUEST: 2,
    GLOBAL_ADMIN: 3,
    OU_USER_MANAGEMENT: 4,
    OU_APPROVE_QUOTATION: 5,
    OU_COMPANY_ADMIN: 6,
};

/**
 * The constants of roles.
 *
 * @type Array
 */
export const operators = [
    '=',
    '>',
    '>=',
    '<',
    '<=',
    '!=',
];
/**
 * The constants of formula types.
 *
 * @type {{PRICE_MATRIX: number, WIDTH: number, PERCENTAGE: number, FIXED: number}}
 */
export const formulaTypes = {
    PRICE_MATRIX: 1,
    FORMULA: 2,
    FIXED: 3,
    PERCENTAGE: 4
};

export const notificationTypes = {
    CUSTOM: 1,
    PROJECT_SHARED: 2,
};

/**
 * The constants of projects
 *
 * @type {{CANCELLATION_PERCENTAGE: *}}
 */
export const project = {
    CANCELLATION_PERCENTAGE: env('MIX_WEBO_CANCELLATION_PERCENTAGE', 15),
};

/**
 * The constants of statuses
 *
 * @type {{CANCELLATION_PERCENTAGE: *}}
 */
export const statuses = {
    OPEN: 1,
    ACCEPTED_BY_CLIENT: 2,
    FINISHED: 3,
    CANCELLED: 4,
    ACCEPTED: 5,
    IN_PRODUCTION: 6,
};
