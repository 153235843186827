<template>
    <div class="project-images">
        <transition name="detail">
            <img :src="project.imagePath"
                 v-if="project.imagePath && empty(activeFrame)">
        </transition>
        <transition name="detail">
            <div class="overlay"
                 v-if="!empty(activeFrame)">
                <img :src="activeFrame.frame.previewUrl">
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "project-images",

        computed: {
            project() {
                return this.$parent.project;
            },

            activeFrame() {
                return this.$parent.activeFrame;
            }
        },
    }
</script>

<style lang="scss">
    .project-images {
        display : flex;
        width   : 100%;
        height  : 100%;

        img {
            object-fit : contain;
        }
    }
</style>