import { roles } from '../../configuration/variables';
import SettingGeneral from '../../views/Setting/SettingGeneral';
import SettingIndex from '../../views/Setting/SettingIndex';
import SettingStaff from '../../views/Setting/SettingStaff';
import SettingStaffCreate from '../../views/Setting/SettingStaffCreate';
import SettingStaffEdit from '../../views/Setting/SettingStaffEdit';
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import ModelBoundView from "../utils/ModelBoundView";
import UserService from "../../services/UserService";

export default [
    // Settings routes
    {
        name: 'settings.index',
        path: '/settings',
        redirect: '/settings/general',
        component: SettingIndex,
        children: [
            {
                name: 'settings.general',
                path: 'general',
                component: SettingGeneral,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                },
            },
            {
                name: 'settings.staff',
                path: 'staff',
                component: SettingStaff,
                children: [
                    {
                        name: 'settings.staff.create',
                        path: 'create',
                        component: SettingStaffCreate,
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                        },
                    },
                    {
                        name: 'settings.staff.edit',
                        path: ':userId/edit',
                        component: ModelBoundView(new UserService(), SettingStaffEdit),
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN, true), role(roles.OU_USER_MANAGEMENT)],
                        },
                    },
                ],
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                },
            },
        ],
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
        },
    },
];
