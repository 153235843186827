<template>
    <base-panel ref="panel"
                class="sidebar-container"
                panel-class="sidebar"
                @overlay-clicked="close">
        <div class="header">
            <h1>
                {{ $t('Create frame group') }}
            </h1>
        </div>
        <form class="body"
              @submit.prevent="submit">
            <div class="form-group">
                <label for="name">
                    {{ $t("Category") }}
                </label>
                <multi-select id="category"
                              v-model="frameGroup.category"
                              :close-on-select="true"
                              :multiple="false"
                              :options="categoriesWithSubCategories"
                              class="w-1/4 mr-4"
                              deselect-label=""
                              label="name"
                              select-label=""
                              selected-label=""
                              track-by="id" />
            </div>
            <div class="form-group">
                <label for="name">
                    {{ $t("Name") }}
                </label>
                <input id="name"
                       ref="name"
                       v-model="frameGroup.name"
                       name="name"
                       type="text">
            </div>
            <button class="primary"
                    type="submit">
                {{ $t('Store') }}
            </button>
        </form>
    </base-panel>
</template>

<script>
    import MultiSelect from 'vue-multiselect';
    import BasePanel from '../../components/BasePanel';

    export default {
        name: 'WindowFrameGroupCreate',
        components: {
            BasePanel,
            MultiSelect,
        },
        data() {
            return {
                frameGroup: {},
                categories: [],
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'window-frame-groups.index',
                });
            },
            submit() {
                this.$http.post('frame-groups/create', this.frameGroup)
                    .then(() => {
                        this.close();
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
            reduceCategoryToOneArray(category, depth = 0) {
                return category.categories.reduce((list, sub) => {
                    let indent = '-'.repeat(depth * 4);

                    list.push(Object.assign(sub, { name: indent + sub.name }));

                    this.reduceCategoryToOneArray(sub, depth + 1)
                        .forEach((item) => list.push(item));

                    return list;
                }, [category]);
            },
        },
        computed: {
            categoriesWithSubCategories: {
                get() {
                    return this.categories.reduce((withSubCategories, category) => {
                        this.reduceCategoryToOneArray(category, 1)
                            .forEach((item) => withSubCategories.push(item));

                        return withSubCategories;
                    }, []);
                },
            },
        },
        beforeMount() {
            this.$http.get('/categories')
                .then((response) => {
                    this.categories = response.data.data;
                });
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        },
    };
</script>
