<template>
    <div class="form-elements-form">
        <h1>
            {{ $t('Add form') }}
        </h1>
        <base-input id="name"
                    name="name"
                    :label="$t('Name')"
                    v-model="form.name" />
        <div class="segmenting">
            <h2>
                {{ $t('Segments') }}
            </h2>
            <segment-group v-for="(segment,index) in form.segments"
                           :key="`${uuid}-segment-${index}`"
                           :segment="segment"
                           :option-types="optionTypes"
                           @delete="form.segments.splice(index, 1)" />
            <button type="button"
                    class="add-segment-button"
                    @click="form.segments.push({})">
                {{ $t('Add segment') }}
            </button>
        </div>
        <div class="form">
            <h2>
                {{ $t('Form') }}
            </h2>
            <form-element-group :form-element="form.formElement"
                                :segments="form.segments"
                                :show-delete-button="false"
                                :form-element-types="formElementTypes" />
        </div>

        <button class="primary"
                @click="store"
                type="button">
            {{ $t('Store') }}
        </button>
    </div>
</template>

<script>
import BaseInput from '../../components/FormElements/BaseInput';
import FormElementGroup from '../../components/FormElements/FormElementGroup';
import FormElement from '../../models/FormElement';
import SegmentGroup from '../../components/Segments/SegmentGroup';

export default {
    name: 'FormCreate',
    components: { SegmentGroup, FormElementGroup, BaseInput },
    data() {
        return {
            form: {
                formElement: new FormElement(),
                segments: [],
            },
            optionTypes: [],
            calculations: [],
        };
    },
    methods: {
        store() {
            this.$http.post('/forms/create', this.deepClone(this.form))
                .then(() => {
                    this.$router.push({ name: 'forms.index' });
                })
                .catch((e) => {
                    this.handleErrors(e.response);
                });
        },
    },
    computed: {
        formElementTypes: {
            get() {
                const baseTypes = [
                    {
                        value: 'input',
                        name: 'Input field',
                    },
                    {
                        value: 'checkbox',
                        name: 'Checkbox',
                    },
                    {
                        value: 'radio',
                        name: 'Radio buttons',
                    },
                ];

                this.form.segments.forEach((segment, index) => {
                    if (!segment || !segment.optionType) {
                        return;
                    }

                    baseTypes.push({
                        value: `segment-${index}-${segment.optionType}`,
                        name: this.$t('Segment:') + ' ' + this.$t(`${this.optionTypes.find(optionType => optionType.class === segment.optionType).translation}`),
                    });

                    baseTypes.push({
                        value: `calculation-${index}-${segment.optionType}`,
                        name: this.$t('Calculation:') + ' ' + this.$t(`${this.optionTypes.find(optionType => optionType.class === segment.optionType).translation}`),
                    });
                });

                return baseTypes;
            },
        },
    },
    beforeMount() {
        this.$http.get('/parameters/revit-options')
            .then((response) => {
                this.optionTypes = response.data;
            });

        this.$http.get('/calculations')
            .then((response) => {
                this.calculations = response.data.data;
            });
    },
};
</script>
