import ParameterLibraryIndex from "../../views/ParameterLibrary/ParameterLibraryIndex";
import ParameterLibraryCreate from "../../views/ParameterLibrary/ParameterLibraryCreate";
import ParameterLibraryEdit from "../../views/ParameterLibrary/ParameterLibraryEdit";
import ConversionIndex from "../../views/ParameterLibrary/ConversionIndex";
import ConversionCreate from "../../views/ParameterLibrary/ConversionCreate";
import ConversionEdit from "../../views/ParameterLibrary/ConversionEdit";
import auth from "../middleware/_auth";
import role from "../middleware/_role";
import { roles } from "../../configuration/variables";
import ModelBoundView from "../utils/ModelBoundView";
import ParameterService from "../../services/ParameterService";
import ParameterUpdateService from "../../services/ParameterUpdateService";

export default [
    {
        name: "parameter-library.index",
        path: "/parameter-library",
        component: ParameterLibraryIndex,
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
            isRevitOption: true,
        },
        children: [
            {
                name: "parameter-library.create",
                path: "create",
                component: ParameterLibraryCreate,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                    isRevitOption: true,
                }
            },
            {
                name: "parameter-library.edit",
                path: ":parameterId/edit",
                component: ModelBoundView(new ParameterService(), ParameterLibraryEdit),
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                    isRevitOption: true,
                }
            }
        ]
    },
    {
        name: "parameter-library.conversions",
        path: "/parameter-library/conversions",
        component: ConversionIndex,
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN)],
            isRevitOption: true,
        },
        children: [
            {
                name: "parameter-library.conversions.create",
                path: "create",
                component: ConversionCreate,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                    isRevitOption: true,
                }
            },
            {
                name: "parameter-library.conversions.edit",
                path: ":parameterUpdateId/edit",
                component: ModelBoundView(new ParameterUpdateService(), ConversionEdit),
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                    isRevitOption: true,
                }
            }
        ]
    }
];
