<template>
    <base-modal ref="modal">
        <template v-slot:header>
            {{ ucf$t("projects.edit") }}
        </template>
        <div class="form-group">
            <label for="name">
                {{ ucf$t("projects.name") }}
            </label>
            <input id="name"
                   ref="name"
                   type="text"
                   v-model="project.name">
        </div>
        <div class="form-group">
            <label for="description">
                {{ ucf$t("projects.description") }}
            </label>
            <textarea id="description"
                      ref="description"
                      v-model="project.description" />
        </div>
        <div class="form-group"
             v-if="project.contacts">
            <label for="contactPerson">
                {{ ucf$t("projects.contact") }}
            </label>
            <multiselect :close-on-select="true"
                         :multiple="false"
                         :options="project.contacts"
                         class="w-1/4 mr-4"
                         deselect-label=""
                         id="contactPerson"
                         label="fullName"
                         select-label=""
                         selected-label=""
                         track-by="id"
                         v-model="project.contactPerson" />
        </div>
        <template v-slot:footer>
            <button class="primary"
                    @click="submitForm">
                {{ $t("Save") }}
            </button>
        </template>
    </base-modal>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import BaseModal from "../../components/BaseModal";

    export default {
        name: 'ProjectEdit',
        components: { BaseModal, Multiselect },
        methods: {
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: { projectId: this.project.id },
                });
            },
            submitForm() {
                const vm = this;
                const data = {
                    name: this.project.name,
                    description: this.project.description,
                    contactPerson: this.project.contactPerson.id ? this.project.contactPerson.id : null,
                };

                this.$http.post(`projects/${this.project.id}`, data)
                    .then(() => {
                        vm.$router.replace({
                            name: 'projects.show',
                            params: { projectId: this.project.id },
                        });
                    })
                    .catch((error) => {
                        vm.handleErrors(error.response);
                    });
            },
        },
        computed: {
            project() {
                return Object.assign({}, this.$parent.project);
            },
        },
        mounted() {
            this.$refs.modal.open();
        },
    };
</script>
