<template>
    <div class="form-group">
        <select :id="id"
                :name="name"
                :value="value"
                :title="label"
                @input="onInput">
            <option :value="undefined">
                {{ label }}
            </option>
            <option v-for="(option, index) in options"
                    :key="`${uuid}-${index}`"
                    :value="get(option, valueKey)">
                <slot name="option"
                      v-bind="option">
                    {{ $t(get(option, displayKey, 'hoi')) }}
                </slot>
            </option>
        </select>
    </div>
</template>

<script>
    import get from 'lodash/get';

    export default {
        name: 'BigSelect',
        props: {
            value: {},
            label: {
                required: true,
                type: String,
            },
            id: {
                required: true,
                type: String,
            },
            name: {
                required: true,
                type: String,
            },
            type: {
                required: false,
                type: String,
                default() {
                    return 'text';
                },
            },
            options: {
                type: Array,
                required: true,
            },
            displayKey: {
                type: String,
                required: false,
                default() {
                    return 'name';
                },
            },
            valueKey: {
                type: String,
                required: false,
                default() {
                    return 'value';
                },
            },
        },
        methods: {
            get,
            onInput(e) {
                this.$emit('input', e.target.value);
            },
        },
    };
</script>
