<template>
    <base-modal ref="modal">
        <template v-slot:header>
            {{ $t('Delete %{name}', { name: 'Revit type' }) }}
        </template>
        <p>
            {{ $t("You sure you want to delete this %{name}", { name: 'Revit type' }) }}
        </p>
        <template v-slot:footer>
            <button @click="confirm"
                    class="primary">
                {{ $t("Yes") }}
            </button>
            <button @click="close"
                    class="secondary">
                {{ $t("No") }}
            </button>
        </template>
    </base-modal>
</template>

<script>
    import BaseModal from "../../components/BaseModal";

    export default {
        name: 'RevitTypeDelete',

        components: {
            BaseModal,
        },

        methods: {

            close() {
                this.$router.replace({
                    name: 'revit-types.index',
                    params: this.$route.params,
                });
            },

            confirm() {
                this.$http
                    .post(`revit-types/${this.$route.params.revitTypeId}/delete`, {})
                    .then(this.close)
                    .catch((error) => this.handleErrors(error.response));
            },
        },

        mounted() {
            this.$refs.modal.open();
        },
    };
</script>
