<template>
    <div class="form-group">
        <label :for="id">
            {{ label }}
        </label>
        <input :id="id"
               :name="name"
               :type="type"
               :value="value"
               @input="onInput">
    </div>
</template>

<script>
    export default {
        name: 'BaseInput',
        props: {
            value: {},
            label: {
                required: true,
                type: String,
            },
            id: {
                required: true,
                type: String,
            },
            name: {
                required: true,
                type: String,
            },
            type: {
                required: false,
                type: String,
                default() {
                    return 'text';
                },
            },
        },
        methods: {
            onInput(e) {
                this.$emit('input', e.target.value);
            },
        },
    };
</script>
