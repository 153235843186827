export default function role(_roles, invert = false) {
    let roles = _roles;

    if (!Array.isArray(_roles)) {
        roles = [_roles];
    }

    return ({ from, next, app }) => {
        if (!app.$user) {
            return next(from);
        }
        const intersection = app.$user.roles.filter(r => roles.includes(r.id));

        if ((intersection.length === 0) !== invert) {
            return next(from);
        }

        return next();
    };
}
