import axios from 'axios';
import { env } from 'simple-vue-env';

function createClient(baseUrl = null, headers = {}, errorHandlers = []) {
    const instance = axios.create({
        baseURL: baseUrl,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });

    Object.entries(headers)
        .forEach(([header, value]) => {
            instance.defaults.headers.common[header] = value;
        });

    instance.interceptors.response.use(
        response => response,
        (error) => {
            if (typeof error === 'undefined') {
                return Promise.reject(error);
            }

            const { status } = error.response;

            if (typeof errorHandlers[status] !== 'undefined') {
                errorHandlers[status](error);
            }

            return Promise.reject(error);
        },
    );

    return instance;
}

class Api {

    constructor(baseUrl = null, headers = {}) {
        if (Api._instance) {
            return Api._instance;
        }

        Api._instance = this;

        this._axios = createClient(baseUrl || env('MIX_APP_API_URL'), headers, Api.handlers);

        return this;
    }

    get(slug, params = {}, cache = true) {
        return this._axios.get(slug, { params });
    }

    post(url, parameters, headers) {
        return this._axios.post(url, parameters, headers);
    }

    put(url, parameters) {
        if (parameters instanceof FormData) {
            parameters.append('_method', 'put');
        } else {
            parameters._method = 'put';
        }

        return this.post(url, parameters);
    }

    patch(url, parameters) {
        if (parameters instanceof FormData) {
            parameters.append('_method', 'patch');
        } else {
            parameters._method = 'patch';
        }

        return this.post(url, parameters);
    }

    delete(url) {
        return this.post(url, { _method: 'delete' });
    }

    request(method, url, parameters) {
        switch (method) {
            case 'get':
                return this.get(url, parameters);
            case 'post':
                return this.post(url, parameters);
            case 'put':
                return this.put(url, parameters);
            case 'patch':
                return this.patch(url, parameters);
            case 'delete':
                return this.delete(url, parameters);
            default:
                throw new Error(`Method ${method} does not exist.`);
        }
    }
}

Api.handlers = {};

export const ApiClient = Api;

const api = new Api();

export default api;
