<template>
    <div v-if="project.hasFrames === true">
        <div class="bottom-bar"
             v-if="canAccept">
            <router-link :to="{ name: 'projects.confirm_approval', params: { project: project.id } }"
                         class="primary"
                         tag="button">
                {{ ucf$t("projects.accept") }}
            </router-link>
        </div>
        <div class="bottom-bar"
             v-if="showImagesButton">
            <router-link :to="{ name: 'projects.show', params: { project: project.id } }"
                         class="primary"
                         tag="button">
                {{ $t("Show project images") }}
            </router-link>
        </div>
        <div class="bottom-bar"
             v-if="showBatchesButton">
            <router-link :to="{ name: 'projects.batches', params: { project: project.id } }"
                         class="primary"
                         tag="button">
                {{ $t("Show batches") }}
            </router-link>
        </div>
        <div class="bottom-bar"
             :class="{'disabled' : isDisabled}"
             v-if="showDownloadInvoice">
            <label v-if="isDisabled">
                {{ ucf$t("projects.download_invoice_text") }}
            </label>
            <button class="primary"
                    :class="{'disabled' : isDisabled}">
                {{ ucf$t("projects.download_invoice") }}
            </button>
        </div>
        <div class="bottom-bar"
             v-if="showAcceptByWEBO">
            <button class="primary"
                    @click="acceptedByWEBO">
                {{ ucf$t("projects.accept") }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "bottom-bar",

        methods: {
            acceptedByWEBO() {
                this.$http.post(`/projects/${this.project.id}/status`, {
                    projectStatus: this.statuses.ACCEPTED,
                }).then(() => {
                    this.$router.go();
                }).catch((error) => {
                    this.handleErrors(error.response);
                });
            },
        },

        computed: {
            project() {
                return this.$parent.project;
            },
            canAccept() {
                let hasRole = false;
                this.authUser.roles.forEach(role => {
                    if (role.id === this.roles.OU_APPROVE_QUOTATION) {
                        hasRole = true;
                    }
                });
                return hasRole
                    && this.project.projectStatus.id === this.statuses.OPEN
                    && this.project.role.id === this.roles.PROJECT_OWNER
                    && this.$router.currentRoute.name !== 'projects.batches'
            },
            showImagesButton() {
                return this.$router.currentRoute.name === 'projects.batches'
                    && this.project.projectStatus.id > this.statuses.OPEN
            },
            showBatchesButton() {
                return this.$router.currentRoute.name === 'projects.show'
                    && this.project.projectStatus.id > this.statuses.OPEN;
            },
            showAcceptByWEBO() {
                let hasRole = false;
                this.authUser.roles.forEach(role => {
                    if (role.id === this.roles.GLOBAL_ADMIN) {
                        hasRole = true;
                    }
                });

                return false
                    && hasRole
                    && this.project.projectStatus.id === this.statuses.ACCEPTED_BY_CLIENT;
            },
            showDownloadInvoice() {
                return false
                    && !this.showAcceptByWEBO
                    && this.project.projectStatus.id !== this.statuses.OPEN
                    && this.project.projectStatus.id !== this.statuses.CANCELLED
            },
            isDisabled() {
                return this.project.projectStatus.id === this.statuses.ACCEPTED_BY_CLIENT
            },
        }
    }
</script>