<template>
    <base-overview slug="revit-types"
                   default-search-column="name"
                   ref="overview">
        <template v-slot:head>
            <h1>{{ $t("Revit types") }}</h1>
            <router-link :to="{ name: 'revit-types.create' }"
                         class="btn primary">
                {{ $t("Create %{name}", {name: 'Revit type'}) }}
            </router-link>
        </template>
        <template v-slot:table-head>
            <div @click="$refs.overview.setOrderBy('name')"
                 class="column pointer">
                {{ $t("Name") }}
            </div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>
                    {{ item.name }}
                </span>
            </div>
        </template>
        <template v-slot:no-more>
            {{ $t('These were all %{name}', {name : 'Revit types'}) }}
        </template>
    </base-overview>
</template>

<script>
    import BaseOverview from '../../components/BaseOverview';

    export default {
        name: 'RevitTypeIndex',
        components: {
            BaseOverview,
        },

        methods: {
            getRoute(revitType) {
                return {
                    name: 'revit-types.edit',
                    params: {
                        revitTypeId: revitType.id,
                    },
                };
            },
        },
    };
</script>
