<template>
    <form-panel @overlay-clicked="close"
                @submit="submit"
                ref="formPanel">
        <template v-slot:header>
            <h1>
                {{ $t('Edit %{name}', { name: revitType.key }) }}
            </h1>
            <div class="edit"
                 @click="toggleDrop">
                <img alt="edit"
                     src="/images/icons/dots-icon.svg">
                <div :class="{'active' : showDrop}"
                     class="drop">
                    <router-link
                        :to="{ name: 'revit-options.delete', params: {
                            revitOption: revitOption,
                            revitTypeId: revitType.id,
                            revitOptionId: revitOption.id,
                        }}"
                        tag="button">
                        {{ $t('Delete %{name}', { name: revitOption.key }) }}
                    </router-link>
                </div>
            </div>
            <div class="name">
                <h2>
                    {{ revitOption.key }}
                </h2>
            </div>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="key">
                    {{ $t('Name') }}
                </label>
                <input id="key"
                       name="key"
                       ref="key"
                       type="text"
                       v-model="revitOption.key">
            </div>
            <div class="form-group">
                <label for="value">
                    {{ $t('Value') }}
                </label>
                <input id="value"
                       name="value"
                       ref="value"
                       type="text"
                       v-model="revitOption.value">
            </div>
            <file-input :value="revitOption.image"
                        @input="optionImageChanged"
                        class="revit-option-image"
                        default="/">
                <template v-slot:default="{image,isLoading}">
                    <img :src="image"
                         alt="logo"
                         class="revit-option-image-preview"
                         v-if="!isLoading && image !== '/'">

                    <span class="revit-option-image-preview revit-option-image-preview-placeholder"
                          v-else />

                    <span class="revit-option-image-text">
                        Afbeelding wijzigen
                    </span>
                </template>
            </file-input>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ $t('Store') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FileInput from '../../components/FileInput';
    import FormPanel from '../../components/FormPanel';

    export default {
        name: 'RevitOptionEdit',

        components: {
            FileInput,
            FormPanel,
        },

        data() {
            return {
                showDrop: false,
            };
        },

        methods: {
            optionImageChanged(image) {
                if (image.constructor !== File) {
                    return;
                }

                this.revitOption.image = image;
            },

            toggleDrop() {
                this.showDrop = !this.showDrop;
            },

            close() {
                this.$router.replace({
                    name: 'revit-options.index',
                    params: this.$route.params,
                });
            },

            submit() {
                const formData = new FormData();

                formData.append('key', this.revitOption.key);
                formData.append('value', this.revitOption.value);

                if (this.revitOption.image.constructor === File) {
                    formData.append('image', this.revitOption.image);
                }

                this.$http.post(
                    `revit-types/${this.revitType.id}/options/${this.revitOption.id}/edit`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                ).then(this.close).catch((error) => {
                    this.handleErrors(error.response);
                });
            },
        },

        computed: {
            revitType:
                {
                    get() {
                        return this.$parent.$parent.revitType;
                    },
                },
        },

        mounted() {
            this.$refs.formPanel.open();
        },

        beforeRouteLeave(to, from, next) {
            this.$refs.formPanel.beforeRouteLeave(to, from, next);
        },
    };
</script>
