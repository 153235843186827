var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-panel',{ref:"formPanel",on:{"overlay-clicked":_vm.close,"submit":_vm.submit},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',[_vm._v("\n            "+_vm._s(_vm.$t('Edit %{name}', { name: _vm.revitType.key }))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"edit",on:{"click":_vm.toggleDrop}},[_c('img',{attrs:{"alt":"edit","src":"/images/icons/dots-icon.svg"}}),_vm._v(" "),_c('div',{staticClass:"drop",class:{'active' : _vm.showDrop}},[_c('router-link',{attrs:{"to":{ name: 'revit-options.delete', params: {
                        revitOption: _vm.revitOption,
                        revitTypeId: _vm.revitType.id,
                        revitOptionId: _vm.revitOption.id,
                    }},"tag":"button"}},[_vm._v("\n                    "+_vm._s(_vm.$t('Delete %{name}', { name: _vm.revitOption.key }))+"\n                ")])],1)]),_vm._v(" "),_c('div',{staticClass:"name"},[_c('h2',[_vm._v("\n                "+_vm._s(_vm.revitOption.key)+"\n            ")])])]},proxy:true},{key:"form",fn:function(){return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"key"}},[_vm._v("\n                "+_vm._s(_vm.$t('Name'))+"\n            ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.revitOption.key),expression:"revitOption.key"}],ref:"key",attrs:{"id":"key","name":"key","type":"text"},domProps:{"value":(_vm.revitOption.key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.revitOption, "key", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"value"}},[_vm._v("\n                "+_vm._s(_vm.$t('Value'))+"\n            ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.revitOption.value),expression:"revitOption.value"}],ref:"value",attrs:{"id":"value","name":"value","type":"text"},domProps:{"value":(_vm.revitOption.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.revitOption, "value", $event.target.value)}}})]),_vm._v(" "),_c('file-input',{staticClass:"revit-option-image",attrs:{"value":_vm.revitOption.image,"default":"/"},on:{"input":_vm.optionImageChanged},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var image = ref.image;
                    var isLoading = ref.isLoading;
return [(!isLoading && image !== '/')?_c('img',{staticClass:"revit-option-image-preview",attrs:{"src":image,"alt":"logo"}}):_c('span',{staticClass:"revit-option-image-preview revit-option-image-preview-placeholder"}),_vm._v(" "),_c('span',{staticClass:"revit-option-image-text"},[_vm._v("\n                    Afbeelding wijzigen\n                ")])]}}])})]},proxy:true},{key:"footer",fn:function(){return [_c('button',{staticClass:"primary",attrs:{"type":"submit"}},[_vm._v("\n            "+_vm._s(_vm.$t('Store'))+"\n        ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }