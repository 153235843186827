<template>
    <div class="segment"
         :class="{hovered}">
        <big-input :id="`length-${uuid}`"
                   :name="`length-${uuid}`"
                   label="Length"
                   v-model="segment.length" />
        <div class="form-group">
            <select id="optionType"
                    name="optionType"
                    v-model="segment.optionType">
                <option :value="undefined">
                    {{ $t('Select a type') }}
                </option>
                <option v-for="(option, index) in optionTypes"
                        :key="`${uuid}-${index}`"
                        :value="option.class">
                    <slot name="option"
                          v-bind="option">
                        {{ $t(option.translation) }}
                    </slot>
                </option>
            </select>
        </div>
        <button type="button"
                class="delete-segment-button"
                @click="$emit('delete')">
            <img src="/images/icons/delete.svg"
                 alt="Delete icon">
        </button>
    </div>
</template>

<script>
    import BigSelect from '../FormElements/BigSelect';
    import BigInput from '../FormElements/BigInput';

    export default {
        name: 'SegmentGroup',
        components: { BigInput, BigSelect },
        props: {
            segment: {
                type: Object,
                required: true,
            },
            optionTypes: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                hovered: false,
            };
        },
    };
</script>
