import authRoutes from './_auth';
import customers from './_customers';
import forms from './_forms';
import windowFrames from './_window-frames';
import windowFrameGroups from './_window-frame-groups';
import notifications from './_notifications';
import onboarding from './_onboarding';
import priceMatrices from './_price-matrices';
import projects from './_projects';
import settings from './_settings';
import modules from './_modules';
import categories from './revit-management/_categories';
import adminSettings from './_admin-settings';
import projectParameters from './_project-parameters';
import parameterLibrary from './_parameter-library';
import formulas from './revit-management/_formulas';
import revitTypes from './revit-management/_revit-types';
import revitOptions from './revit-management/_revit-options';
import prioritizations from './_prioritizations';

export default [
    // Default redirect
    {
        path: '/',
        redirect: '/projects',
    },

    ...authRoutes,
    ...forms,
    ...modules,
    ...customers,
    ...windowFrames,
    ...windowFrameGroups,
    ...notifications,
    ...onboarding,
    ...priceMatrices,
    ...projects,
    ...settings,
    ...categories,
    ...adminSettings,
    ...projectParameters,
    ...parameterLibrary,
    ...formulas,
    ...revitTypes,
    ...revitOptions,
    ...prioritizations,

    // Default redirect
    {
        path: '*',
        redirect: '/projects',
    },
];
