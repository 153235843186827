<template>
    <div>
        <div class="search-container">
            <div class="searchbar">
                <img src="/images/icons/search-icon.svg" title="search-icon" />
                <input
                        :placeholder="$t('Search..')"
                        :title="$t('Search..')"
                        @input="setSearch"
                        name="search"
                        v-model="search"
                />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <h3>{{ $t('Unlinked formulas') }}</h3>
                <div class="table">
                    <div class="row header">
                        <div class="column">{{ $t('Name') }}</div>
                    </div>
                    <div
                            :key="formula.id"
                            class="row"
                            v-for="formula in filteredFormulas"
                            @click="toggleFormula(formula)"
                    >
                        <div class="column">{{ formula.name }}</div>
                    </div>
                    <infinite-loading @infinite="loadFormulas" ref="infiniteLoading" spinner="spiral">
                        <div class="no-results" slot="no-more">{{ $t('These were all formulas') }}</div>
                    </infinite-loading>
                </div>
            </div>
            <div class="column">
                <h3>{{ $t('Linked formulas') }}</h3>
                <div class="table">
                    <div class="row header">
                        <div class="column">{{ $t('Name') }}</div>
                    </div>
                    <div
                            :key="formula.id"
                            class="row"
                            v-for="formula in filteredFrameFormulas"
                            @click="toggleFormula(formula)"
                    >
                        <div class="column">{{ formula.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseOverview from "../../components/BaseOverview";

    export default {
        name: "WindowFrameFormulas",
        components: { BaseOverview },
        data() {
            return { formulas: [], page: 1, search: "" };
        },
        methods: {
            loadFormulas($state) {
                this.$http
                    .get("/formulas", { page: this.page, search: this.search })
                    .then(({ data }) => {
                        if (data.data.length === 0) $state.complete();
                        this.formulas = this.formulas.concat(data.data);
                        this.page++;
                        $state.loaded();
                    });
            },
            toggleFormula(formula) {
                let index = this.frameFormulas.indexOf(formula);
                if (index === -1) this.frameFormulas.push(formula);
                else this.frameFormulas.splice(index, 1);
            },
            setSearch: debounce(function _(e) {
                this.formulas = [];
                this.page = 1;
                this.$refs.infiniteLoading.stateChanger.reset();
            }, 500)
        },
        computed: {
            frameFormulas: {
                get() {
                    return this.$parent.frame.formulas || [];
                }
            },
            filteredFormulas() {
                return this.formulas.filter(
                    e => !this.frameFormulas.find(x => x.id === e.id)
                );
            },
            filteredFrameFormulas() {
                return this.frameFormulas.filter(e =>
                    e.name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    };
</script>

<style lang="scss" scoped>
    .columns {
        display : flex;

        .column {
            width   : 50%;
            padding : 10px;
        }
    }
</style>
