<template>
    <div class="condition-group">
        <button type="button"
                @click="$emit('remove')"
                class="btn remove top-right">
            {{ $t('Remove condition group') }}
        </button>
        <div class="form-group">
            <label for="type">
                Vergelijkingsmethode
            </label>
            <select v-model="conditionGroup.type"
                    name="type"
                    id="type">
                <option value="&">
                    AND
                </option>
                <option value="|">
                    OR
                </option>
            </select>
        </div>
        <div class="condition"
             v-for="(condition, conditionIndex) in conditionGroup.conditions"
             :key="`${conditionIndex}-form-condition`">
            <div class="form-group">
                <label for="parameter">{{ $t("Parameter") }}</label>
                <select id="parameter" name="parameter" v-model="condition.parameter">
                    <option :value="undefined">
                        {{ $t('Select a parameter') }}
                    </option>
                    <option :value="parameter"
                            v-for="(parameter, index) of parameters"
                            :key="`${index}-parameter`">
                        {{ parameter.name }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="operator">{{ $t("Operator") }}</label>
                <select id="operator" name="operator" v-model="condition.operator">
                    <option :value="undefined">
                        {{ $t('Select an operator') }}
                    </option>
                    <option :value="operator"
                            :key="`${conditionIndex}-${index}-operator`"
                            v-for="(operator, index) of operators">
                        {{ $t(operator) }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="value">{{ $t("Value") }}</label>
                <input id="value" name="value" ref="value" type="text" v-model="condition.value" />
            </div>
            <button type="button"
                    @click="removeCondition(conditionIndex)"
                    class="btn remove">
                <img :alt="$t('Remove condition')" src="/images/icons/close-icon.svg" />
            </button>
        </div>
        <button type="button"
                class="btn primary"
                @click="addCondition">
            {{ $t('Add condition') }}
        </button>
    </div>
</template>

<script>
    export default {
        name: "ConditionGroup",
        model: {
            prop: 'conditionGroup',
            event: 'change',
        },
        props: {
            conditionGroup: {
                required: true,
                type: Object,
            },
            parameters: {
                type: Array,
                required: true,
            },
        },
        watch: {
            conditionGroup: {
                deep: true,
                handler(conditionGroup) {
                    this.$emit('change', conditionGroup);
                },
            },
        },
        methods: {
            addCondition() {
                this.conditionGroup.conditions.push({});
            },
            removeCondition(conditionIndex) {
                this.conditionGroup.conditions.splice(conditionIndex, 1);
            },
        },
    }
</script>

<style lang="scss">
    .condition-group {
        margin-bottom : 16px;
        border        : 1px solid rgba(0, 0, 0, .32);
        border-radius : 8px;
        padding       : 56px 24px 24px 24px;

        .btn.remove.top-right {
            position    : absolute;
            top         : 24px;
            right       : 24px;
            display     : flex;
            width       : unset;
            padding     : 0 16px 0 16px;
            font-size   : 12px;
            line-height : 32px;
            color       : rgba(0, 0, 0, .8);
        }

        .condition {
            display               : grid;
            grid-template-columns : 1fr 1fr 1fr 32px;
            grid-gap              : 12px;

            .form-group {
                width : 100%;
            }
        }

        .btn.primary {
            position : relative;
            right    : 0;
        }
    }
</style>