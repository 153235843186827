<template>
    <form-panel
            @overlay-clicked="close"
            @submit.prevent="submit"
            class="sidebar-container"
            panel-class="sidebar"
            ref="panel"
    >
        <template v-slot:header>
            <h1>{{ $t('Update parameter') }}</h1>
            <div class="edit" @click="toggleDrop">
                <img alt="edit" src="/images/icons/dots-icon.svg" />
                <div :class="{'active' : showDrop}" class="drop">
                    <button type="button" @click="deleteParameter">{{ $t("Delete parameter") }}</button>
                </div>
            </div>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="name">{{ $t('Name') }}</label>
                <input id="name" name="name" ref="name" type="text" v-model="parameter.name" />
            </div>
            <div class="form-group">
                <label for="ParameterName">{{ $t('Parameter name') }}</label>
                <input
                        id="ParameterName"
                        name="ParameterName"
                        ref="ParameterName"
                        type="text"
                        v-model="parameter.parameterName"
                />
            </div>
            <div class="form-group">
                <label for="formName">{{ $t('Form') }}</label>
                <select id="formName" name="formName" v-model="parameter.formId">
                    <option :value="undefined" />
                    <option :value="form.id" v-for="(form, index) of forms" :key="index">{{ form.name }}</option>
                </select>
            </div>

            <div class="form-group">
                <label for="conditions">
                    {{ $t('Conditions') }}
                </label>
                <textarea disabled
                          id="conditions"
                          name="conditions"
                          ref="conditions"
                          style="font-family: monospace;"
                          v-text="($refs.conditionsModal || {}).conditionString" />
            </div>
            <button @click="$refs.conditionsModal.open()"
                    class="secondary no-bottom"
                    type="button">
                {{ $t('Change conditions') }}
            </button>
            <conditions-modal ref="conditionsModal"
                              :parameters="parameters"
                              :type="parameter.name"
                              v-model="parameter.conditions" />
            <button type="submit" class="primary">{{ $t('Update') }}</button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from "../../components/FormPanel";
    import ConditionsModal from "../../components/ConditionsModal";

    export default {
        name: "ParameterLibraryEdit",

        components: {
            ConditionsModal,
            FormPanel
        },

        data() {
            return {
                forms: [],
                parameters: [],
                showDrop: false
            };
        },

        methods: {
            submit() {
                this.$http
                    .post(`parameters/${this.parameter.id}/edit`, this.parameter)
                    .then(() => this.close())
                    .catch((e) => {
                        this.handleErrors(e.response);
                    });
            },

            close() {
                this.$router.replace({
                    name: "parameter-library.index"
                });
            },

            toggleDrop() {
                this.showDrop = !this.showDrop;
            },

            deleteParameter() {
                this.$http
                    .delete(`parameters/${this.parameter.id}`, this.parameter)
                    .then(() => {
                        this.close();
                    });
            }
        },

        beforeMount() {
            this.$http.get("/forms").then(response => {
                this.forms = response.data.data;
            });

            this.$http.get("/parameters", { take: 0 })
                .then(({ data }) => (this.parameters = data.data));
        },

        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        }
    };
</script>
