<template>
    <div class="form-group">
        <label for="percentage">{{ $t('Percentage') }}</label>
        <input
                id="percentage"
                name="percentage"
                type="number"
                min="0"
                max="100"
                v-model="formula.percentage"
        />
    </div>
</template>

<script>
    export default {
        props: ["formula"]
    };
</script>
