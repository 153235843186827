<template>
    <base-modal ref="modal">
        <template v-slot:header>
            {{ $t('Delete %{name}', {name: revitOption.key}) }}
        </template>
        <p>
            {{ $t('You sure you want to delete this %{name}', {name: revitOption.key}) }}
        </p>
        <template v-slot:footer>
            <button @click="confirm"
                    class="primary">
                {{ $t('Yes') }}
            </button>
            <button @click="close"
                    class="primary">
                {{ $t('No') }}
            </button>
        </template>
    </base-modal>

</template>

<script>
    import BaseModal from '../../components/BaseModal';

    export default {
        name: 'RevitTypeDelete',

        props: {
            revitOption: {
                required: true,
                type: Object,
            },
        },

        components: {
            BaseModal,
        },

        methods: {

            close() {
                this.$router.replace({
                    name: 'revit-options.index',
                    params: this.$route.params,
                });
            },

            confirm() {
                this.$http
                    .post(
                        `revit-types/${this.$route.params.revitTypeId}/options/${this.$route.params.revitOptionId}/delete`,
                        {})
                    .then(this.close)
                    .catch((error) => this.handleErrors(error.response));
            },
        },

        mounted() {
            this.$refs.modal.open();
        },
    };
</script>
