<template>
    <div class="frame-general">
        <div class="form-group">
            <label for="name">
                {{ $t("Name") }}
            </label>
            <input id="name"
                   name="name"
                   ref="name"
                   type="text"
                   v-model="$parent.frame.name">
        </div>
        <div class="form-group">
            <label for="module">
                {{ $t("Module") }}
            </label>
            <select id="module"
                    name="module"
                    ref="module"
                    type="text"
                    v-model="$parent.frame.module">
                <option :value="undefined"
                        disabled>
                    {{ $t('Select a module') }}
                </option>
                <option :value="module.id"
                        :key="`module-${module.id}`"
                        v-for="module of modules">
                    {{ module.name }}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label for="preview">
                {{ $t("Preview") }}
            </label>
            <file-input :default="null"
                        id="preview"
                        file-type="image/*"
                        v-model="$parent.frame.preview">
                <template #default="{image}">
                    <img :src="image"
                         alt="Image"
                         v-if="image">
                    <img :alt="$parent.frame.name"
                         :src="$parent.frame.previewUrl"
                         v-else-if="!!$parent.frame.previewUrl">
                    <div class="img-placeholder"
                         v-else />
                    <span>
                        {{ $t('Upload image') }}
                    </span>
                </template>
            </file-input>
        </div>
        <!--<div class="form-group">-->
        <!--    <label for="cnc">-->
        <!--        {{ $t("CNC File") }}-->
        <!--    </label>-->
        <!--    <file-input :create-base64="false"-->
        <!--                :default="null"-->
        <!--                file-type=".cnc"-->
        <!--                v-model="$parent.frame.cncFile"-->
        <!--                id="cnc">-->
        <!--        <template #default="{image}">-->
        <!--            <img alt="Checkmark Icon"-->
        <!--                 class="checkmark"-->
        <!--                 src="/images/icons/check-pink-icon.svg"-->
        <!--                 v-if="$parent.frame.cncFile">-->
        <!--            <div class="img-placeholder"-->
        <!--                 v-else />-->
        <!--            <span>-->
        <!--                {{ $t('Upload CNC') }}-->
        <!--            </span>-->
        <!--        </template>-->
        <!--    </file-input>-->
        <!--</div>-->
    </div>
</template>

<script>
    import FileInput from '../../components/FileInput';

    export default {
        name: 'WindowFrameGeneral',
        components: { 'file-input': FileInput },
        data() {
            return {
                modules: [],
            };
        },

        beforeMount() {
            this.$http.get('/modules')
                .then((response) => {
                    this.modules = response.data.data;
                });
        },
    };
</script>
