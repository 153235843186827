<template>
    <base-panel panel-class="modal project-modal"
                @overlay-clicked="close"
                ref="panel">
        <div class="header">
            {{ ucf$t("projects.object_amount") }}
        </div>
        <div class="body">
            <div class="form-group">
                <label for="amount">
                    {{ ucf$t("projects.amount") }}
                </label>
                <input id="amount"
                       ref="amount"
                       type="text"
                       v-model="amount"
                       autofocus>
            </div>
            <button class="primary"
                    @click="submitForm"
                    v-if="frame && frame.batchAmount === undefined">
                {{ ucf$t("projects.batches_add_item") }}
            </button>
            <div v-else
                 class="actions">
                <a @click="removeObject">
                    {{ ucf$t("projects.object_delete") }}
                </a>
                <button class="primary"
                        @click="submitForm">
                    {{ ucf$t("projects.object_save") }}
                </button>
            </div>
        </div>
    </base-panel>
</template>

<script>
    import BasePanel from '../../components/BasePanel';

    export default {
        name: 'ProjectBatchAmount',
        components: {
            BasePanel,
        },
        data() {
            return {
                value: null,
                maxBatchAmount: process.env.MIX_WEBO_MAX_BATCH_AMOUNT,
            };
        },
        methods: {
            close() {
                const tempArray = [];
                this.selectedBatch.forEach((frame) => {
                    if (isNaN(frame.batchAmount) || frame.batchAmount <= 0) {
                        return;
                    }

                    const index = tempArray.findIndex(f => f.id === frame.id);
                    index >= 0 ? tempArray[index].batchAmount += frame.batchAmount : tempArray.push(frame);
                });
                this.batches[this.batches.indexOf(this.selectedBatch)] = tempArray;

                this.$parent.$refs.batches.$forceUpdate();
                this.$router.back();
            },
            submitForm() {
                const amount = parseInt(this.amount);
                if (amount < 1 || isNaN(amount)) {
                    this.showCustomError(this.ucf$t('projects.batches_frame_min'), 'error');
                    return;
                }

                const frameUsages = this.batches.flatMap(batch => batch.filter(frame => frame.id === this.frame.id))
                    .filter(Boolean);
                let usedAmount = frameUsages.filter(frame => frame.batchAmount !== undefined)
                    .reduce((total, frame) => total + frame.batchAmount, 0);

                if (this.frame.batchAmount !== undefined) {
                    usedAmount -= this.frame.batchAmount;
                }
                if (amount > this.frame.amount || (usedAmount + amount) > this.frame.amount) {
                    this.showCustomError(this.ucf$t('projects.batches_frame_max'), 'error');
                    return;
                }

                let batchAmount = this.selectedBatch.filter(frame => frame.batchAmount !== undefined)
                    .reduce((total, frame) => total + frame.batchAmount, 0);

                if (this.frame.batchAmount !== undefined) {
                    batchAmount -= this.frame.batchAmount;
                }
                if ((batchAmount + amount) > this.maxBatchAmount) {
                    this.showCustomError(this.ucf$t('projects.batches_max'), 'error');
                    return;
                }

                this.frame.batchAmount = amount;
                this.close();
            },

            removeObject() {
                this.frame.batchAmount = undefined;
                this.close();
            },
        },
        computed: {
            project() {
                return Object.assign({}, this.$parent.project);
            },
            projectBatches() {
                return this.$parent.$refs.batches.$data;
            },
            frame() {
                return this.projectBatches.currentFrame;
            },
            selectedBatch() {
                return this.projectBatches.currentBatch;
            },
            batches() {
                return this.projectBatches.batchDivision;
            },
            amount: {
                get() {
                    if (this.value !== null) {
                        return this.value;
                    }

                    if (this.frame && this.frame.hasOwnProperty('batchAmount')) {
                        return this.frame.batchAmount;
                    }

                    return null;
                },
                set(val) {
                    this.value = val;
                },
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        },
    };
</script>
