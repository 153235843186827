<template>
    <div class="dropdown-container">
        <div :class="{'active' : open}"
             class="nav-item"
             @click="toggle">
            <slot name="button"
                  :open="open" />
        </div>

        <div v-show="open"
             class="dropdown">
            <slot name="dropdown" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NavBarDropdown',
        props: {
            forceOpen: {
                type: Boolean,
                required: false,
                default() {
                    return false;
                },
            },
        },
        data() {
            return {
                isOpened: false,
            };
        },
        methods: {
            toggle() {
                this.isOpened = !this.isOpened;
            },
        },
        watch: {
            '$route.name': {
                handler() {
                    this.isOpened = false;
                },
            },
        },
        computed: {
            open: {
                get() {
                    return this.forceOpen || this.isOpened;
                },
            },
        },
    };
</script>
