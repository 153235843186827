<template>
    <base-overview ref="overview"
                   :auto-layout="true"
                   :searchEnabled="false"
                   :tabs="tabs"
                   slug="parameter-updates">
        <template v-slot:head>
            <h1>{{ $t('Conversions') }}</h1>
            <button class="btn secondary with-icon"
                    type="button"
                    ref="importButton"
                    @click="selectImportFile">
                <img src="/images/icons/upload.svg" />
                <input ref="importInput"
                       style="display:none;"
                       type="file"
                       @change="importFile">
            </button>
            <button class="btn secondary with-icon"
                    type="button"
                    @click="downloadExport">
                <img src="/images/icons/download.svg" />
            </button>
            <router-link :to="{ name: 'parameter-library.conversions.create'}"
                         class="btn primary">
                {{ $t('Create conversion') }}
            </router-link>
        </template>
        <template v-slot:table-title
                  class="table">
            <div class="table">
                <div class="row auto-layout">
                    <h3 class="column">{{ $t('From') }}</h3>
                    <span class="column"></span>
                    <span class="column"></span>
                    <h3 class="column">{{ $t('To') }}</h3>
                    <span class="column"></span>
                    <span class="column"></span>
                    <span class="column"></span>
                </div>
            </div>
        </template>
        <template v-slot:table-head>
            <div class="column">{{ $t("Parameter") }}</div>
            <div class="column">{{ $t("Module") }}</div>
            <div class="column">{{ $t("Value") }}</div>
            <div class="column">{{ $t("Parameter") }}</div>
            <div class="column">{{ $t("Module") }}</div>
            <div class="column">{{ $t("Value") }}</div>
            <div class="column">{{ $t("Has warning") }}</div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">{{ item.from_parameter.name }}</div>
            <div class="column">{{ item.from_module.name }}</div>
            <div class="column">{{ item.from_value }}</div>
            <div class="column">{{ item.to_parameter.name }}</div>
            <div class="column">{{ item.to_module.name }}</div>
            <div class="column">{{ item.to_value }}</div>
            <div class="column">{{ item.warning ? $t("Yes") : $t("No") }}</div>
        </template>
        <template v-slot:no-more>{{ $t('These were all conversions') }}</template>
    </base-overview>
</template>

<script>
import BaseOverview from "../../components/BaseOverview";

export default {
    name: "ConversionIndex",

    components: {
        BaseOverview
    },

    data() {
        return {
            tabs: [
                {
                    route: { name: "parameter-library.index" },
                    title: this.$t("Parameters")
                },
                {
                    route: { name: "parameter-library.conversions" },
                    title: this.$t("Conversions")
                }
            ]
        };
    },

    methods: {
        getRoute(item) {
            return {
                name: `parameter-library.conversions.edit`,
                params: {
                    parameterUpdateId: item.id
                }
            };
        },
        selectImportFile() {
            this.$refs.importInput.click();
        },
        importFile(e) {
            this.$refs.importButton.disabled = true
            
            let files = Array.from(e.target.files);

            if (files.length === 0) {
                this.$refs.importButton.disabled = false;

                return;
            }

            const file = files[0];
            const formData = new FormData();
            formData.append('csv', file);

            this.$http.post('/parameter-updates-import', formData)
                .finally(() => {
                    this.$refs.importButton.disabled = false;

                    this.$refs.overview.reset();
                });
        },
        downloadExport(e) {
            e.target.disabled = true;

            this.$http
                .get('/parameter-updates-export', {
                    responseType: 'blob',
                })
                .then((response) => {
                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    const fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'export.csv');
                    document.body.appendChild(fileLink);

                    fileLink.click();

                    document.body.removeChild(fileLink);
                })
                .finally(() => e.target.disabled = true);
        },
    },
};
</script>