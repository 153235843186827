<template>
    <div class="form-element-fields">
        <big-input id="default"
                   name="default"
                   :label="$t('Default value')"
                   v-model="formElement.default" />
        <base-input id="min"
                    name="min"
                    label="Min"
                    v-model="formElement.min" />
        <base-input id="max"
                    name="max"
                    label="Max"
                    v-model="formElement.max" />
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect';
    import BigSelect from './BigSelect';
    import BigInput from './BigInput';
    import BaseInput from "./BaseInput";

    export default {
        name: 'FormElementGroupInputFields',
        components: { BaseInput, BigInput, BigSelect, VueMultiselect },
        props: {
            value: {},
        },
        computed: {
            formElement: {
                get() {
                    return this.value;
                },
            },
        },
    };
</script>
