import { roles } from '../../configuration/variables';
import PriceMatrixService from '../../services/PriceMatrixService';
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import PriceMatrixEdit from '../../views/Price/PriceMatrixEdit';
import PriceMatrixIndex from '../../views/Price/PriceMatrixIndex';
import ModelBoundView from '../utils/ModelBoundView';
import PriceMatrixUpscale from "../../views/Price/PriceMatrixMutation";

export default [
    {
        name: 'price-matrices.index',
        path: '/price-matrices',
        component: PriceMatrixIndex,
        children: [
            {
                name: 'price-matrices.edit',
                path: ':priceMatrixId/edit',
                component: ModelBoundView(new PriceMatrixService(),
                    PriceMatrixEdit),
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'price-matrices.upscale',
                path: '/upscale',
                component: PriceMatrixUpscale,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            isRevitOption: true,
            middleware: [auth, role(roles.GLOBAL_ADMIN)],
        },
    },
];
