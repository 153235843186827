import auth from "../middleware/_auth";
import role from "../middleware/_role";
import { roles } from "../../configuration/variables";
import AdminSettingsIndex from "../../views/AdminSettings/AdminSettingsIndex";
import EditProjectAcceptationEmails from "../../views/AdminSettings/Components/EditProjectAcceptationEmails";


export default [
    {
        name: 'admin-settings.index',
        path: '/admin-settings/:key?',
        component: AdminSettingsIndex,
        children: [
            {
                path: '/admin-settings/project_accepted_emails/edit',
                name: 'admin-settings.edit.project_accepted_emails',
                component: EditProjectAcceptationEmails,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
        },
    },

]