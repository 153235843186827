<template>
    <div class="form-element-fields">
        <div class="form-group">
            <vue-multiselect :options="calculations"
                             :placeholder="$t('Select the options')"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             :id="`options`"
                             label="name"
                             name="options[]"
                             select-label=""
                             :multiple="false"
                             selected-label=""
                             show-labels
                             track-by="id"
                             v-model="formElement.options" />
        </div>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect';

    export default {
        name: 'FormElementGroupCalculation',

        props: {
            value: {},
        },

        components: {
            VueMultiselect,
        },

        computed: {
            calculations: {
                get() {
                    let calculations = this.$parent.calculations;
                    calculations.forEach(function (calculation) {
                        calculation.name = this.$t(calculation.name)
                    });

                    return calculations;
                },
            },
            formElement: {
                get() {
                    return this.value;
                },
            },
        },
    };
</script>
