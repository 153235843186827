import { roles } from '../../configuration/variables';
import ProjectService from '../../services/ProjectService';
import ProjectApprove from '../../views/Project/ProjectApprove';
import ProjectApproveModal from '../../views/Project/ProjectApproveModal';
import ProjectApprovedModal from '../../views/Project/ProjectApprovedModal';
import ProjectCancel from '../../views/Project/ProjectCancel';
import ProjectCreate from '../../views/Project/ProjectCreate';
import ProjectEdit from '../../views/Project/ProjectEdit';
import ProjectIndex from '../../views/Project/ProjectIndex';
import ProjectNotifications from '../../views/Project/ProjectNotifications';
import ProjectReopen from '../../views/Project/ProjectReopen';
import ProjectShow from '../../views/Project/ProjectShow';
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import ModelBoundView from '../utils/ModelBoundView';
import ProjectBatchAmount from '../../views/Project/ProjectBatchAmount';
import { executeMiddleware } from "../middleware";
import ProjectFinish from "../../views/Project/ProjectFinish";
import ProjectInProduction from "../../views/Project/ProjectInProduction";

const projectsIndexRoute = {
    name: 'projects.index',
    path: '/projects/:status?',
    component: ProjectIndex,
    children: [
        {
            name: 'projects.create',
            path: 'create',
            component: ProjectCreate,
            meta: {
                middleware: [
                    auth,
                    role(roles.GLOBAL_ADMIN, true),
                ],
                forbiddenRoute: { name: 'projects.index', params: { status: 1 } },
            },
        },
    ],
    meta: {
        middleware: [
            auth,
            ({ to, next, redirect }) => {
                if (!to.params.status) {
                    return redirect({ name: 'projects.index', params: { status: 1 } });
                }

                return next();
            },
        ],
        forbiddenRoute: { name: 'projects.index', params: { status: 1 } },
    },
};

projectsIndexRoute.beforeEnter = (to, from, next) => {
    return executeMiddleware(to, from, next, null, null);
};

export default [
    projectsIndexRoute,
    {
        name: 'projects.batches',
        path: '/projects/:projectId/batches',
        component: ModelBoundView(new ProjectService(), ProjectShow),
        meta: {
            middleware: [auth],
        },
        children: [
            {
                name: 'projects.batches.amount',
                path: 'amount',
                component: ProjectBatchAmount,
                meta: {
                    middleware: [auth, role(roles.OU_APPROVE_QUOTATION)],
                },
            },
        ],
    },
    {
        name: 'projects.show',
        path: '/projects/:projectId/show',
        component: ModelBoundView(new ProjectService(), ProjectShow),
        children: [
            {
                name: 'projects.approve',
                path: '/projects/:projectId/approve',
                component: ProjectApprove,
                meta: {
                    middleware: [auth, role(roles.OU_APPROVE_QUOTATION)],
                },
            },
            {
                name: 'projects.confirm_approval',
                path: '/projects/:projectId/confirm_approval',
                component: ProjectApproveModal,
                meta: {
                    middleware: [auth, role(roles.OU_APPROVE_QUOTATION)],
                },
            },
            {
                name: 'projects.approved',
                path: '/projects/:projectId/approved',
                component: ProjectApprovedModal,
                meta: {
                    middleware: [auth, role(roles.OU_APPROVE_QUOTATION)],
                },
            },
            {
                name: 'projects.edit',
                path: '/projects/:projectId/edit',
                component: ProjectEdit,
                meta: {
                    middleware: [auth],
                },
            },
            {
                name: 'projects.cancel',
                path: '/projects/:projectId/cancel',
                component: ProjectCancel,
                meta: {
                    middleware: [auth],
                },
            },
            {
                name: 'projects.reopen',
                path: '/projects/:projectId/reopen',
                component: ProjectReopen,
                meta: {
                    middleware: [auth],
                },
            },
            {
                name: 'projects.in-production',
                path: '/projects/:projectId/in-production',
                component: ProjectInProduction,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'projects.finish',
                path: '/projects/:projectId/finish',
                component: ProjectFinish,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'projects.notifications',
                path: '/projects/:projectId/notifications',
                component: ProjectNotifications,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                },
            },
        ],
        meta: {
            middleware: [auth],
        },
    },
];
