<template>
    <div class="form-group">
        <label for="formula">{{ $t('Formula') }}</label>
        <formula-input :parameters="parameters" id="formula" quick-filter v-model="formula.formula" />
    </div>
</template>

<script>
    import FormulaInput from "./FormulaInput";

    export default {
        components: { FormulaInput },
        props: ["formula", "parameters"]
    };
</script>
