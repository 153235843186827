<template>
    <div class="frame-families">
        <div class="family"
             v-for="(family, familyIndex) in $parent.frame.families"
             :key="`${familyIndex}-frame-family`">
            <div class="head space-around">
                <div class="full-width">
                    <h2 v-text="$t('Family') + ' ' + (familyIndex + 1)" />
                    <button @click="$parent.frame.families.splice(familyIndex, 1)"
                            class="remove"
                            type="button">
                        <img :alt="$t('Remove family')"
                             src="/images/icons/close-icon.svg">
                    </button>
                </div>
                <div class="updated-at"
                     v-if="family.updatedAt != null">
                    Gewijzigd op:
                    {{ family.updatedAt }}
                </div>
            </div>
            <div class="form-group">
                <label for="name">
                    {{ $t('Name') }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="family.name">
            </div>
            <div class="form-group">
                <label for="preview">
                    {{ $t('Revit family') }}
                </label>
                <file-input :create-base64="false"
                            :default="null"
                            file-type=".rfa"
                            v-model="family.rfaFile">
                    <template #default="{image}">
                        <img alt="Checkmark Icon"
                             class="checkmark"
                             src="/images/icons/check-pink-icon.svg"
                             v-if="image || !!$parent.frame.sourceUrl">
                        <div class="img-placeholder"
                             v-else />
                        <span>
                            {{ $t('Upload RFA') }}
                        </span>
                    </template>
                </file-input>
            </div>
            <div class="form-group">
                <label for="conditions">
                    {{ $t('Conditions') }}
                </label>
                <textarea disabled
                          id="conditions"
                          name="conditions"
                          style="font-family: monospace;"
                          v-text="family.conditions.conditionString" />
            </div>
            <conditions-modal v-model="family.conditions"
                              ref="familyConditions"
                              :parameters="parameters"
                              :type="family.name" />
            <button @click="openConditionModal(familyIndex)"
                    class="secondary no-bottom"
                    type="button">
                {{ $t('Change conditions') }}
            </button>
        </div>
        <button @click="$parent.frame.families.push({conditions: {groups: [], compareMethod: '|'}})"
                class="primary with-margin"
                type="button">
            {{ $t('Add family') }}
        </button>
    </div>
</template>

<script>
    import ConditionsModal from '../../components/ConditionsModal';
    import FileInput from '../../components/FileInput';

    export default {
        name: 'WindowFrameFamilies',
        components: { ConditionsModal, FileInput },
        methods: {
            openConditionModal(familyIndex) {
                this.$refs.familyConditions[familyIndex].open();
            },
        },
        computed: {
            parameters: {
                get() {
                    return this.$parent.$data.frame.parameters;
                },
            },
        },
        watch: {},
    };
</script>
