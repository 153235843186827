<template>
    <div class="formula-input">
        <div class="parameters">
      <span
              @click="addParameterClick(parameter.name)"
              class="parameter"
              :key="`parameter-${parameterIndex}`"
              v-for="(parameter, parameterIndex) of quickParameters"
      >{{ parameter.name }}</span>
            <span @click="openParameterList" class="parameter">...</span>
        </div>
        <div class="value">
      <span
              :class="{ parameter: isParameter(part) }"
              class="part"
              :key="`part-${partIndex}`"
              v-for="(part, partIndex) of parts"
      >{{ part }}</span>
            <input
                    :id="id"
                    :name="id"
                    @blur="onSpacePressed"
                    @keydown.8="onBackspacePressed"
                    @keydown.space.prevent="onSpacePressed"
                    @keydown.tab.prevent="addPart(quickParameters[0].name)"
                    type="text"
                    v-model="currentInputValue"
            />
        </div>
        <base-modal
                :key="quickFilter"
                @overlay-clicked="closeParameterList"
                modal-class="formula-parameter-sidebar"
                ref="parameterList"
        >
            <template #header>
                <h1>{{ ucf$t('objects.parameters') }}</h1>
            </template>
            <template #default>
                <p>{{ $t('objects.parameters_description') }}</p>
                <input type="text" :placeholder="$t('Search..')" v-model="search" />
                <table class="table">
                    <tr>
                        <th>{{ $t('Name') }}</th>
                        <th>{{ $t('Parameter name') }}</th>
                        <th>{{ $t('Form name') }}</th>
                    </tr>
                    <tr
                            v-for="parameter in filteredParameters"
                            :key="parameter.id"
                            @click="addParameterClick(parameter.name)"
                    >
                        <td>{{ parameter.name }}</td>
                        <td>{{ parameter.parameter_name }}</td>
                        <td>{{ parameter.form.name }}</td>
                    </tr>
                </table>
            </template>
        </base-modal>
    </div>
</template>

<script>
    import BaseModal from "../BaseModal";

    export default {
        name: "FormulaInput",
        components: { BaseModal },
        props: {
            id: {
                required: true,
                type: String
            },
            parameters: {
                required: true,
                type: Array
            },
            quickFilter: {
                type: String,
                required: true
            },
            /* eslint-disable vue/require-prop-types */
            value: {}
        },
        data() {
            return {
                currentInputValue: "",
                internalValue: this.value || "",
                operators: ["/", "*", "-", "+", "(", ")"],
                search: ""
            };
        },
        methods: {
            openParameterList() {
                this.$refs.parameterList.open();
            },
            closeParameterList() {
                this.$refs.parameterList.close();
            },
            addParameterClick(parameter) {
                if (!this.addPart(parameter)) {
                    this.flashInvalidFormula(parameter);
                    return;
                }
                this.closeParameterList();
            },
            addPart(part) {
                const lastPart = this.parts[this.parts.length - 1];

                if (
                    !this.isOperator(part) &&
                    !this.isNumber(part) &&
                    !this.isParameter(part)
                ) {
                    return false;
                }

                if (
                    this.isOperator(lastPart) &&
                    this.isOperator(part) &&
                    part !== "(" &&
                    lastPart !== ")"
                ) {
                    return false;
                }

                if (part === ")" && !this.internalValue.includes("(")) {
                    return false;
                }

                if (
                    (this.isNumber(lastPart) || this.isParameter(lastPart)) &&
                    (this.isNumber(part) || this.isParameter(part))
                ) {
                    return false;
                }

                this.currentInputValue = "";
                this.internalValue += ` ${part}`;
                return true;
            },
            flashInvalidFormula(value) {
                switch (true) {
                    case ["(", ")"].includes(value):
                        this.$flash.flash(
                            this.$t("objects.formula.invalid_order_parenthesis"),
                            "error"
                        );
                        return;
                    case this.isParameter(value):
                        this.$flash.flash(
                            this.$t("objects.formula.invalid_order_parameter"),
                            "error"
                        );
                        return;
                    case this.isOperator(value):
                        this.$flash.flash(
                            this.$t("objects.formula.invalid_order_operator"),
                            "error"
                        );
                        return;
                    default:
                        this.$flash.flash(this.$t("objects.formula.invalid_value"), "error");
                }
            },
            parameterExists(param) {
                if (typeof param !== "string" || typeof param === "undefined") {
                    return false;
                }

                return this.lowerCaseParameters.includes(param.toLowerCase());
            },
            onBackspacePressed(e) {
                if (e.target.value.length !== 0 || this.internalValue.length === 0) {
                    return;
                }
                const { parts } = this;

                e.target.value = parts.pop();

                this.internalValue = parts.join(" ");
            },
            onSpacePressed(e) {
                let { value } = e.target;

                if (this.empty(value)) {
                    return;
                }

                if (this.parameterExists(value)) {
                    const index = this.lowerCaseParameters.indexOf(value.toLowerCase());

                    value = this.parameters[index].name;
                }

                if (!this.addPart(value)) {
                    this.flashInvalidFormula(value);
                }

                this.currentInputValue = "";
                e.target.value = "";
            },
            isParameter(part) {
                return (
                    !this.isOperator(part) &&
                    !this.isNumber(part) &&
                    this.parameterExists(part)
                );
            },
            isNumber(number) {
                /* eslint-disable no-restricted-globals */
                return !isNaN(number);
            },
            isOperator(operator) {
                return this.operators.includes(operator);
            },
            isFormulaValid() {
                let formula = this.internalValue;
                this.parameters.forEach(parameter => {
                    if (formula.toLowerCase().includes(parameter.name.toLowerCase())) {
                        formula = formula
                            .toLowerCase()
                            .replace(new RegExp(parameter.name.toLowerCase(), "g"), 20);
                    }
                });

                try {
                    /* eslint-disable no-eval */
                    eval(formula);
                } catch (e) {
                    return false;
                }

                return true;
            },
            getFilteredParameters(filterString) {
                return this.parameters.filter(parameter =>
                    parameter.name.toLowerCase().includes(filterString)
                );
            }
        },
        watch: {
            value: {
                handler(value) {
                    if (typeof value === "undefined" || typeof value.map === "undefined") {
                        return;
                    }

                    this.internalValue = value;
                },
                immediate: true
            },
            internalValue: {
                handler(newValue) {
                    if (this.isFormulaValid()) {
                        this.$emit("input", newValue);
                    }
                }
            }
        },
        computed: {
            filteredParameters: {
                get() {
                    return this.parameters.filter(e =>
                        Object.values(e)
                            .toString()
                            .toLowerCase()
                            .includes(this.search.toLowerCase())
                    );
                }
            },
            parts: {
                get() {
                    return (this.internalValue || "")
                        .split(" ")
                        .filter(part => !this.empty(part));
                }
            },
            lowerCaseParameters: {
                get() {
                    return this.parameters.map(parameter => parameter.name.toLowerCase());
                }
            },
            quickParameters: {
                get() {
                    const parameters = this.getFilteredParameters(
                        this.currentInputValue.toLowerCase() || this.quickFilter.toLowerCase()
                    );
                    const quickParameters = parameters.slice(0, 3);

                    if (quickParameters.length < 3 && !this.empty(this.currentInputValue)) {
                        this.getFilteredParameters(this.quickFilter.toLowerCase())
                            .filter(parameter => !quickParameters.includes(parameter))
                            .slice(0, 3 - quickParameters.length)
                            .forEach(parameter => quickParameters.push(parameter));
                    }

                    if (quickParameters.length < 3) {
                        this.parameters
                            .filter(parameter => !quickParameters.includes(parameter))
                            .slice(0, 3 - quickParameters.length)
                            .forEach(parameter => quickParameters.push(parameter));
                    }

                    return quickParameters;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .formula-input {
        .parameter {
            display          : inline-block;
            margin-right     : 8px;
            border-radius    : 4px;
            padding          : 0 16px;
            background-color : #e6e1e4;
            cursor           : pointer;
            font-size        : 12px;
            font-weight      : 600;
            line-height      : 32px;
        }

        .parameters {
            margin-bottom : 8px;
        }

        .value {
            display          : flex;
            margin-bottom    : 14px;
            box-shadow       : none;
            border           : 1px solid rgba(0, 0, 0, 0.1);
            border-radius    : 4px;
            outline          : none;
            box-sizing       : border-box;
            width            : 100%;
            padding          : 8px 24px;
            background-color : #ffffff;
            font-family      : "Montserrat", sans-serif;
            font-size        : 18px;
            font-weight      : 500;
            line-height      : 32px;
            color            : #1a1a1a;

            .part {
                margin-right : 4px;
            }

            input {
                display          : block;
                margin           : auto 0;
                border           : none;
                border-radius    : 0;
                outline          : none;
                box-sizing       : border-box;
                height           : 100%;
                padding          : 0;
                background-color : transparent;
                font-family      : "Montserrat", sans-serif;
                font-size        : 18px;
                font-weight      : 500;
                line-height      : 24px;
                color            : #1a1a1a;
            }
        }
    }

    table {
        display         : table;
        width           : 100%;
        border-collapse : collapse;

        tr {
            border-bottom : 1px hsla(0, 0%, 0%, 0.1) solid;

            th,
            td {
                padding    : 8px;
                text-align : left;
            }

            th {
                opacity     : 0.4;
                font-weight : 600;
            }
        }
    }
</style>
