<template>
    <base-modal ref="modal"
                class="conditions">
        <template v-slot:header>
            <h2>{{ $t('Show based on condition', { type: type }) }}</h2>
        </template>
        <div class="conditions">
            <div class="form-group"
                 v-if="value.groups.length > 1">
                <label for="type">
                    Vergelijkingsmethode per groep
                </label>
                <select name="type"
                        v-model="value.compareMethod"
                        id="type">
                    <option value="&">
                        AND
                    </option>
                    <option value="|">
                        OR
                    </option>
                </select>
            </div>
            <template v-for="(group, idx) of value.groups">
                <condition-group :parameters="parameters"
                                 @remove="value.groups.splice(idx, 1)"
                                 v-model="value.groups[idx]" />
            </template>
            <button type="button"
                    class="btn primary"
                    @click="addGroup">
                {{ $t('Add condition group') }}
            </button>
        </div>
        <template v-slot:footer>
            <p class="left">
                <strong>{{ $t('Current condition') }}:</strong><br>
                <span class="code">{{ conditionString }}</span>
            </p>
        </template>
    </base-modal>
</template>

<script>
import BaseModal from "./BaseModal";
import ConditionGroup from "./ConditionGroup";

export default {
    name: "ConditionsModal",
    components: {
        ConditionGroup,
        BaseModal
    },
    props: {
        parameters: {
            required: true,
            type: Array,
        },
        type: {
            required: false,
            type: String,
            default() {
                return '';
            },
        },
        value: {
            required: true,
            type: Object,
            default() {
                return {
                    groups: [],
                    compareMethod: {},
                };
            },
        },
    },
    methods: {
        addGroup() {
            this.value.groups.push({ type: '&', conditions: [] });
        },
        open() {
            this.$refs.modal.open();
        },
    },
    computed: {
        conditionString: {
            get() {
                const hasMultipleGroups = this.value.groups.length > 1;

                if (this.value.groups.length === 0) {
                    return this.$t('None');
                }

                const conditionString = this.value.groups.reduce((string, group, groupIdx) => {
                    const compare = group.type === '&' ? ' AND ' : ' OR ';
                    let groupString = group.conditions.reduce((str, condition) => {
                        let conditionString = '...';

                        if (!this.empty((condition.parameter || {}).name)
                            && !this.empty(condition.operator)
                            && !this.empty(condition.value)) {
                            conditionString = (condition.parameter || {}).name + ' ' + condition.operator + ' ' + condition.value;
                        }

                        if (str.length > 0) {
                            conditionString = compare + conditionString;
                        }

                        return str + conditionString;
                    }, '');

                    if (hasMultipleGroups) {
                        groupString = '( ' + groupString + ' )';
                    }

                    if (string.length > 0) {
                        const groupCompare = this.value.compareMethod;

                        return string + (groupCompare === '&' ? ' AND ' : ' OR ') + groupString;
                    }

                    return string + groupString;
                }, '');

                this.value.conditionString = conditionString;

                return conditionString;
            },
        },
    },
    mounted() {
        this.value.conditionString = this.conditionString;
        this.$refs.modal.$el.children[0].style.width = '55%';
        this.$refs.modal.$el.children[0].querySelector('.body').style.paddingBottom = '24px';
    },
};
</script>

<style lang="scss"
       scoped>
.conditions > > > .panel {
    width : 100%;
}

.left {
    width : 100%;

    .code {
        font-family : monospace;
        font-size   : 16px;
    }
}
</style>
