<template>
    <base-overview :slug="slug"
                   :tabs="tabs"
                   ref="overview">
        <template v-slot:head>
            <h1>
                {{ $t($route.params.key) }}
            </h1>
            <router-link :to="{ name: `admin-settings.edit.${$route.params.key}`}"
                         class="btn primary">
                {{ $t('Edit') }}
            </router-link>
        </template>
        <template v-slot:table-head>
            <div>
                {{ $t("Value") }}
            </div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>
                    {{ item.value }}
                </span>
            </div>
        </template>
        <template v-slot:no-more>
            {{ $t(`These were all ${$route.params.key}`) }}
        </template>
    </base-overview>
</template>

<script>
    import BaseOverview from "../../components/BaseOverview";

    export default {
        name: "AdminSettingsIndex",

        components: {
            BaseOverview
        },

        data() {
            return {
                tabs: [
                    {
                        route: {
                            name: this.$route.name,
                            params: {
                                key: 'project_accepted_emails',
                            },
                        },
                        title: this.$t('project_accepted_emails'),
                    },
                ],
            };
        },

        computed: {
            slug: {
                get() {
                    return `/settings/${this.$route.params.key}`
                }
            }
        },

        methods: {
            getRoute(item) {
                return '';
            },
        },
    }
</script>