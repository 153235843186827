<template>
    <form-panel
            @overlay-clicked="close"
            @submit.prevent="submit"
            class="sidebar-container"
            panel-class="sidebar"
            ref="panel"
    >
        <template v-slot:header>
            <h1>{{ $t('Create parameter') }}</h1>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="name">{{ $t('Name') }}</label>
                <input id="name" name="name" ref="name" type="text" v-model="parameter.name" />
            </div>
            <div class="form-group">
                <label for="parameterName">{{ $t('Parameter name') }}</label>
                <input
                        id="parameterName"
                        name="parameterName"
                        ref="parameterName"
                        type="text"
                        v-model="parameter.parameterName"
                />
            </div>
            <div class="form-group">
                <label for="formName">{{ $t('Form') }}</label>
                <select id="formName" name="formName" v-model="parameter.formId">
                    <option :value="undefined" />
                    <option :value="form.id" v-for="(form, index) of forms" :key="index">{{ form.name }}</option>
                </select>
            </div>

            <div class="form-group">
                <label for="conditions">
                    {{ $t('Conditions') }}
                </label>
                <textarea disabled
                          id="conditions"
                          name="conditions"
                          ref="conditions"
                          style="font-family: monospace;"
                          v-text="($refs.conditionsModal || {}).conditionString" />
            </div>
            <button @click="$refs.conditionsModal.open()"
                    class="secondary no-bottom"
                    type="button">
                {{ $t('Change conditions') }}
            </button>
            <conditions-modal ref="conditionsModal"
                              :parameters="parameters"
                              :type="parameter.name"
                              v-if="parameter.conditions"
                              v-model="parameter.conditions" />

        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ $t('Create') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from "../../components/FormPanel";
    import ConditionsModal from "../../components/ConditionsModal";

    export default {
        name: "ParameterLibraryCreate",
        components: { ConditionsModal, FormPanel },
        data() {
            return {
                parameter: {
                    name: '',
                    parameterName: '',
                    conditions: { groups: [], compareMethod: '|' },
                },
                forms: [],
                parameters: [],
            };
        },

        methods: {
            submit() {
                this.$http.post("parameters/create", this.parameter).then(() => {
                    this.close();
                }).catch((e) => {
                    this.handleErrors(e.response);
                });
            },

            close() {
                this.$router.replace({
                    name: "parameter-library.index"
                });
            }
        },

        beforeMount() {
            this.$http.get("/forms").then(response => {
                this.forms = response.data.data;
            });

            this.$http.get("/parameters", { take: 0 })
                .then(({ data }) => (this.parameters = data.data));
        },

        mounted() {
            this.$refs.panel.open();
        },

        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        }
    };
</script>
