import { roles } from '../../configuration/variables';
import FrameGroupService from '../../services/FrameGroupService';
import WindowFrameGroupCreate from '../../views/WindowFrame/WindowFrameGroupCreate';
import WindowFrameGroupEdit from '../../views/WindowFrame/WindowFrameGroupEdit';
import WindowFrameGroupEditGeneral from '../../views/WindowFrame/WindowFrameGroupEditGeneral';
import WindowFrameGroupFrames from '../../views/WindowFrame/WindowFrameGroupFrames';
import WindowFrameGroupIndex from '../../views/WindowFrame/WindowFrameGroupIndex';
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import ModelBoundView from '../utils/ModelBoundView';

export default [
    {
        name: 'window-frame-groups.index',
        path: '/window-frame-groups',
        component: WindowFrameGroupIndex,
        children: [
            {
                name: 'window-frame-groups.create',
                path: 'create',
                component: WindowFrameGroupCreate,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            isRevitOption: true,
            middleware: [auth, role(roles.GLOBAL_ADMIN)],
        },
    },
    {
        name: 'window-frame-groups.edit',
        path: '/window-frame-groups/:frameGroupId',
        component: ModelBoundView(new FrameGroupService(), WindowFrameGroupEdit),
        children: [
            {
                name: 'window-frame-groups.edit.general',
                path: 'general',
                component: WindowFrameGroupEditGeneral,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frame-groups.edit.frames',
                path: 'frames',
                component: WindowFrameGroupFrames,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ next }) => next({ name: 'window-frame-groups.edit.general' }),
            ],
        },
    },
];
