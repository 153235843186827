<template>
    <base-overview :slug="`revit-types/${$route.params.revitTypeId}/options`"
                   ref="overview">
        <template v-slot:head>
            <h1>{{ revitType.name }}</h1>
            <router-link :to="{ name: 'revit-options.create', params: $route.params }"
                         class="btn primary">
                {{ $t("Create %{name}", { name: revitType.name }) }}
            </router-link>
        </template>
        <template v-slot:table-head>
            <div @click="$refs.overview.setOrderBy('key')"
                 class="column pointer">
                {{ $t("Name") }}
            </div>
            <div class="column">
                {{ $t("Value") }}
            </div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>
                    {{ item.key }}
                </span>
            </div>
            <div class="column">
                <span>
                    {{ item.value }}
                </span>
            </div>
        </template>
        <template v-slot:no-more>
            {{ $t('These were all %{name}', { name: revitType.name }) }}
        </template>
    </base-overview>
</template>

<script>
    import BaseOverview from '../../components/BaseOverview';
    import Service from "../../services/Service";

    export default {
        name: "RevitOptionIndex",

        data() {
            return {
                revitType: {
                    name: ''
                },
            }
        },

        components: {
            BaseOverview,
        },

        methods: {
            getRoute(revitOption) {
                return {
                    name: 'revit-options.edit',
                    params: {
                        revitOptionId: revitOption.id,
                    },
                };
            },
            setRevitType() {
                new Service('revitTypes').find(this.$route.params.revitTypeId).then(data => {
                    this.revitType = data
                });
            }
        },

        beforeMount() {
            this.setRevitType();
        },

        beforeUpdate() {
            this.setRevitType();
        },
    };
</script>