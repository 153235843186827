<template>
    <form-panel ref="formPanel"
                @submit="submit"
                @overlay-clicked="close">
        <template v-slot:header>
            <h1>
                {{ $t('Create category') }}
            </h1>
        </template>
        <template v-slot:form>
            <div v-if="$route.params.parentCategoryId != null"
                 class="form-group">
                <label for="parentCategory">
                    {{ $t("Parent category") }}
                </label>
                <input id="parentCategory"
                       :value="parentCategory.name"
                       disabled
                       type="text">
            </div>
            <div class="form-group">
                <label for="name">
                    {{ $t("Name") }}
                </label>
                <input id="name"
                       ref="name"
                       v-model="category.name"
                       name="name"
                       type="text">
            </div>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ $t('Store') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from "../../components/FormPanel";

    export default {
        name: 'CategoryCreate',
        components: {
            FormPanel,
        },
        data() {
            return {
                category: {},
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'categories.index',
                });
            },
            submit() {
                this.$http.post('categories/create', this.category)
                    .then(() => {
                        this.close();
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.category.parentCategory = this.$route.params.parentCategoryId;

            this.$refs.formPanel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.formPanel.beforeRouteLeave(to, from, next);
        },
    };
</script>
