<template>
    <base-overview slug="parameters" ref="overview" :tabs="tabs" :auto-layout="true">
        <template v-slot:head>
            <h1>{{ $t('Parameters') }}</h1>
            <router-link
                :to="{ name: 'parameter-library.create'}"
                class="btn primary"
            >{{ $t('Create parameter') }}
            </router-link>
        </template>
        <template v-slot:table-head>
            <div @click="$refs.overview.setOrderBy('name')" class="column pointer">{{ $t('Name') }}</div>
            <div class="column">{{ $t('Parameter name') }}</div>
            <div class="column">{{ $t('Form name') }}</div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">{{ item.name }}</div>
            <div class="column">{{ item.parameterName }}</div>
            <div class="column">{{ item.form.name }}</div>
        </template>
        <template v-slot:no-more>{{ $t('These were all parameters') }}</template>
    </base-overview>
</template>

<script>
    import BaseOverview from '../../components/BaseOverview';

    export default {
        name: 'ParameterLibraryIndex',

        components: {
            BaseOverview,
        },

        data() {
            return {
                tabs: [
                    {
                        route: { name: 'parameter-library.index' },
                        title: this.$t('Parameters'),
                    },
                    {
                        route: { name: 'parameter-library.conversions' },
                        title: this.$t('Conversions'),
                    },
                ],
            };
        },

        methods: {
            getRoute(item) {
                return {
                    name: `parameter-library.edit`,
                    params: {
                        parameterId: item.id,
                    },
                };
            },
        },
    };
</script>