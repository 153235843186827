<template>
    <div>
        <div class="form-group">
            <label for="priceMatrix">{{ $t('Price matrix') }}</label>
            <select id="priceMatrix" name="priceMatrix" v-model="formula.priceMatrix">
                <option :value="undefined" disabled>{{ $t('Select a price matrix') }}</option>
                <option
                        :value="priceMatrix.id"
                        v-for="priceMatrix of priceMatrices"
                        :key="priceMatrix.id"
                >{{ priceMatrix.name }}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label for="widthFormula">{{ $t('Width formula') }}</label>
            <formula-input
                    :parameters="parameters"
                    id="widthFormula"
                    quick-filter="breedte"
                    v-model="formula.widthFormula"
            />
        </div>
        <div class="form-group">
            <label for="heightFormula">{{ $t('Height formula') }}</label>
            <formula-input
                    :parameters="parameters"
                    id="heightFormula"
                    quick-filter="hoogte"
                    v-model="formula.heightFormula"
            />
        </div>
    </div>
</template>

<script>
    import FormulaInput from "./FormulaInput";

    export default {
        props: ["formula", "parameters"],
        components: { FormulaInput },
        data() {
            return {
                priceMatrices: []
            };
        },
        beforeMount() {
            this.$http.get("/price-matrices").then(response => {
                this.priceMatrices = response.data.data;
            });
        }
    };
</script>
