<template>
    <base-modal ref="modal">
        <template v-slot:header>
            {{ ucf$t("projects.notification_header") }}
        </template>
        <label class="checkbox-container" @click="toggleNotification">
            <div class="checkbox"
                 :class="{'checked' : project.hasNotifications}" />
            {{ ucf$t("projects.has_notification") }}
        </label>
        <template v-slot:footer>
            <button @click="confirm"
                    class="primary">
                {{ $t("Save") }}
            </button>
        </template>
    </base-modal>
</template>

<script>
    import BaseModal from "../../components/BaseModal";

    export default {
        name: 'ProjectNotifications',
        components: { BaseModal, },
        data() {
            return {
                project: {}
            }
        },
        methods: {
            toggleNotification() {
                this.project.hasNotifications = !this.project.hasNotifications;
            },
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: { projectId: this.project.id },
                });
            },
            confirm() {
                this.$http
                    .post(`/projects/${this.project.id}/notification`, {
                        hasNotifications: this.project.hasNotifications,
                    })
                    .then(() => {
                        this.$router.replace({
                            name: 'projects.show',
                            params: { projectId: this.project.id },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.$refs.modal.open();
            this.project = Object.assign({}, this.$parent.project);
        },
    };
</script>
