<template>
    <form-panel @overlay-clicked="close"
                @submit="submit"
                ref="panel">
        <template v-slot:header>
            <h1>
                {{ ucf$t('user.add_employee') }}
            </h1>
        </template>
        <template v-slot:form>
            <input name="customerId"
                   type="hidden"
                   v-model="customerId">
            <div class="form-row">
                <div class="form-group">
                    <label for="firstName">
                        {{ ucf$t("user.first_name") }}
                    </label>
                    <input id="firstName"
                           name="firstName"
                           ref="firstName"
                           type="text"
                           v-model="value.firstName">
                </div>
                <div class="form-group">
                    <label for="middleName">
                        {{ ucf$t("user.middle_name") }}
                    </label>
                    <input id="middleName"
                           name="middleName"
                           ref="middleName"
                           type="text"
                           v-model="value.middleName">
                </div>
                <div class="form-group">
                    <label for="lastName">
                        {{ ucf$t("user.last_name") }}
                    </label>
                    <input id="lastName"
                           name="lastName"
                           ref="lastName"
                           type="text"
                           v-model="value.lastName">
                </div>
            </div>
            <div class="form-group">
                <label for="phoneNumber">
                    {{ ucf$t("user.phone_number") }}
                </label>
                <input id="phoneNumber"
                       name="phoneNumber"
                       ref="phoneNumber"
                       type="text"
                       v-model="value.phoneNumber">
            </div>
            <div class="form-group">
                <label for="email">
                    {{ ucf$t("user.email") }}
                </label>
                <input id="email"
                       name="email"
                       ref="email"
                       type="text"
                       v-model="value.email">
            </div>
            <div class="form-group">
                <label for="roles">
                    {{ ucf$t("user.role") }}
                </label>
                <multiselect :close-on-select="false"
                             :multiple="true"
                             :options="roleOptions"
                             :placeholder="ucf$t('user.select_role')"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="roles"
                             label="name"
                             name="roles[]"
                             ref="roles"
                             select-label=""
                             selected-label=""
                             track-by="name"
                             v-model="value.roles" />
            </div>
            <file-input class="file-upload"
                        default="/"
                        v-model="image">
                <template v-slot:default="{image,isLoading}">
                    <img :src="image"
                         class="profile"
                         v-if="!image.isLoading && image !== '/'">
                    <div class="profile with-background"
                         v-else>
                        {{ value.firstName.charAt(0).toUpperCase() }}
                        {{ value.lastName.charAt(0).toUpperCase() }}
                    </div>
                    <label>
                        {{ ucf$t("user.add_image") }}
                    </label>
                </template>
            </file-input>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ ucf$t('user.add_employee') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import FileInput from '../../components/FileInput';
    import FormPanel from "../../components/FormPanel";

    export default {
        name: 'SettingStaffCreate',
        components: { FormPanel, FileInput, Multiselect },
        data() {
            return {
                image: null,
                value: {
                    firstName: '',
                    lastName: '',
                },
                roleOptions: [],
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'settings.staff',
                });
            },
            submit(event) {
                const formData = new FormData(event.target);

                formData.delete('roles[]');

                if (this.image !== null && typeof this.image !== 'string') {
                    formData.append('image', this.image);
                }

                if (this.value.roles)
                    this.value.roles.forEach((role) => {
                        formData.append('roles[]', role.id);
                    });

                this.$http.post('users/create', formData)
                    .then(() => {
                        this.close();
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        computed: {
            customerId: {
                get() {
                    return this.authUser.customerId;
                },
            },
        },
        beforeMount() {
            this.$http.get('/roles')
                .then((response) => {
                    this.roleOptions = response.data.data;
                });
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$parent.reloadContacts();
            this.$refs.panel.beforeRouteLeave(to, from, next);
        },
    };
</script>
