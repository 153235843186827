<template>
    <base-overview slug="formulas" ref="overview">
        <template v-slot:head>
            <h1>{{ $t('Formulas') }}</h1>
            <router-link :to="{ name: 'formulas.create' }" class="btn primary">{{ $t('Add formula') }}</router-link>
        </template>
        <template v-slot:table-head>
            <div @click="$refs.overview.setOrderBy('name')" class="column pointer">{{ $t("Name") }}</div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>{{ item.name }}</span>
            </div>
        </template>
        <template v-slot:no-more>{{ $t('These were all formulas') }}</template>
    </base-overview>
</template>

<script>
    import baseOverview from "../../components/BaseOverview";

    export default {
        components: { baseOverview },
        methods: {
            getRoute(formula) {
                return {
                    name: "formulas.edit",
                    params: {
                        formulaId: formula.id
                    }
                };
            }
        }
    };
</script>
