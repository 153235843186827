<template>
    <div>
        <div class="search-container">
            <div class="searchbar">
                <img src="/images/icons/search-icon.svg"
                     title="search-icon">
                <input :placeholder="$t('Search..')"
                       :title="$t('Search..')"
                       name="search"
                       v-model="search">
            </div>

            <router-link :to="{ ...$route, name: 'window-frames.create.general' }"
                         class="primary with-margin-top"
                         tag="button">
                {{ $t('Create frame') }}
            </router-link>
        </div>

        <div class="table">
            <div class="row header">
                <div class="column">
                    {{ $t('Name') }}
                </div>
            </div>
            <transition-group name="list">
                <div class="row"
                     :key="frame.id"
                     v-for="frame in frames">
                    <div class="column">
                        {{ frame.name }}
                    </div>
                    <div class="column"
                         style="margin-left: auto;">
                        <router-link class="btn primary"
                                     :to="{ name: 'window-frames.edit.general', params: { ...$route.params, frameId: frame.id } }">
                            {{ $t('Edit') }}
                        </router-link>
                    </div>
                    <div class="column">
                        <router-link class="btn secondary"
                                     :to="{ name: 'window-frames.copy.general', params: { ...$route.params, frameId: frame.id } }">
                            {{ $t('Copy') }}
                        </router-link>
                    </div>
                    <div class="column">
                        <a class="btn primary"
                           @click="deleteFrame(frame)">
                            {{ $t('Delete') }}
                        </a>
                    </div>
                </div>
            </transition-group>
            <infinite-loading :distance="400"
                              @distance="1"
                              @infinite="loadItems"
                              ref="infiniteLoading"
                              spinner="spiral">
                <div class="no-results"
                     slot="no-more">
                    <slot name="no-more" />
                </div>
            </infinite-loading>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WindowFrameGroupFrames',
    data() {
        return {
            search: '',
            page: 1,
            maxPage: 2,
            frames: [],
            orderBy: {
                field: 'created_at',
                sequence: 'DESC',
            },
        };
    },
    methods: {
        setOrderBy(field) {
            const sequence = (field === this.orderBy.field && this.orderBy.sequence === 'DESC')
                ? 'ASC'
                : 'DESC';

            this.orderBy = {
                field,
                sequence,
            };

            this.page = 1;
            this.maxPage = 2;
            this.frames = [];
            this.$refs.infiniteLoading.stateChanger.reset();
        },
        deleteFrame(frame) {
            const confirmText = this.$t('Are you sure you want to delete this frame?')
            if (confirm(confirmText)) ;
            {
                this.$http.post(`/frames/${frame.id}/delete`)
                    .then(() => {
                        this.page = 1;
                        this.frames = [];
                        this.$refs.infiniteLoading.stateChanger.reset();
                    })
            }
        },
        loadItems($state) {
            this.$http
                .get(`/frame-groups/${this.$parent.frameGroup.id}/frames`, {
                    page: this.page,
                    orderBy: this.orderBy.field,
                    sequence: this.orderBy.sequence,
                    search: this.search,
                })
                .then(({ data }) => {
                    if (data.data.length === 0) {
                        $state.complete();
                        return;
                    }

                    data.data.forEach((customer) => {
                        this.frames.push(customer);
                    });

                    this.maxPage = data.meta.last_page;
                    $state.loaded();
                });
            this.page++;
        },
    },
    watch: {
        search: {
            handler() {
                this.frames = [];
                this.$refs.infiniteLoading.stateChanger.reset();
                this.page = 1;
                this.maxPage = 2;
            },
        },
    },
};
</script>