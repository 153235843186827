<template>
    <base-overview class="project-container"
                   search-enabled
                   :auto-layout="false"
                   :key="$route.params.status"
                   :tabs="tabs"
                   :slug="slug">
        <template v-slot:head>
            <h1>
                {{ ucf$t("projects.projects") }}
            </h1>
            <router-link :to="{ name: 'projects.create' }"
                         v-auth.global-admin:invert
                         class="btn primary">
                {{ $t("Create project") }}
            </router-link>
        </template>
        <template v-slot:table-head>
            <div class="column pointer"
                 @click="setOrderBy('projects.name')">
                {{ ucf$t("projects.project") }}
            </div>
            <div class="column pointer"
                 @click="setOrderBy('projects.created_at')">
                {{ ucf$t("projects.create_date") }}
            </div>
            <div class="column">
                {{ ucf$t("projects.contact") }}
            </div>
            <div class="column right">
                {{ ucf$t("projects.offer_price") }}
            </div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>
                    {{ item.name }}
                </span>
            </div>
            <div class="column">
                <span>
                    {{ ucfirst(dateFormat(item.createdAt, $t("global.hour"))) }}
                </span>
            </div>
            <div class="column">
                <span>
                    {{ item.contactPerson.fullName }}
                </span>
                <span class="font-light"
                      v-auth.global-admin>
                    {{ item.customer.name }}
                </span>
                <div class="circle">
                    <img src="/images/icons/email-icon.svg"
                         title="email">
                    <div class="popover">
                        {{ item.contactPerson.email }}
                    </div>
                </div>
                <div class="circle">
                    <img src="/images/icons/call-icon.svg"
                         title="call">
                    <div class="popover">
                        {{ item.contactPerson.phoneNumber }}
                    </div>
                </div>
            </div>
            <div class="column right font-light">
                <span v-if="item.price > 0">
                    € {{ numberFormat(item.price) }}
                </span>
                <span v-else>
                    &nbsp;
                </span>
            </div>
        </template>
    </base-overview>
</template>

<script>
    import BaseOverview from '../../components/BaseOverview';

    export default {
        name: 'ProjectIndex',
        components: { BaseOverview },
        data() {
            return {
                tabs: [
                    {
                        route: {
                            name: 'projects.index',
                            params: { status: 1 },
                        },
                        title: this.ucf$t('projects.open'),
                    },
                    {
                        route: {
                            name: 'projects.index',
                            params: { status: 2 },
                        },
                        title: this.ucf$t('projects.agreed'),
                    },
                    {
                        route: {
                            name: 'projects.index',
                            params: { status: 6 },
                        },
                        title: this.ucf$t('projects.in_production'),
                    },
                    {
                        route: {
                            name: 'projects.index',
                            params: { status: 3 },
                        },
                        title: this.ucf$t('projects.finished'),
                    },
                    {
                        route: {
                            name: 'projects.index',
                            params: { status: 4 },
                        },
                        title: this.ucf$t('projects.canceled'),
                    },
                ],
            };
        },
        methods: {
            getRoute(project) {
                return {
                    name: 'projects.show',
                    params: {
                        projectId: project.id,
                    },
                };
            },
        },
        computed: {
            slug: {
                get() {
                    return '/projects?status=' + this.$route.params.status;
                },
            },
        },
    };
</script>
