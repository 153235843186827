<template>
    <div>
        <div class="form-group">
            <div v-if="parentElement.type === 'checkbox'">
                <vue-multiselect :options="checkboxValues"
                                 :placeholder="$t('Select the options')"
                                 class="w-1/4 mr-4"
                                 deselect-label=""
                                 id="options"
                                 label="name"
                                 name="options[]"
                                 select-label=""
                                 selected-label=""
                                 show-labels
                                 track-by="value"
                                 v-model="formElement.visibleWhen[0]" />
            </div>
            <div v-else-if="parentElement.type === 'radio'">
                <vue-multiselect :close-on-select="false"
                                 :options="parentElement.options"
                                 :placeholder="$t('Select the options')"
                                 class="w-1/4 mr-4"
                                 deselect-label=""
                                 id="options"
                                 label="label"
                                 multiple
                                 name="options[]"
                                 select-label=""
                                 selected-label=""
                                 show-labels
                                 track-by="value"
                                 v-model="formElement.visibleWhen" />
            </div>
            <div v-else-if="(parentElement.type || '').includes('segment')">
                <vue-multiselect :close-on-select="false"
                                 :options="parentElementOptions"
                                 :placeholder="$t('Select the options')"
                                 class="w-1/4 mr-4"
                                 deselect-label=""
                                 id="options"
                                 label="name"
                                 multiple
                                 name="options[]"
                                 select-label=""
                                 selected-label=""
                                 show-labels
                                 track-by="value"
                                 v-model="formElement.visibleWhen" />
            </div>
            <div v-else-if="(parentElement.type || '').includes('calculation')">
                <vue-multiselect :close-on-select="false"
                                 :options="calculationOptions"
                                 :placeholder="$t('Select when this element is visible')"
                                 class="w-1/4 mr-4"
                                 deselect-label=""
                                 id="options"
                                 label="name"
                                 multiple
                                 name="options[]"
                                 select-label=""
                                 selected-label=""
                                 show-labels
                                 track-by="value"
                                 v-model="formElement.visibleWhen" />
            </div>
            <div v-else>
                <big-input id="visible"
                           name="visible"
                           :label="$t('Visible when')"
                           v-model="formElement.visibleWhen" />
            </div>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect/src/Multiselect';
    import BigInput from './BigInput';

    export default {
        name: 'FormElementVisibility',

        props: {
            formElement: {
                required: true,
                type: Object,
            },
        },

        components: {
            BigInput,
            VueMultiselect,
        },

        computed: {
            checkboxValues() {
                return [
                    {
                        name: (this.parentElement || {label: '...'}).label + ': ' + this.$t('Checked'),
                        value: 2,
                    },
                    {
                        name: (this.parentElement || {label: '...'}).label + ': ' + this.$t('Unchecked'),
                        value: 1,
                    },
                ];
            },

            parentElement() {
                return this.$parent.$parent.formElement;
            },
            parentElementOptions() {
                return this.parentElement.options.map((option) => {
                    return option.name;
                });
            },

            calculationOptions() {
                if (
                    this.parentElement !== undefined
                    && this.parentElement.type !== undefined
                    && this.parentElement.type.includes('calculation-')
                ) {
                    return this.$parent.optionTypes.find(
                        ot => ot.class === this.parentElement.type.replace('calculation-', '')).data.map(function(opt) {
                        opt.name = this.parentElement.label + ': ' + opt.name;

                        return opt;
                    });
                }
                return [];
            },
        },

        watch: {
            'formElement.visibleWhen': {
                handler() {
                    if (!Array.isArray(this.formElement.visibleWhen)) {
                        this.formElement.visibleWhen = [
                            this.formElement.visibleWhen,
                        ];
                    }
                },
            },
        },
    };
</script>
