<template>
    <transition name="fade">
        <div class="navbar-container">
            <div class="logo">
                <img class="big"
                     src="/images/nav-logo.svg"
                     title="logo" />
                <img class="small"
                     src="/images/nav-logo-small.svg"
                     title="logo" />
            </div>
            <nav-bar-component v-for="(navigationItem, index) of navigationItems"
                               :key="`navigation-item-${index}`"
                               v-bind="navigationItem" />

            <div class="navbar-user"
                 @click="toggleMenu">
                <div :class="{'show' : showMenu}"
                     class="submenu">
                    <router-link
                        v-auth.global-admin
                        :to="{
                        name: 'admin-settings.index',
                        params: {
                            key: 'project_accepted_emails'
                        },
                    }"
                        class="menu-item"
                    >{{ $t('Settings') }}
                    </router-link>
                    <router-link :to="{ name: 'profile.show' }"
                                 class="menu-item">{{ $t('My profile') }}
                    </router-link>
                    <router-link :to="{ name: 'auth.logout' }"
                                 class="menu-item">{{ $t('Logout') }}
                    </router-link>
                </div>
                <img v-if="navbarUser.imageUrl"
                     :src="navbarUser.imageUrl"
                     class="profile" />
                <div v-else
                     class="profile with-background">{{ initials }}
                </div>
                <p
                    class="name"
                >{{ navbarUser.firstName }} {{ navbarUser.middleName }} {{ navbarUser.lastName }}
                </p>
                <img class="toggle"
                     src="/images/icons/dropdown-arrows.svg" />
            </div>
        </div>
    </transition>
</template>

<script>
    import NavBarComponent from './NavBarComponent';
    import NavBarDropdown from './NavBarDropdown';
    import NavBarSubDropdown from './NavBarSubDropdown';

    export default {
        name: 'NavBar',
        components: { NavBarComponent, NavBarSubDropdown, NavBarDropdown },
        data() {
            return {
                revitTypes: [],
                showMenu: false,
                navbarUser: this.$root.$user,
            };
        },
        methods: {

            toggleMenu() {
                this.showMenu = !this.showMenu;
            },

            toggleDropdown() {
                this.dropdownVisible = !this.dropdownVisible;
            },

            updateUser() {
                this.navbarUser = this.$root.$user;
            },

            loadRevitTypes() {
                this.$http.get('/revit-types')
                    .then((data) => {
                        this.revitTypes = data.data.data;
                    });
            },
        },
        computed: {
            navigationItems() {
                const revitTypes = this.revitTypes;
                const userHasRole = this.userHasRole;

                return [
                    {
                        icon: '/images/icons/company-icon.svg',
                        iconTitle: 'company-icon',
                        to: { name: 'projects.index', params: { status: 1 } },
                        text: this.ucfirst(this.$t('projects.project')),
                    },
                    {
                        icon: '/images/icons/customers-icon.svg',
                        iconTitle: 'customers-icon',
                        to: { name: 'customers.index' },
                        text: this.ucfirst(this.$t('customers.customers')),
                    },
                    {
                        icon: '/images/icons/notifications-icon.svg',
                        iconTitle: 'notifications-icon',
                        to: { name: 'notifications.index' },
                        text: 'Notificaties',
                        showNotificationAmount: true,
                    },
                    {
                        icon: '/images/icons/revit-icon.svg',
                        iconTitle: 'revit-icon',
                        dropdown: true,
                        text: this.$t('Revit management'),
                        show() {
                            return userHasRole(['global-admin']);
                        },
                        children: [
                            {
                                text: 'Configuratie formulier',
                                dropdown: true,
                                children: [
                                    {
                                        to: { name: 'revit-types.index' },
                                        text: this.$t('Revit types'),
                                    },
                                    {
                                        to: { name: 'parameter-library.index' },
                                        text: this.$t('Parameter library'),
                                    },
                                    {
                                        to: { name: 'forms.index' },
                                        text: this.$t('Forms'),
                                    },
                                ],
                            },
                            {
                                text: 'Kozijnen',
                                dropdown: true,
                                children: [
                                    {
                                        to: { name: 'modules.index' },
                                        text: this.$t('Modules'),
                                    },
                                    {
                                        to: { name: 'categories.index' },
                                        text: this.$t('Categories'),
                                    },
                                    {
                                        to: { name: 'window-frame-groups.index' },
                                        text: this.$t('Frame groups'),
                                    },
                                ],
                            },
                            {
                                text: 'Prijzen',
                                dropdown: true,
                                children: [
                                    {
                                        to: { name: 'price-matrices.index' },
                                        text: this.$t('Price matrices'),
                                    },
                                    {
                                        to: { name: 'formulas.index' },
                                        text: this.$t('Formulas library'),
                                    },
                                    {
                                        to: { name: 'prioritization.index' },
                                        text: this.$t('Prioritizations'),
                                    },
                                ],
                            },
                            {
                                text: 'Revit keuzes',
                                key: 'nav-bar-sub-dropdown-without-revit-types',
                                dropdown: true,
                                show() {
                                    return revitTypes.length > 0;
                                },
                                children: revitTypes.map((revitType) => ({
                                    key: `${revitType.id}-${revitType.name}`,
                                    to: { name: 'revit-options.index', params: { revitTypeId: revitType.id } },
                                    text: revitType.name,
                                })),
                            },
                        ],
                    },
                    {
                        icon: '/images/icons/settings-icon.svg',
                        iconTitle: 'settings-icon',
                        to: { name: 'settings.index' },
                        text: 'Instellingen',
                        show() {
                            return userHasRole(['global-admin'], true);
                        },
                    },
                ];
            },

            shouldShowRevitManagementDropdown() {
                if (this.empty(this.$route.name)) {
                    return false;
                }

                return this.$route.meta && this.$route.meta.isRevitOption;
            },

            initials() {
                const { navbarUser } = this;

                if (this.empty(this.navbarUser.firstName)) {
                    return '';
                }

                return navbarUser.firstName.charAt(0) + navbarUser.lastName.charAt(0);
            },
        },

        mounted() {
            this.$root.$on('RevitTypeChanged', this.loadRevitTypes);
            this.loadRevitTypes();
        },
    };
</script>
