<template>
    <div :key="$route.params.status"
         class="parameter-container frame-form">
        <div class="header">
            <h1>
                {{ $t('Create frame') }}
            </h1>
            <button @click="store"
                    class="primary"
                    type="button">
                {{ $t('Store') }}
            </button>
        </div>
        <div class="tabs">
            <router-link :to="{ name: 'window-frames.create.general' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('General') }}
            </router-link>
            <router-link :to="{ name: 'window-frames.create.parameters' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Parameters') }}
            </router-link>
            <router-link :to="{ name: 'window-frames.create.families' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Families') }}
            </router-link>
            <router-link :to="{ name: 'window-frames.create.formulas' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Formulas') }}
            </router-link>
        </div>

        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'WindowFrameCreate',
        data() {
            return {
                frame: {
                    parameters: [],
                    formulas: [],
                    families: [],
                },
                revitOptions: {},
                forms: [],
            };
        },
        methods: {
            store() {
                const formData = new FormData();

                this.appendToFormData(formData, 'frameGroup', this.$route.params.frameGroupId);
                Object.entries(this.frame).forEach(([key, value]) => {
                    this.appendToFormData(formData, key, value);
                });

                this.$http.post('/frames/create', formData)
                    .then(() => {
                        this.$router.replace({
                            name: 'window-frame-groups.edit.frames',
                            params: this.$route.params,
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        beforeMount() {
            this.$http.get(`/formulas/frame-group/${this.$route.params.frameGroupId}`)
                .then((response) => {
                    this.frame.formulas = response.data.data;
                });
            this.$http.get('/forms')
                .then((response) => {
                    this.forms = response.data.data;
                });
        },
    };
</script>
