<template>
    <base-modal ref="modal">
        <template v-slot:header>
            {{ ucf$t("projects.approve") }}
        </template>
        <p>
            {{ ucf$t("projects.approve_description") }}
        </p>
        <template v-slot:footer>
            <button @click="toBatches"
                    class="primary">
                {{ $t('Yes') }}
            </button>
            <button @click="close"
                    class="secondary">
                {{ $t('No') }}
            </button>
        </template>
    </base-modal>
</template>

<script>
    import BaseModal from "../../components/BaseModal";

    export default {
        name: 'ProjectApproveModal',
        components: {
            BaseModal,
        },
        methods: {
            toBatches() {
                this.$router.replace({
                    name: 'projects.batches',
                    params: { project: this.project.id },
                });
            },
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: { projectId: this.project.id },
                });
            },
        },
        computed: {
            project() {
                return Object.assign({}, this.$parent.project);
            },
        },
        mounted() {
            this.$refs.modal.open();
        },
    };
</script>
