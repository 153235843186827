<template>
    <div class="horizontal-select">
        <div :class="{ active: !empty(value) && getPropertyValue(option,trackBy) === getPropertyValue(value,trackByValue) }"
             @click="$emit('slide', option)"
             class="option"
             :key="`option-${getPropertyValue(option,showBy)}`"
             v-for="option of options">
            <slot :option="option">
                {{ getPropertyValue(option,showBy) }}
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HorizontalSelect',
        props: {
            trackBy: {
                required: true,
                type: String,
            },
            trackByValue: {
                required: true,
                type: String,
            },
            showBy: {
                required: true,
                type: String,
            },
            options: {
                required: true,
                type: Array,
            },
            value: {
                type: Object,
            },
        },

        methods: {
            getPropertyValue(object, property) {
                return property
                    .split('.')
                    .reduce((obj, prop) => obj && obj[prop], object)
            }
        }
    };
</script>

<style lang="scss">
    @import "../../sass/variables";

    .horizontal-select {
        display   : flex;
        flex-flow : row nowrap;
        margin    : 0 32px 32px 32px;
        width     : calc(100% - 64px);

        .option {
            display          : flex;
            flex             : 1 1 0;
            flex-flow        : column nowrap;
            justify-content  : center;
            border-top       : 1px solid rgba(0, 0, 0, 0.1);
            border-right     : 1px solid rgba(0, 0, 0, 0.1);
            border-bottom    : 1px solid rgba(0, 0, 0, 0.1);
            box-sizing       : border-box;
            height           : 86px;
            background-color : white;
            cursor           : pointer;
            font-weight      : bold;
            text-align       : center;
            transition       : background-color .3s ease-out;

            &:hover {
                background-color : $primary;
            }

            &:first-child {
                border-left   : 1px solid rgba(0, 0, 0, 0.1);
                border-radius : 4px 0 0 4px;
            }

            &:last-child {
                border-right  : 1px solid rgba(0, 0, 0, 0.1);
                border-radius : 0 4px 4px 0;
            }

            &.active {
                background-color : $pink;
                color            : #ffffff;

                span {
                    opacity : 1;
                }
            }

            strong {
                font-size   : 18px;
                font-weight : 600;
                line-height : 18px;
            }

            span {
                opacity     : 0.4;
                margin-top  : 6px;
                font-size   : 14px;
                font-weight : 600;
                line-height : 14px;
            }
        }
    }
</style>
