<template>
    <form-panel @overlay-clicked="close"
                @submit="submit"
                panel-class="sidebar price-matrix"
                ref="panel">
        <template v-slot:header>
            <h1>
                {{ ucf$t('price-matrices.update') }}
            </h1>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="name">
                    {{ ucf$t('price-matrices.name') }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="priceMatrix.name">
            </div>
            <div class="form-group">
                <label>
                    {{ ucf$t('price-matrices.matrix') }}
                </label>
                <price-matrix-input :key="priceMatrix.id"
                                    :value="priceMatrix.matrix"
                                    :vertical-axis-enabled="!priceMatrix.verticalAxisNull"
                                    :add-height-enabled="priceMatrix.height_revit_type_id === null"
                                    :add-width-enabled="priceMatrix.width_revit_type_id === null"
                                    @input="updatedPriceMatrix"
                                    :show-euro-sign="!priceMatrix.isPercentage"
                                    ref="matrixField" />
            </div>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ ucf$t('price-matrices.update') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import PriceMatrixInput from '../../components/PriceMatrixInput';
    import FormPanel from '../../components/FormPanel';
    import BaseCheckbox from '../../components/FormElements/BaseCheckbox';

    export default {
        name: 'PriceMatrixEdit',
        components: {
            BaseCheckbox,
            FormPanel,
            PriceMatrixInput,
        },
        methods: {
            updatedPriceMatrix(matrix) {
                this.priceMatrix.matrix = matrix;
            },
            submit() {
                let data = this.priceMatrix;
                if (data.matrix.hasOwnProperty('cells')) {
                    data.matrix = data.matrix.cells;
                }

                this.$http.post(`/price-matrices/${this.priceMatrix.id}`, this.priceMatrix)
                    .then(() => {
                        this.$router.replace({
                            name: 'price-matrices.index',
                            params: this.$route.params,
                        });
                    })
                    .catch((e) => {
                        this.handleErrors(e.response);
                    });
            },
            close() {
                this.$router.replace({
                    name: 'price-matrices.index',
                    query: this.$route.query,
                });
            },
        },
        mounted() {
            this.$refs.panel.open();

            this.$refs.matrixField.$refs.panel.remount();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        },
        onModelLoaded(priceMatrix) {
            this.$nextTick(() => this.$refs.matrixField.setPriceMatrix(priceMatrix.matrix));
        },
    };
</script>
