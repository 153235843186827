<template>
    <form-panel @overlay-clicked="close"
                @submit="submit"
                ref="panel">
        <template v-slot:header>
            <div class="value">
                <img :src="user.imageUrl"
                     class="profile"
                     v-if="user.imageUrl">
                <div class="profile with-background"
                     v-else>
                    {{ initials }}
                </div>
                <h1>
                    {{ user.name }}
                </h1>
            </div>
            <div class="edit"
                 @click="showPopup = !showPopup">
                <img alt="edit"
                     src="/images/icons/dots-icon.svg">
                <div class="popover">
                    <div class="drop"
                         :class="{'active' : showPopup}">
                        <button @click="deleteUser">
                            {{ $t('Delete') }}
                        </button>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:form>
            <div class="form-row">
                <div class="form-group">
                    <label for="firstName">
                        {{ ucf$t("user.first_name") }}
                    </label>
                    <input id="firstName"
                           name="firstName"
                           ref="firstName"
                           type="text"
                           v-model="user.firstName">
                </div>
                <div class="form-group">
                    <label for="middleName">
                        {{ ucf$t("user.middle_name") }}
                    </label>
                    <input id="middleName"
                           name="middleName"
                           ref="middleName"
                           type="text"
                           v-model="user.middleName">
                </div>
                <div class="form-group">
                    <label for="lastName">
                        {{ ucf$t("user.last_name") }}
                    </label>
                    <input id="lastName"
                           name="lastName"
                           ref="lastName"
                           type="text"
                           v-model="user.lastName">
                </div>
            </div>
            <div class="form-group">
                <label for="phoneNumber">
                    {{ ucf$t("user.phone_number") }}
                </label>
                <input id="phoneNumber"
                       name="phoneNumber"
                       ref="phoneNumber"
                       type="text"
                       v-model="user.phoneNumber">
            </div>
            <div class="form-group">
                <label for="email">
                    {{ ucf$t("user.email") }}
                </label>
                <input id="email"
                       name="email"
                       ref="email"
                       type="text"
                       v-model="user.email">
            </div>
            <div class="form-group">
                <label for="roles">
                    {{ ucf$t("user.role") }}
                </label>
                <multiselect :close-on-select="false"
                             :multiple="true"
                             :options="roleOptions"
                             :placeholder="ucf$t('user.select_role')"
                             @input="submit"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="roles"
                             label="name"
                             name="roles[]"
                             ref="roles"
                             select-label=""
                             selected-label=""
                             track-by="name"
                             v-model="user.roles" />
            </div>
            <file-input class="file-upload"
                        default="/"
                        v-model="user.imageUrl">
                <template v-slot:default="{image,isLoading}">
                    <img :src="image"
                         class="profile"
                         v-if="!image.isLoading && image !== '/'">
                    <div class="profile with-background"
                         v-else>
                        {{ initials }}
                    </div>
                    <label>
                        {{ ucf$t("user.add_image") }}
                    </label>
                </template>
            </file-input>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ $t('Save') }}
            </button>
        </template>
    </form-panel>

</template>

<script>
    import Multiselect from 'vue-multiselect';
    import FileInput from '../../components/FileInput';
    import FormPanel from "../../components/FormPanel";

    export default {
        name: 'SettingStaffEdit',
        components: {
            FormPanel,
            FileInput,
            Multiselect,
        },
        data() {
            return {
                user: {
                    firstName: '',
                    lastName: '',
                },
                roleOptions: [],
                showPopup: false,
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'settings.staff',
                });
            },
            deleteUser() {
                if (confirm(this.$t('Are you sure you want to delete this user?')))
                    this.$http.post(`users/${this.user.id}/delete`)
                        .then(() => {
                            this.$router.replace({
                                name: 'settings.staff',
                            });
                        })
            },
            submit() {
                const formData = new FormData(this.$refs.employeeForm);

                formData.delete('roles[]');

                this.user.roles.forEach((role) => {
                    formData.append('roles[]', role.id);
                });

                this.$http.post(`users/${this.user.id}`, formData)
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        computed: {
            initials() {
                if (this.empty(this.user.firstName)) {
                    return '';
                }

                return this.user.firstName.charAt(0) + this.user.lastName.charAt(0);
            },
        },
        beforeMount() {
            this.$http.get('/roles')
                .then((response) => {
                    this.roleOptions = response.data.data;
                });
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$parent.reloadContacts();
            this.$refs.panel.beforeRouteLeave(to, from, next);
        },
    };
</script>
