import ProjectParameterIndex from "../../views/ProjectParameter/ProjectParameterIndex";
import auth from "../middleware/_auth";
import role from "../middleware/_role";
import { roles } from "../../configuration/variables";
import ProjectParameterCreate from "../../views/ProjectParameter/ProjectParameterCreate";
import ModelBoundView from "../utils/ModelBoundView";
import ProjectParameterService from "../../services/ProjectParameterService";
import ProjectParameterEdit from "../../views/ProjectParameter/ProjectParameterEdit";

export default [
    // Settings routes
    {
        name: 'project-parameters.index',
        path: '/project-parameters',
        component: ProjectParameterIndex,
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
        },
        children: [
            {
                name: 'project-parameters.create',
                path: 'create',
                component: ProjectParameterCreate,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'project-parameters.edit',
                path: ':projectParameterId/edit',
                component: ModelBoundView(new ProjectParameterService(), ProjectParameterEdit),
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
        ],
    },
];
