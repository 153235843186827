import Index from "../../../views/Formula/Index";
import Create from "../../../views/Formula/Create";
import Edit from "../../../views/Formula/Edit";
import auth from "../../middleware/_auth";
import role from "../../middleware/_role";
import { roles } from "../../../configuration/variables";
import FormulaService from "../../../services/FormulaService";
import ModelBoundView from "../../utils/ModelBoundView";

export default [
    {
        name: "formulas.index",
        path: "/formulas",
        component: Index,
        children: [
            {
                name: "formulas.create",
                path: "create",
                component: Create,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                    forbiddenRoute: { name: "formulas.index" }
                }
            },
            {
                name: "formulas.edit",
                path: ":formulaId/edit",
                component: ModelBoundView(new FormulaService(), Edit),
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                    forbiddenRoute: { name: "formulas.index" }
                }
            }
        ],
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    if (!to.params.category) {
                        return next({
                            name: "formulas.index",
                            params: { category: "formulas" }
                        });
                    }

                    return next();
                }
            ],
            forbiddenRoute: { name: "formulas.index" }
        }
    }
];
