<template>
    <base-panel @overlay-clicked="close"
                class="sidebar-container"
                panel-class="sidebar"
                ref="panel">
        <div class="header">
            <div class="value">
                <img :src="user.imageUrl"
                     class="profile"
                     v-if="user.imageUrl">
                <div class="profile with-background"
                     v-else-if="user.firstName">
                    {{ user.firstName.charAt(0).toUpperCase() }}{{ user.lastName.charAt(0).toUpperCase() }}
                </div>
                <h1>
                    {{ user.name }}
                </h1>
            </div>
            <div class="edit">
                <img alt="edit"
                     src="/images/icons/dots-icon.svg">
            </div>
        </div>
        <form class="body"
              ref="employeeForm"
              @change="submit">
            <h2>
                {{ ucf$t('settings.general_data') }}
            </h2>
            <div class="form-row">
                <div class="form-group">
                    <label for="firstName">
                        {{ ucf$t("user.first_name") }}
                    </label>
                    <input id="firstName"
                           name="firstName"
                           ref="firstName"
                           type="text"
                           v-model="user.firstName">
                </div>
                <div class="form-group">
                    <label for="middleName">
                        {{ ucf$t("user.middle_name") }}
                    </label>
                    <input id="middleName"
                           name="middleName"
                           ref="middleName"
                           type="text"
                           v-model="user.middleName">
                </div>
                <div class="form-group">
                    <label for="lastName">
                        {{ ucf$t("user.last_name") }}
                    </label>
                    <input id="lastName"
                           name="lastName"
                           ref="lastName"
                           type="text"
                           v-model="user.lastName">
                </div>
            </div>
            <div class="form-group">
                <label for="phoneNumber">
                    {{ ucf$t("user.phone_number") }}
                </label>
                <input id="phoneNumber"
                       name="phoneNumber"
                       ref="phoneNumber"
                       type="text"
                       v-model="user.phoneNumber">
            </div>
            <div class="form-group">
                <label for="email">
                    {{ ucf$t("user.email") }}
                </label>
                <input id="email"
                       name="email"
                       ref="email"
                       type="text"
                       v-model="user.email">
            </div>
            <div class="form-group">
                <label for="roles">
                    {{ ucf$t("user.role") }}
                </label>
                <multiselect :close-on-select="false"
                             :multiple="true"
                             :options="roleOptions"
                             :placeholder="ucf$t('user.select_role')"
                             @input="submit"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="roles"
                             label="name"
                             name="roles[]"
                             ref="roles"
                             select-label=""
                             selected-label=""
                             track-by="name"
                             v-model="user.roles" />
            </div>
            <file-input class="file-upload"
                        default="/"
                        name="profileImage"
                        v-model="user.imageUrl">
                <template v-slot:default="{image,isLoading}">
                    <img :src="image"
                         class="profile"
                         v-if="!image.isLoading && image !== '/'">
                    <div class="profile with-background"
                         v-else-if="user.firstName">
                        {{ user.firstName.charAt(0).toUpperCase() }}{{ user.lastName.charAt(0).toUpperCase() }}
                    </div>
                    <label>
                        {{ ucf$t("user.add_image") }}
                    </label>
                </template>
            </file-input>
        </form>
    </base-panel>
</template>

<script>
import Multiselect from 'vue-multiselect';
import BasePanel from '../../components/BasePanel';
import FileInput from '../../components/FileInput';

export default {
    name: 'CustomerStaffEdit',
    components: { FileInput, BasePanel, Multiselect },
    data() {
        return {
            roleOptions: [],
        };
    },
    methods: {
        close() {
            this.$router.replace({
                name: 'customers.show.staff',
                params: {
                    customer: this.$route.params.customer,
                },
            });
        },
        submit() {
            const formData = new FormData(this.$refs.employeeForm);

            formData.delete('roles[]');

            this.user.roles.forEach((role) => {
                formData.append('roles[]', role.id);
            });

            this.$http.post(`users/${this.user.id}`, formData)
                .catch((error) => {
                    this.handleErrors(error.response);
                });
        },
    },
    beforeMount() {
        this.$http.get('/roles')
            .then((response) => {
                this.roleOptions = response.data.data;
            });
    },
    mounted() {
        this.$refs.panel.open();
    },
    beforeRouteLeave(to, from, next) {
        this.$refs.panel.beforeRouteLeave(to, from, next);
    },
};
</script>
