import auth from '../middleware/_auth';
import PrioritizationIndex
    from '../../views/Prioritization/PrioritizationIndex';

export default [
    {
        name: 'prioritization.index',
        path: '/prioritization',
        component: PrioritizationIndex,
        meta: {
            middleware: [auth],
        },
    },
];
