<template>
    <base-modal ref="modal">
        <template v-slot:header>
            {{ ucf$t("projects.put_in_production") }}
        </template>
        <p>
            {{ ucf$t("projects.in_production_description") }}
        </p>
        <template v-slot:footer>
            <button class="primary"
                    @click="confirm">
                {{ $t("Yes") }}
            </button>
            <button class="secondary"
                    @click="close">
                {{ $t("No") }}
            </button>
        </template>
    </base-modal>
</template>

<script>
    import BaseModal from "../../components/BaseModal";

    export default {
        name: 'ProjectInProduction',
        components: {
            BaseModal,
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: { projectId: this.project.id },
                });
            },
            confirm() {
                this.$http
                    .post(`/projects/${this.project.id}/status`, {
                        projectStatus: this.statuses.IN_PRODUCTION,
                    })
                    .then(() => {
                        this.$router.replace({
                            name: 'projects.show',
                            params: { projectId: this.project.id },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        computed: {
            project() {
                return Object.assign({}, this.$parent.project);
            },
        },
        mounted() {
            this.$refs.modal.open();
        },
    };
</script>
