<template>
    <div>
        <div :style="style"
             class="row">
            <div class="column">
                <span>
                    {{ category.name }}
                </span>
            </div>
            <div class="column"
                 style="margin-left: auto;">
                <router-link :to="{ name: 'categories.subCategory.create', params: {parentCategoryId: category.id}, query: $route.query }"
                             class="btn secondary">
                    {{ $t('Add subcategory') }}
                </router-link>
            </div>
            <div class="column">
                <router-link :to="{ name: 'categories.edit', params: { categoryId: category.id } }"
                             class="btn primary">
                    {{ $t('Edit') }}
                </router-link>
            </div>
        </div>
        <transition-group name="list">
            <category-row v-for="sub of category.categories"
                          :key="sub.id"
                          :category="sub"
                          :depth="depth + 1" />
        </transition-group>
    </div>
</template>

<script lang="ts">
    export default {
        name: "CategoryRow",
        props: {
            category: {
                required: true,
            },
            depth: {
                required: false,
                default() {
                    return 0;
                },
            },
        },
        computed: {
            style: {
                get() {
                    if (this.depth > 0) {
                        const l = 100 - (this.depth * 5);
                        const padLeft = 16 + (this.depth * 20);

                        return `background: hsl(0, 0%, ${l}%); padding-left: ${padLeft}px;`;
                    }

                    return '';
                },
            },
        },
    }
</script>