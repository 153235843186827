<template>
    <div>
        <h2>
            {{ ucf$t("projects.payment_successful") }}
        </h2>
        <p>
            {{ ucf$t("projects.payment_successful_text") }}
        </p>
        <div class="division">
            <div class="header">
                <h3>
                    {{ ucf$t("projects.batches_division") }}
                </h3>
                <p>
                    {{ ucf$t("projects.batches_description") }}
                </p>
                <div v-if="editBatches">
                    <button class="primary"
                            @click="saveBatches">
                        {{ ucf$t("projects.division_save") }}
                    </button>
                    <a @click="setEditBatches(false)">
                        {{ ucf$t("projects.division_cancel") }}
                    </a>
                </div>
                <div v-else-if="showEdit">
                    <button class="primary"
                            @click="setEditBatches(true)">
                        {{ ucf$t("projects.division_edit") }}
                    </button>
                    <a @click="saveBatches">
                        {{ ucf$t("projects.division_save") }}
                    </a>
                </div>
            </div>
            <div class="body">
                <div class="division partial"
                     v-for="(batch,index) in batches"
                     v-bind:key="index">
                    <div class="header"
                         v-if="!(index === 0 && batch.length === 0)">
                        <h4>
                            {{ ucf$t("projects.batch") }} {{ index + 1 }}
                        </h4>
                        <div>
                            <div class="amount">
                                {{ getBatchAmount(batch) }} / {{ maxBatchAmount }}
                            </div>
                            <div class="close"
                                 v-if="editBatches">
                                <img src="/images/icons/close-icon.svg"
                                     @click="deleteBatch(index)">
                            </div>
                        </div>
                    </div>
                    <div class="body"
                         :class="{'empty-first' : (index === 0 && batch.length === 0)}">
                        <transition name="fade">
                            <img src="/images/empty-batches.svg"
                                 alt="empty batch"
                                 v-if="batch.length === 0">
                        </transition>
                        <draggable class="table"
                                   :class="{'empty': batch.length === 0}"
                                   group="projects"
                                   :list="batch"
                                   @add="onAdd"
                                   v-if="editBatches"
                                   :move="onMoveCallback">
                            <div class="row"
                                 v-for="(element, batchIndex) in batch"
                                 :key="batchIndex + '-' + element.batchAmount"
                                 @click="editElement(batch, element)">
                                <div class="column">
                                    {{ element.batchAmount }}x
                                </div>
                                <div class="column">
                                    {{ getElementName(element) }}
                                </div>
                                <div class="column">
                                    {{ element.batchAmount }}/{{ element.amount }}
                                </div>
                            </div>
                        </draggable>
                        <div v-else
                             class="table">
                            <div class="row"
                                 v-for="(element, batchIndex) in batch"
                                 :key="batchIndex">
                                <div class="column">
                                    {{ element.batchAmount }}x
                                </div>
                                <div class="column">
                                    {{ getElementName(element) }}
                                </div>
                                <div class="column">
                                    {{ element.batchAmount }}/{{ element.amount }}
                                </div>
                            </div>
                        </div>
                        <p v-if="(index === 0 && batch.length === 0)">
                            {{ ucf$t("projects.division_description") }}
                        </p>
                    </div>
                </div>

                <button class="primary"
                        @click="addBatch"
                        v-if="editBatches">
                    {{ ucf$t("projects.batches_add") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        name: 'Batches',
        components: {
            draggable
        },
        data() {
            return {
                currentFrame: null,
                currentBatch: null,
                editBatches: false,
                batchDivision: [[]],
                maxBatchAmount: process.env.MIX_WEBO_MAX_BATCH_AMOUNT,
            };
        },

        methods: {
            addBatch() {
                this.batchDivision.push([]);
            },

            getElementName(element) {
                return element.hasOwnProperty('parameters')
                && element.parameters.hasOwnProperty('Merknaam')
                    ? element.parameters.Merknaam
                    : element.name
            },

            getBatchAmount(batch) {
                return batch.reduce((total, frame) => frame.batchAmount ? total + frame.batchAmount : total, 0);
            },

            deleteBatch(index) {
                this.batchDivision.splice(index, 1);
            },

            setEditBatches(val) {
                if (val === false) {
                    this.batchDivision = [[]];
                }

                this.editBatches = val;
            },

            onAdd(item) {
                const parent = item.to.parentElement.parentElement;
                const index = [].indexOf.call(parent.parentElement.children, parent);

                this.currentBatch = this.batches[index];
                this.currentFrame = this.currentBatch[item.newIndex];
                this.$router.push('batches/amount');
            },

            onMoveCallback(evt, originalEvent) {
                return evt.from.id === 'source';
            },

            editElement(batch, item) {
                this.currentBatch = batch;
                this.currentFrame = item;
                this.$router.push('batches/amount');
            },

            saveBatches() {
                const vm = this;
                const data = { batches: this.batches };
                this.$http.post(`projects/${this.project.id}/batches`, data)
                    .then(() => {
                        vm.$router.replace({
                            name: 'projects.approved',
                            params: { projectId: this.project.id },
                        });
                    })
                    .catch((error) => {
                        vm.handleErrors(error.response);
                    });
            },

            calculateDefaultBatches() {
                const batches = [[]];
                this.frames.forEach((frame) => {
                    let rest = frame.amount;
                    const amountUsed = batches[batches.length - 1].reduce((tot, i) => tot + i.batchAmount, 0);
                    let amountToUse = this.maxBatchAmount - amountUsed;
                    while (rest > 0) {
                        frame = Object.assign({}, frame);
                        if (rest <= amountToUse) {
                            frame.batchAmount = rest;
                            batches[batches.length - 1].push(frame);
                            rest = 0;
                        } else {
                            rest -= amountToUse;
                            frame.batchAmount = amountToUse;
                            batches[batches.length - 1].push(frame);
                            amountToUse = this.maxBatchAmount;
                            batches.push([]);
                        }
                    }
                });

                return batches;
            },
        },

        computed: {
            batches: {
                get() {
                    if (
                        (this.project.projectStatus.id === this.statuses.OPEN && this.editBatches === true)
                        || this.project.projectStatus.id > this.statuses.OPEN) {
                        return this.batchDivision;
                    } else {
                        return this.calculateDefaultBatches();
                    }
                },
            },
            project: {
                get() {
                    return this.$parent.project;
                },
            },
            frames: {
                get() {
                    return this.$parent.frames;
                }
            },
            showEdit: {
                get() {
                    return this.project.projectStatus.id < this.statuses.ACCEPTED_BY_CLIENT
                },
            },
        },

        beforeMount() {
            this.$http.get(`/projects/${this.project.id}/batches`)
                .then((response) => {
                    this.batchDivision = response.data.data;
                });
        },
    }
</script>