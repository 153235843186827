<template>
    <div>
        <form @submit.prevent=""
              @change.prevent="">
            <div class="form-group">
                <label for="name">
                    {{ $t("Category") }}
                </label>
                <multi-select :close-on-select="true"
                              :multiple="false"
                              :options="categoriesWithSubCategories"
                              class="w-1/4 mr-4"
                              deselect-label=""
                              id="category"
                              label="name"
                              select-label=""
                              selected-label=""
                              track-by="id"
                              v-model="$parent.frameGroup.category" />
            </div>
            <div class="form-group">
                <label for="name">
                    {{ $t("Name") }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="$parent.frameGroup.name">
            </div>
        </form>
        <a class="btn primary"
           @click="deleteFrameGroup">
            {{ $t('Delete') }}
        </a>
    </div>
</template>

<script>
import MultiSelect from 'vue-multiselect';

export default {
    name: 'WindowFrameGroupEditGeneral',

    components: {
        MultiSelect,
    },

    data() {
        return {
            categories: [],
        };
    },

    methods: {
        deleteFrameGroup() {
            const confirmText = this.$t('Are you sure you want to delete this frame group?')
            if (confirm(confirmText)) ;
            {
                this.$http.post(`/frame-groups/${this.$parent.frameGroup.id}/delete`)
                    .then(() => {
                        this.$router.replace({
                            name: 'window-frame-groups.index',
                        });
                    })
            }
        },
        reduceCategoryToOneArray(category, depth = 0) {
            return category.categories.reduce((list, sub) => {
                let indent = '-'.repeat(depth * 4);

                list.push(Object.assign(sub, { name: indent + sub.name }));

                this.reduceCategoryToOneArray(sub, depth + 1)
                    .forEach((item) => list.push(item));

                return list;
            }, []);
        },
    },
    computed: {
        categoriesWithSubCategories: {
            get() {
                return this.categories.reduce((withSubCategories, category) => {
                    withSubCategories.push(category);

                    this.reduceCategoryToOneArray(category, 1)
                        .forEach((item) => withSubCategories.push(item));

                    return withSubCategories;
                }, []);
            },
        },
    },

    beforeMount() {
        this.$http.get('/categories')
            .then((response) => {
                this.categories = response.data.data;
            });
    },
};
</script>
