<template>
    <base-modal ref="modal">
        <template v-slot:header>
            {{ ucf$t("projects.cancel") }}
        </template>
        <p>
            {{ ucf$t("projects.cancel_description") }}
        </p>
        <template v-slot:footer>
            <button @click="confirm"
                    class="primary">
                {{ $t('Yes') }}
            </button>
            <button @click="close"
                    class="secondary">
                {{ $t('No') }}
            </button>
        </template>
    </base-modal>
</template>

<script>
    import BaseModal from "../../components/BaseModal";

    export default {
        name: 'ProjectCancel',
        components: {
            BaseModal,
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: { projectId: this.project.id },
                });
            },
            confirm() {
                this.$http
                    .post(`/projects/${this.project.id}/status`, {
                        projectStatus: this.statuses.CANCELLED,
                    })
                    .then(() => {
                        this.$router.replace({
                            name: 'projects.show',
                            params: { projectId: this.project.id },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        computed: {
            project() {
                return Object.assign({}, this.$parent.project);
            },
        },
        mounted() {
            this.$refs.modal.open();
        },
    };
</script>
