<template>
    <base-panel @overlay-clicked="close"
                class="sidebar-container"
                panel-class="sidebar"
                ref="panel">
        <div class="header">
            <h1>
                {{ ucf$t('user.add_employee') }}
            </h1>
        </div>
        <form class="body"
              ref="employeeForm"
              @submit.prevent="submit">
            <input name="customerId"
                   type="hidden"
                   v-model="customerId">
            <h2>
                {{ ucf$t('settings.general_data') }}
            </h2>
            <div class="form-row">
                <div class="form-group">
                    <label for="firstName">
                        {{ ucf$t("user.first_name") }}
                    </label>
                    <input id="firstName"
                           name="firstName"
                           ref="firstName"
                           type="text"
                           v-model="value.firstName">
                </div>
                <div class="form-group">
                    <label for="middleName">
                        {{ ucf$t("user.middle_name") }}
                    </label>
                    <input id="middleName"
                           name="middleName"
                           ref="middleName"
                           type="text"
                           v-model="value.middleName">
                </div>
                <div class="form-group">
                    <label for="lastName">
                        {{ ucf$t("user.last_name") }}
                    </label>
                    <input id="lastName"
                           name="lastName"
                           ref="lastName"
                           type="text"
                           v-model="value.lastName">
                </div>
            </div>
            <div class="form-group">
                <label for="phoneNumber">
                    {{ ucf$t("user.phone_number") }}
                </label>
                <input id="phoneNumber"
                       name="phoneNumber"
                       ref="phoneNumber"
                       type="text"
                       v-model="value.phoneNumber">
            </div>
            <div class="form-group">
                <label for="email">
                    {{ ucf$t("user.email") }}
                </label>
                <input id="email"
                       name="email"
                       ref="email"
                       type="text"
                       v-model="value.email">
            </div>
            <div class="form-group">
                <label for="roles">
                    {{ ucf$t("user.role") }}
                </label>
                <multiselect :close-on-select="false"
                             :multiple="true"
                             :options="roleOptions"
                             :placeholder="ucf$t('user.select_role')"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="roles"
                             label="name"
                             name="roles[]"
                             ref="roles"
                             select-label=""
                             selected-label=""
                             track-by="name"
                             v-model="value.roles" />
            </div>
            <file-input class="file-upload"
                        default="/"
                        v-model="image">
                <template v-slot:default="{image,isLoading}">
                    <img :src="image"
                         class="profile"
                         v-if="!image.isLoading && image !== '/'">
                    <div class="profile with-background"
                         v-else>
                        {{ value.firstName.charAt(0).toUpperCase() }}
                        {{ value.lastName.charAt(0).toUpperCase() }}
                    </div>
                    <label>
                        {{ ucf$t("user.add_image") }}
                    </label>
                </template>
            </file-input>
            <button class="primary"
                    type="submit">
                {{ ucf$t('user.add_employee') }}
            </button>
        </form>
    </base-panel>
</template>

<script>
import Multiselect from 'vue-multiselect';
import BasePanel from '../../components/BasePanel';
import FileInput from '../../components/FileInput';

export default {
    name: 'CustomerShowStaffCreate',
    components: { FileInput, BasePanel, Multiselect },
    data() {
        return {
            image: null,
            value: {
                firstName: '',
                lastName: '',
            },
            roleOptions: [],
        };
    },
    methods: {
        close() {
            this.$router.replace({
                name: 'customers.show.staff',
                params: this.$route.params,
            });
        },
        submit() {
            const formData = new FormData(this.$refs.employeeForm);

            formData.delete('roles[]');

            if (this.image !== null && typeof this.image !== 'string') {
                formData.append('image', this.image);
            }

            this.value.roles.forEach((role) => {
                formData.append('roles[]', role.id);
            });

            this.$http.post('users/create', formData)
                .then(() => {
                    this.$router.replace({
                        name: 'settings.staff',
                    });
                })
                .catch((error) => {
                    this.handleErrors(error.response);
                });
        },
    },
    computed: {
        customerId: {
            get() {
                if (this.$route.params.customerId) {
                    return this.$route.params.customerId;
                }

                return this.authUser.customerId;
            },
        },
    },
    beforeMount() {
        this.$http.get('/roles')
            .then((response) => {
                this.roleOptions = response.data.data;
            });
    },
    mounted() {
        this.$refs.panel.open();
    },
    beforeRouteLeave(to, from, next) {
        this.$refs.panel.beforeRouteLeave(to, from, next);
    },
};
</script>
