<template>
    <form @change="submit"
          v-if="!empty(customer) && !empty(customer.address)">
        <div class="company-details  company-details--with-address">
            <div class="address">
                <h3 class="company-details__heading">
                    {{ ucf$t('settings.company_details') }}
                </h3>

                <label for="companyName">
                    {{ ucf$t('settings.company_name') }}
                </label>
                <input id="companyName"
                       ref="name"
                       type="text"
                       v-model="customer.name">

                <label for="contactPerson">
                    {{ ucf$t('settings.contact_person') }}
                </label>
                <multiselect :close-on-select="true"
                             :multiple="false"
                             :options="contacts"
                             @close="submit"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="contactPerson"
                             label="fullName"
                             select-label=""
                             selected-label=""
                             track-by="id"
                             v-model="customer.contactPerson" />
                <div class="company-details__field-wrap">
                    <div class="field">
                        <label for="kvkNumber">{{ $t('settings.kvk_number') }}</label>
                        <input id="kvkNumber"
                               ref="kvkNumber"
                               type="text"
                               v-model="customer.kvkNumber">
                    </div>

                    <div class="field">
                        <label for="btwNumber">{{ $t('settings.btw_number') }}</label>
                        <input id="btwNumber"
                               ref="btwNumber"
                               type="text"
                               v-model="customer.btwNumber">
                    </div>
                </div>

                <file-input :value="customer.logo"
                            @input="logoImageChanged"
                            class="company-details__upload-logo"
                            default="/">
                    <template v-slot:default="{image,isLoading}">
                        <img :src="image"
                             alt="logo"
                             class="company-details__upload-logo__image"
                             v-if="!isLoading && image !== '/'">

                        <span class="company-details__upload-logo__image company-details__upload-logo__image--placeholder"
                              v-else />

                        <span class="company-details__upload-logo__text">
                            Bedrijfslogo wijzigen
                        </span>
                    </template>
                </file-input>
            </div>

            <div class="address">
                <h3 class="company-details__heading">
                    {{ $t('ISAH Details') }}
                </h3>

                <label for="isahClientNumber">
                    {{ $t("ISAH Client Number") }}
                </label>
                <input id="isahClientNumber"
                       name="isahClientNumber"
                       ref="isahClientNumber"
                       type="text"
                       v-model="customer.isahClientNumber">
                <label for="isahContactId">
                    {{ $t("ISAH Contact ID") }}
                </label>
                <input id="isahContactId"
                       name="isahContactId"
                       ref="isahContactId"
                       type="text"
                       v-model="customer.isahContactId">
            </div>
        </div>

        <div class="company-details company-details--with-address">
            <div class="address">
                <h3 class="company-details__heading">
                    {{ ucf$t('settings.address_title') }}
                </h3>

                <div class="company-details__field-wrap">
                    <div class="field">
                        <label for="address.address">
                            {{ ucf$t('settings.address') }}
                        </label>
                        <input id="address.address"
                               ref="address.address"
                               type="text"
                               v-model="customer.address.address">
                    </div>

                    <div class="field">
                        <label for="address.postalCode">
                            {{ ucf$t('settings.postal_code') }}
                        </label>
                        <input id="address.postalCode"
                               ref="address.postalCode"
                               type="text"
                               v-model="customer.address.postalCode">
                    </div>
                </div>

                <div class="company-details__field-wrap">
                    <div class="field">
                        <label for="address.city">
                            {{ ucf$t('settings.city') }}
                        </label>
                        <input id="address.city"
                               ref="address.city"
                               type="text"
                               v-model="customer.address.city">
                    </div>

                    <div class="field">
                        <label for="address.country">
                            {{ ucf$t('settings.country') }}
                        </label>
                        <multiselect :close-on-select="true"
                                     :multiple="false"
                                     :options="countries"
                                     @close="submit"
                                     class="w-1/4 mr-4"
                                     deselect-label=""
                                     id="address.country"
                                     label="name"
                                     select-label=""
                                     selected-label=""
                                     track-by="id"
                                     v-model="customer.address.country" />
                    </div>
                </div>
            </div>

            <div class="address">
                <h3 class="company-details__heading">
                    {{ ucf$t('settings.invoice_address_title') }}
                </h3>

                <div class="company-details__field-wrap">
                    <div class="field">
                        <label for="invoiceAddress.address">
                            {{ ucf$t('settings.address') }}
                        </label>
                        <input id="invoiceAddress.address"
                               ref="invoiceAddress.address"
                               type="text"
                               v-model="customer.invoiceAddress.address">
                    </div>

                    <div class="field">
                        <label for="invoiceAddress.postalCode">
                            {{ ucf$t('settings.postal_code') }}
                        </label>
                        <input id="invoiceAddress.postalCode"
                               ref="invoiceAddress.postalCode"
                               type="text"
                               v-model="customer.invoiceAddress.postalCode">
                    </div>
                </div>

                <div class="company-details__field-wrap">
                    <div class="field">
                        <label for="invoiceAddress.city">
                            {{ ucf$t('settings.city') }}
                        </label>
                        <input id="invoiceAddress.city"
                               ref="invoiceAddress.city"
                               type="text"
                               v-model="customer.invoiceAddress.city">
                    </div>

                    <div class="field">
                        <label for="invoiceAddress.country">
                            {{ ucf$t('settings.country') }}
                        </label>
                        <multiselect :close-on-select="true"
                                     :multiple="false"
                                     :options="countries"
                                     @close="submit"
                                     class="w-1/4 mr-4"
                                     deselect-label=""
                                     id="invoiceAddress.country"
                                     label="name"
                                     select-label=""
                                     selected-label=""
                                     track-by="id"
                                     v-model="customer.invoiceAddress.country" />
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import FileInput from '../../components/FileInput';

    export default {
        name: 'CustomerGeneral',
        components: { FileInput, Multiselect },
        methods: {
            logoImageChanged(logo) {
                if (logo.constructor !== File) {
                    return;
                }

                const formData = new FormData();
                formData.append('logo', logo);

                this.customer.logo = logo;

                this.$http.post(`/customers/${this.$parent.customer.id}/update-logo`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
            submit() {
                const data = new FormData();

                Object.entries(this.customer).forEach(([key, value]) => {
                    this.appendToFormData(data, key, value);
                });

                this.$http.post(`/customers/${this.$parent.customer.id}/edit`, data)
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        computed: {
            contacts: {
                get() {
                    return this.$parent.contacts;
                },
            },
            countries: {
                get() {
                    return this.$parent.countries;
                },
            },
            customer: {
                get() {
                    return this.$parent.customer;
                },
            },
        },
    };
</script>
