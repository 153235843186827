import Api from '../../configuration/api';
import { auth as authConfig } from '../../configuration/config';

window.loadProfile = async () => Api.get('/users/current')
    .then((response) => {
        const user = response.data.data;
        localStorage.setItem(
            'webo_bim_user_data',
            JSON.stringify(user),
        );
    })
    .catch((error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('webo_bim_token');
            localStorage.removeItem('webo_bim_user_data');
            localStorage.removeItem('webo_bim_expires_at');
            localStorage.removeItem('webo_bim_refresh_token');
        }
        return error;
    });

export default function auth({ to, next, authenticator }) {
    if (to.name === 'auth.logout') {
        authenticator.logout();
        localStorage.removeItem('webo_bim_user_data');
        next(authConfig.options.redirects.login);
        return;
    }

    authenticator.check()
        .then(next)
        .catch(() => {
            next(authConfig.options.redirects.failed);
        });
}
