<template>
    <base-modal ref="modal">
        <template v-slot:header>
            {{ ucf$t("projects.finish") }}
        </template>
        <p>
            {{ ucf$t("projects.finish_description") }}
        </p>
        <template v-slot:footer>
            <button @click="confirm"
                    class="primary">
                {{ $t("Yes") }}
            </button>
            <button @click="close"
                    class="secondary">
                {{ $t("No") }}
            </button>
        </template>
    </base-modal>
</template>

<script>
import BaseModal from "../../components/BaseModal";

export default {
    name: 'ProjectFinish',
    components: {
        BaseModal,
    },
    methods: {
        close() {
            this.$router.replace({
                name: 'projects.show',
                params: { projectId: this.project.id },
            });
        },
        confirm() {
            this.$http
                .post(`/projects/${this.project.id}/status`, {
                    projectStatus: this.statuses.FINISHED,
                })
                .then(() => {
                    this.$router.replace({
                        name: 'projects.show',
                        params: { projectId: this.project.id },
                    });
                })
                .catch((error) => {
                    this.handleErrors(error.response);
                });
        },
    },
    computed: {
        project() {
            return Object.assign({}, this.$parent.project);
        },
    },
    mounted() {
        this.$refs.modal.open();
    },
};
</script>
