<template>
    <div class="form-element-fields">
        <base-checkbox v-model="defaultValue">
            {{ $t(`Default state of the checkbox is ${checkboxDefault}`) }}
        </base-checkbox>
        <base-checkbox v-model="formElement.updatesParameter">
            {{ $t('This checkbox should update the value of the parameter') }}
        </base-checkbox>
        <base-input :label="$t('Value when on')"
                    id="whenOn"
                    name="whenOn"
                    v-model="formElement.whenOn" />
        <base-input :label="$t('Value when off')"
                    id="whenOff"
                    name="whenOff"
                    v-model="formElement.whenOff" />
    </div>
</template>

<script>
import BigInput from './BigInput';
import BaseCheckbox from './BaseCheckbox';
import BaseInput from "./BaseInput";

export default {
        name: 'FormElementGroupCheckboxFields',
        components: { BaseInput, BaseCheckbox, BigInput },
        props: {
            value: {},
        },
        computed: {
            formElement: {
                get() {
                    return this.value;
                },
            },
            defaultValue: {
                get() {
                    return this.formElement.default === true
                        || this.formElement.default === "2";
                }, set(value) {
                    this.formElement.default = value === true ? "2" : "1";
                }
            },
            checkboxDefault: {
                get() {
                    return this.formElement.default ? 'on' : 'off';
                },
            },
        },
    };
</script>
