<template>
    <form-panel
            @overlay-clicked="close"
            @submit.prevent="submit"
            ref="panel"
    >
        <template v-slot:header>
            <h1>{{ $t('Add formula') }}</h1>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="name">{{ $t('Name') }}</label>
                <input id="name" name="name" ref="name" type="text" v-model="formula.name" />
            </div>
            <div class="form-group">
                <label for="formulaType">{{ $t('Formula type') }}</label>
                <select id="formulaType" name="formulaType" v-model="formula.formulaType">
                    <option :value="undefined" />
                    <option :value="id" v-for="(id, name) in types" :key="id">{{ $t(name) }}</option>
                </select>
            </div>
            <price-matrix-form
                    v-if="formula.formulaType === 1"
                    :formula="formula"
                    :parameters="parameters"
            />
            <basic-form v-if="formula.formulaType === 2" :formula="formula" :parameters="parameters" />
            <fixed-form v-if="formula.formulaType === 3" :formula="formula" />
            <percentage-form v-if="formula.formulaType === 4" :formula="formula" />

            <div class="form-group">
                <label for="conditions">
                    {{ $t('Conditions') }}
                </label>
                <textarea disabled
                          id="conditions"
                          name="conditions"
                          ref="conditions"
                          style="font-family: monospace;"
                          v-text="($refs.conditionsModal || {}).conditionString" />
            </div>
            <button @click="$refs.conditionsModal.open()"
                    class="secondary no-bottom"
                    type="button">
                {{ $t('Change conditions') }}
            </button>
            <conditions-modal ref="conditionsModal"
                              :parameters="parameters"
                              :type="formula.name"
                              v-model="formula.conditions" />
            <button type="submit" class="primary">
                {{ $t('Create') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from "../../components/FormPanel";
    import { FORUMULA_TYPES } from "../../models/Formula";
    import FixedForm from "../../components/Formulas/FormulaFixedForm";
    import BasicForm from "../../components/Formulas/FormulaBasicForm";
    import PercentageForm from "../../components/Formulas/FormulaPercentageForm";
    import PriceMatrixForm from "../../components/Formulas/FormulaPriceMatrixForm";
    import ConditionsModal from "../../components/ConditionsModal";

    export default {
        name: "Create",
        components: {
            FormPanel,
            FixedForm,
            BasicForm,
            PercentageForm,
            PriceMatrixForm,
            ConditionsModal
        },
        data() {
            return {
                formula: { name: "", conditions: { groups: [], compareMethod: '|' } },
                types: FORUMULA_TYPES,
                parameters: []
            };
        },

        methods: {
            submit() {
                this.$http.post("formulas/create", this.formula).then(() => {
                    this.close();
                }).catch((e) => {
                    this.handleErrors(e.response);
                });
            },

            close() {
                this.$router.replace({
                    name: "formulas.index"
                });
            }
        },

        mounted() {
            this.$http
                .get("parameters", { take: 0 })
                .then(
                    ({ data }) => (this.parameters = this.parameters.concat(data.data))
                );
        },

        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        }
    };
</script>

<style lang="scss" scoped>
    .condition {
        margin           : 4px 0;
        border-radius    : 4px;
        padding          : 8px 16px;
        background-color : #e6e1e4;
        font-weight      : 600;
    }
</style>