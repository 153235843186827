<template>
    <auth-layout>
        <div class="login-content">
            <h1 class="small-margin"
                v-if="$route.query.newUser == true">
                {{ ucf$t('auth.password.reset.header') }}<span class="dot">.</span>
            </h1>
            <h1 class="small-margin"
                v-else>
                {{ ucf$t('auth.password.reset.activation.header') }}<span class="dot">.</span>
            </h1>
            <p class="sub-title"
               v-if="$route.query.newUser == true">
                {{ ucf$t('auth.password.reset.subtitle') }}
            </p>
            <p class="sub-title"
               v-else>
                {{ ucf$t('auth.password.reset.activation.subtitle') }}
            </p>
            <form @submit.prevent="submit"
                  class="login-form"
                  method="post">
                <input :placeholder="ucf$t('user.password')"
                       autofocus
                       class="big full-width"
                       name="password"
                       type="password"
                       v-model="password">
                <input :placeholder="ucf$t('user.password_confirm')"
                       class="big full-width"
                       name="passwordConfirmation"
                       type="password"
                       v-model="passwordConfirmation">
                <button class="primary big full-width"
                        v-if="!!$route.query.newUser">
                    {{ ucf$t('auth.password.reset.button') }}
                </button>
                <button class="primary big full-width"
                        v-else>
                    {{ ucf$t('auth.password.reset.activation.button') }}
                </button>
            </form>
        </div>
    </auth-layout>
</template>

<script>
    import AuthLayout from '../Layouts/AuthLayout';

    export default {
        name: 'PasswordReset',
        components: { AuthLayout },
        data() {
            return {
                password: '',
                passwordConfirmation: '',
            };
        },
        methods: {
            submit() {
                this.$http
                    .post('/password/reset', {
                        token: this.$route.params.token,
                        email: this.$route.query.email,
                        newUser: this.$route.query.newUser || false,
                        password: this.password,
                        password_confirmation: this.passwordConfirmation,
                    })
                    .then(() => {
                        this.login({
                            email: this.$route.query.email,
                            password: this.password,
                        })
                            .then(() => {
                                const redirect = (this.$route.query.newUser || false)
                                    ? { name: 'onboarding.show' }
                                    : { name: 'projects.index', params: { status: 1 } };

                                this.$router.push(redirect);
                            });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
    };
</script>
