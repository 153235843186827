import { roles } from '../../configuration/variables';
import FrameService from '../../services/FrameService';
import WindowFrameCreate from '../../views/WindowFrame/WindowFrameCreate';
import WindowFrameEdit from '../../views/WindowFrame/WindowFrameEdit';
import WindowFrameFormulas from '../../views/WindowFrame/WindowFrameFormulas';
import WindowFrameGeneral from '../../views/WindowFrame/WindowFrameGeneral';
import WindowFrameFamilies from '../../views/WindowFrame/WindowFrameFamilies';
import WindowFrameParameters from '../../views/WindowFrame/WindowFrameParameters';
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import ModelBoundView from '../utils/ModelBoundView';
import WindowFrameCopy from '../../views/WindowFrame/WindowFrameCopy';

export default [
    {
        name: 'window-frames.create',
        path: '/window-frame-group/:frameGroupId/window-frames/create',
        component: WindowFrameCreate,
        children: [
            {
                name: 'window-frames.create.general',
                path: 'general',
                component: WindowFrameGeneral,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frames.create.parameters',
                path: 'parameters',
                component: WindowFrameParameters,
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'window-frames.create.formulas',
                path: 'formulas',
                component: WindowFrameFormulas,
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                        ({ from, next }) => {
                            if (from.name === null) {
                                return next({ name: 'window-frames.create.general' });
                            }

                            return next();
                        },
                    ],
                },
            },
            {
                name: 'window-frames.create.families',
                path: 'families',
                component: WindowFrameFamilies,
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
        ],
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
        },
    },
    {
        name: 'window-frames.edit',
        path: '/window-frames/:frameId',
        component: ModelBoundView(new FrameService(), WindowFrameEdit),
        children: [
            {
                name: 'window-frames.edit.general',
                path: 'general',
                component: WindowFrameGeneral,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frames.edit.parameters',
                path: 'parameters',
                component: WindowFrameParameters,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frames.edit.formulas',
                path: 'formulas',
                component: WindowFrameFormulas,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frames.edit.families',
                path: 'families',
                component: WindowFrameFamilies,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => next({ ...to, name: 'window-frames.edit.general' }),
            ],
        },
    },
    {
        name: 'window-frames.copy',
        path: '/copy/window-frames/:frameId',
        component: ModelBoundView(new FrameService(), WindowFrameCopy),
        children: [
            {
                name: 'window-frames.copy.general',
                path: 'general',
                component: WindowFrameGeneral,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frames.copy.parameters',
                path: 'parameters',
                component: WindowFrameParameters,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frames.copy.formulas',
                path: 'formulas',
                component: WindowFrameFormulas,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frames.copy.families',
                path: 'families',
                component: WindowFrameFamilies,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => next({ ...to, name: 'window-frames.copy.general' }),
            ],
        },
    },
];
