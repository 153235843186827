<template>
    <div class="base-checkbox"
         @click="$emit('input', !value)">
        <span class="checkbox"
              :class="{'active' : value}" />
        <div class="text-container">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BaseCheckbox',
        props: {
            value: {},
        },
    };
</script>
