import { roles } from '../../configuration/variables';
import ModuleService from '../../services/ModuleService';
import ModuleCreate from '../../views/Module/ModuleCreate';
import ModuleEdit from '../../views/Module/ModuleEdit';
import ModuleIndex from '../../views/Module/ModuleIndex';
import ModuleDelete from '../../views/Module/ModuleDelete';
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import ModelBoundView from '../utils/ModelBoundView';


export default [
    {
        name: 'modules.index',
        path: '/modules',
        component: ModuleIndex,
        children: [
            {
                name: 'modules.create',
                path: 'create',
                component: ModuleCreate,
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'modules.edit',
                path: ':moduleId/edit',
                component: ModelBoundView(new ModuleService(), ModuleEdit),
                meta: {
                    isRevitOption: true,
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'modules.delete',
                path: ':moduleId/delete',
                component: ModuleDelete,
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
        ],
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    if (!to.params.category) {
                        return next({ name: 'modules.index' });
                    }

                    return next();
                },

            ],
            forbiddenRoute: { name: 'modules.index' },
        },
    },
];
