<template>
    <form-panel @overlay-clicked="close"
                @submit.prevent="submit"
                class="sidebar-container"
                panel-class="sidebar"
                ref="panel">
        <template v-slot:header>
            <h1>
                {{ $t('Update parameter') }}
            </h1>
            <div class="edit"
                 @click="toggleDrop">
                <img alt="edit"
                     src="/images/icons/dots-icon.svg">
                <div :class="{'active' : showDrop}"
                     class="drop">
                    <button type="button"
                            @click="deleteParameter">
                        {{ $t("Delete parameter") }}
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="name">
                    {{ $t('Name') }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="projectParameter.name">
            </div>
            <div class="form-group">
                <label for="formName">
                    {{ $t('Form') }}
                </label>
                <select id="formName"
                        name="formName"
                        v-model="projectParameter.form">
                    <option :value="undefined" />
                    <option :value="form.id"
                            v-for="(form, index) of forms"
                            :key="index">
                        {{ form.name }}
                    </option>
                </select>
            </div>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ $t('Update') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from "../../components/FormPanel";

    export default {
        name: "ProjectParameterEdit",

        components: {
            FormPanel
        },

        data() {
            return {
                forms: [],
                showDrop: false,
            };
        },

        methods: {
            submit() {
                this.$http.post(`project-parameters/${this.projectParameter.id}/edit`, this.projectParameter)
                    .then(() => {
                        this.close();
                    })
                    .catch((e) => {
                        this.handleErrors(e.response);
                    });
            },

            close() {
                this.$router.replace({
                    name: 'project-parameters.index',
                });
                this.$parent.getParameters();
            },

            toggleDrop() {
                this.showDrop = !this.showDrop;
            },

            deleteParameter() {
                this.$http.post(`project-parameters/${this.projectParameter.id}/delete`, this.projectParameter)
                    .then(() => {
                        this.close();
                    });
            },
        },

        beforeMount() {
            this.$http.get('/forms')
                .then((response) => {
                    this.forms = response.data.data;
                });
        },

        mounted() {
            this.$refs.formPanel.open();
        },

        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        },
    }
</script>