<template>
    <div :key="$route.query.t"
         class="project-detail-container"
         v-if="!empty(project.name)">
        <div class="detail">
            <div class="project">
                <div class="header">
                    <transition name="detail">
                        <div class="name"
                             v-if="project.name !== ''">
                            <h1>
                                {{ ucfirst(project.name) }}
                            </h1>

                            <span :class="projectStatus"
                                  class="status">
                                {{ $t(projectStatus) }}
                            </span>
                        </div>
                    </transition>
                    <div class="circles">
                        <div class="edit">
                            <img alt="edit"
                                 src="/images/icons/dots-icon.svg">
                            <div class="popover">
                                <div class="menu">
                                    <router-link
                                        v-if="! project.isCanceled && project.projectStatus.id < statuses.ACCEPTED_BY_CLIENT"
                                        :to="{ name: 'projects.edit', params: { projectId: project.id }, query: $route.query }"
                                        class="menu-item">
                                        {{ ucf$t("projects.edit") }}
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'projects.notifications', params: { projectId: project.id }, query: $route.query }"
                                        class="menu-item"
                                        v-auth.global-admin:invert>
                                        {{ ucf$t("projects.notifications") }}
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'projects.cancel', params: { projectId: project.id }, query: $route.query }"
                                        class="menu-item"
                                        v-if="! project.isCanceled && project.projectStatus.id < statuses.ACCEPTED_BY_CLIENT">
                                        {{ ucf$t("projects.cancel") }}
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'projects.reopen', params: { projectId: project.id }, query: $route.query }"
                                        class="menu-item"
                                        v-if="project.isCanceled">
                                        {{ ucf$t("projects.reopen") }}
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'projects.in-production', params: { projectId: project.id }, query: $route.query }"
                                        class="menu-item"
                                        v-auth.global-admin
                                        v-if="project.projectStatus.id === statuses.ACCEPTED_BY_CLIENT">
                                        {{ ucf$t("projects.put_in_production") }}
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'projects.finish', params: { projectId: project.id }, query: $route.query }"
                                        class="menu-item"
                                        v-auth.global-admin
                                        v-if="project.projectStatus.id === statuses.IN_PRODUCTION">
                                        {{ ucf$t("projects.finish") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="description"
                   v-if="project.description">
                    {{ project.description }}
                </p>
            </div>

            <div class="tabs">
                <div class="tab-item active">
                    {{ ucf$t("projects.products") }}
                </div>
            </div>
            <div class="product-detail">
                <transition name="detail">
                    <empty-state v-if="project && project.hasFrames === false" />
                    <frame-details v-else />
                </transition>
            </div>
            <bottom-bar />
        </div>
        <div class="overview"
             :class="{'batches' : showBatches}">
            <batches v-if="showBatches"
                     ref="batches" />
            <project-images v-else />
        </div>
        <transition mode="out-in"
                    name="fade">
            <router-view />
        </transition>
    </div>
</template>

<script>
    import EmptyState from './ShowComponents/empty-state';
    import FrameDetails from './ShowComponents/frame-details';
    import BottomBar from './ShowComponents/bottom-bar';
    import ProjectImages from './ShowComponents/project-images';
    import Batches from './ShowComponents/batches';

    export default {
        name: 'ProjectShow',
        components: {
            Batches,
            ProjectImages,
            BottomBar,
            FrameDetails,
            EmptyState,
        },
        computed: {
            showBatches: {
                get() {
                    return this.$route.name.includes('projects.batches');
                },
            },

            framesPrices: {
                get() {
                    return this.project.prices.find(price => price.module.id === this.project.module.id);
                },
            },

            activeFrame: {
                get() {
                    if (this.empty(this.$route.query.frame)) {
                        return null;
                    }

                    return this.framesPrices.frames.find(framePrice => framePrice.frame.id === parseInt(this.$route.query.frame, 10));
                },
            },
            projectStatus: {
                get() {
                    const statusses = Object.assign({}, ...Object.entries(this.statuses)
                        .map(([key, value]) => ({ [value]: key.toLocaleLowerCase() })));

                    if (this.project.isCanceled) return statusses[this.statuses.CANCELLED];

                    return statusses[this.project.projectStatus.id];
                },
            },
        },
    };
</script>
