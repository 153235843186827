<template>
    <form-panel ref="panel"
                @overlay-clicked="close"
                @submit="submit">
        <template v-slot:header>
            <h1>
                {{ revitType.name }} {{ $t("Create").toLowerCase() }}
            </h1>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="key">
                    {{ $t("Name") }}
                </label>
                <input id="key"
                       name="key"
                       ref="key"
                       type="text"
                       v-model="formData.key">
            </div>
            <div class="form-group">
                <label for="value">
                    {{ $t("Value") }}
                </label>
                <input id="value"
                       name="value"
                       ref="value"
                       type="text"
                       v-model="formData.value">
            </div>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ $t('Store') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from "../../components/FormPanel";

    export default {
        name: "RevitOptionCreate",

        components: {
            FormPanel,
        },

        data() {
            return {
                formData: {}
            }
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'revit-options.index',
                    params: this.$route.params
                });
            },

            submit() {
                const { formData } = this;

                this.$http.post(`/revit-types/${this.revitType.id}/options/create`, formData)
                    .then(this.close)
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },

        computed: {
            revitType:
                {
                    get() {
                        return this.$parent.$parent.revitType;
                    }
                }
        },

        mounted() {
            this.$refs.panel.open();
        },

        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        },
    }
</script>
