export default class Formula {
    constructor() {
        this.formula = "";
        this.widthFormula = "";
        this.heightFormula = "";
        this.price = "";
        this.conditions = [];
        this.percentage = "";
    }
}

export const FORUMULA_TYPES = {
    price_matrix: 1,
    basic: 2,
    fixed: 3,
    percentage: 4
};
