<template>
    <div @click="click"
         class="file-input-wrapper">
        <input @change="fileChanged"
               class="file-input"
               ref="fileInput"
               type="file"
               :name="name">
        <div :key="image"
             class="file-input-button">
            <slot :image="image"
                  :is-loading="isLoading" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'FileInput',
    props: {
        value: {},
        default: {
            required: false,
            type: String,
            default() {
                return '/images/happy-emoji.png';
            },
        },
        createBase64: {
            required: false,
            type: Boolean,
            default() {
                return true;
            },
        },
        name: {
            required: false,
            type: String,
            default() {
                return null;
            },
        }
    },
    data() {
        return {
            base64: null,
            isLoading: false,
        };
    },
    methods: {
        getBase64(file) {
            this.isLoading = true;
            return (new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            })).then((res) => {
                this.isLoading = false;

                return res;
            });
        },
        click(e) {
            this.$refs.fileInput.click(e);
        },
        async fileChanged(e) {
            const file = Array.from(e.target.files)[0];

            this.$emit('input', file);

            if (this.createBase64) {
                this.base64 = await this.getBase64(file);
            } else {
                this.base64 = true;
            }
        },
    },
    computed: {
        image: {
            get() {
                if (this.base64 !== null) {
                    return this.base64;
                }

                if (typeof this.value === 'string') {
                    return this.value;
                }

                return this.default;
            },
        },
    },
};
</script>
