<template>
    <div class="overview">
        <div class="header">
            <h1>
                {{ $t('Frame group') }}
            </h1>
            <button @click="store"
                    class="primary"
                    type="button">
                {{ $t('Update') }}
            </button>
        </div>
        <div class="tabs">
            <router-link :to="{ name: 'window-frame-groups.edit.general' }"
                         class="tab-item">
                {{ $t('General') }}
            </router-link>
            <router-link :to="{ name: 'window-frame-groups.edit.frames' }"
                         class="tab-item">
                {{ $t('Frames') }}
            </router-link>
        </div>

        <router-view v-if="!empty(this.frameGroup.id)" />
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'WindowFrameGroupEdit',
        methods: {
            store() {
                this.$http.post(`frame-groups/${this.frameGroup.id}/edit`, this.frameGroup)
                    .then(() => {
                        this.$router.replace({
                            name: 'window-frame-groups.index',
                            query: { t: moment().format('HHmmssSSS') },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
    };
</script>
