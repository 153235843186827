import Vue from 'vue';
import configuration from './configuration';
import { executeMiddleware } from './router/middleware';

Vue.config.productionTip = false;

const app = new Vue(configuration);

configuration.router.beforeEach((to, from, next) => {
    app.reloadNotifications();
    app.reloadUser();
    executeMiddleware(to, from, next, configuration.router, app);

    return next();
});
