<template>
    <base-panel panel-class="modal project-approve-modal big"
                @overlay-clicked="close"
                ref="panel">
        <div class="header">
            {{ ucf$t("projects.approve") }}
            <div class="close">
                <img src="/images/icons/close-icon.svg"
                     @click="close">
            </div>
        </div>
        <div class="body">
            <div :class="{'active' : !project.hasCancellation}" class="approve-option"
                 v-on:click="setCancellation(false)">
                <span class="checkbox">
                </span>
                <div class="text-container">
                    <span class="amount">
                        &euro; {{ numberFormat(project.price) }}
                    </span>
                    <span class="description">
                        {{ ucfirst($t("projects.without_cancellation")) }}
                    </span>
                </div>
            </div>

            <div :class="{'active' : project.hasCancellation}"
                 class="approve-option"
                 @click="setCancellation(true)">
                <span class="checkbox">
                </span>
                <div class="text-container">
                    <span class="amount">
                        &euro; {{ numberFormat(project.price * cancelationPercentage) }}
                    </span>
                    <span class="description">
                        {{ ucfirst($t("projects.with_cancellation")) }}
                    </span>
                    <span class="sub-description">
                        {{ ucfirst($t("projects.cancellation_days")) }}
                    </span>
                </div>
            </div>
        </div>
        <div class="footer">
            <button class="primary" v-if="false" v-on:click="">
                <!-- todo: payment method implementation and check-->
                {{ ucfirst($t("projects.approve_and_pay")) }}
            </button>
            <button class="primary" v-else v-on:click="confirm">
                {{ ucfirst($t("projects.approve")) }}
            </button>
        </div>
    </base-panel>
</template>

<script>
    import BasePanel from '../../components/BasePanel';

    export default {
        name: 'ProjectApprove',
        components: { BasePanel },
        data() {
            return {
                project: {},
                cancelationPercentage: (process.env.MIX_WEBO_CANCELLATION_PERCENTAGE / 100) + 1,
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: { projectId: this.project.id },
                });
            },
            setCancellation(bool) {
                this.project.hasCancellation = bool;
            },
            confirm() {
                this.$http.post(`/projects/${this.project.id}/status`, {
                    projectStatus: this.statuses.ACCEPTED_BY_CLIENT,
                    hasCancellation: this.project.hasCancellation,
                }).then(() => {
                    this.$router.replace({
                        name: 'projects.batches',
                        params: { projectId: this.project.id },
                    });
                }).catch((error) => {
                    this.handleErrors(error.response);
                });
            }
        },
        mounted() {
            this.$refs.panel.open();
            this.project = Object.assign({}, this.$parent.project);
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        },
    };
</script>
