<template>
    <base-modal ref="modal">
        <template v-slot:header>
            {{ $t("Delete Module") }}
        </template>
        <p>
            {{ $t("You sure you want to delete this module") }}
        </p>
        <template v-slot:footer>
            <button @click="confirm"
                    class="primary">
                {{ $t("Yes") }}
            </button>
            <button @click="close"
                    class="secondary">
                {{ $t("No") }}
            </button>
        </template>
    </base-modal>
</template>

<script>
    import BaseModal from "../../components/BaseModal";

    export default {
        name: 'ModulesDelete',
        components: {
            BaseModal,
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'modules.index',
                    params: { category: this.$route.params.category },
                });
            },
            confirm() {
                this.$http
                    .post(`modules/${this.$route.params.moduleId}/delete`, {})
                    .then(() => {
                        this.$router.replace({
                            name: 'modules.index',
                            params: { category: this.$route.params.category },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.$refs.modal.open();
        },
    };
</script>
