<template>
    <div class="products">
        <horizontal-select v-if="this.project.projectStatus.id === this.statuses.OPEN"
                           v-model="project.module"
                           :options="project.prices"
                           show-by="module.name"
                           track-by="module.id"
                           track-by-value="id"
                           @slide="updateModule"
        >
            <template v-slot:default="{option}">
                <strong v-text="option.module.name" />
                <span v-if="project.module.id !== option.module.id && !isNaN(getPriceDifference(option))">
                    <template v-if="getPriceDifference(option) != 0">
                        € {{ numberFormat(getPriceDifference(option)) }}
                    </template>
                    <template v-else>
                        Geen prijsverschil
                    </template>
                </span>
                <span v-else-if="project.module.id !== option.module.id">
                    Prijsverschil kan niet berekend worden
                </span>
            </template>
        </horizontal-select>

        <draggable
            id="source"
            :group="{ name: 'projects', pull: 'clone', put: false }"
            :list="framePrices.frames"
            class="table">
            <div v-for="framePrice in framePrices.frames"
                 :class="{active: !empty(activeFrame) && activeFrame.frame.id === framePrice.frame.id}"
                 class="row"
                 @click.prevent="openFrameDetail(framePrice.frame)">
                <div class="column">
                    {{ framePrice.frame.amount }}x
                </div>
                <div class="column">
                    {{ getFrameName(framePrice.frame) }}<br>
                    <span class="small-text">{{ getFrameFilling(framePrice.frame) }}</span>
                </div>
                <div class="column"
                     v-if="isNaN(framePrice.total)">
                    {{ framePrice.total }}
                </div>
                <div class="column"
                     v-else>
                    &euro;{{ numberFormat(framePrice.total) }}
                </div>
                <transition name="slide-y">
                    <div v-show="!empty(activeFrame) && activeFrame.frame.id === framePrice.frame.id"
                         class="object-detail">
                        <div class="horizontal-select">
                            <div class="option"
                                 :class="{active: !showPrices}"
                                 @click.stop="setShowPrices(false)">
                                Opties
                            </div>
                            <div class="option"
                                 :class="{active: showPrices}"
                                 @click.stop="setShowPrices(true)">
                                Prijzen
                            </div>
                        </div>

                        <template v-if="! showPrices">
                            <div v-for="([key,value]) of getFrameParameterDetails(framePrice.frame)"
                                 class="row">
                                <div class="column">
                                    {{ key }}
                                </div>
                                <div class="column">
                                    {{ value }}
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <table v-if="!(typeof framePrice.price === 'string' || framePrice.price instanceof String)">
                                <tr v-for="key in dynamicPrices(framePrice)"
                                    class="">
                                    <td class="">
                                        {{ $t(key) }}
                                    </td>
                                    <td></td>
                                    <td class="text-right">
                                        &euro; {{ numberFormat(framePrice.price[key]) }}
                                    </td>
                                </tr>
                                <template v-for="(sectionPrice, index) in framePrice.price.sections">
                                    <tr class="">
                                        <td class="">
                                            Vak {{ index + 1 }}
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr class=""
                                        v-for="(value, sectionKey) in sectionPrice">
                                        <td></td>
                                        <td class="">
                                            {{ $t(sectionKey) }}
                                        </td>
                                        <td class="text-right">
                                            &euro; {{ numberFormat(value) }}
                                        </td>
                                    </tr>
                                </template>
                                <tr class="border-top">
                                    <td class="">
                                        Totaal
                                    </td>
                                    <td></td>
                                    <td class="text-right">
                                        &euro; {{ numberFormat(framePrice.singlePrice) }}
                                    </td>
                                </tr>
                            </table>
                            <div v-else>
                                {{ framePrice.price }}
                            </div>
                        </template>
                    </div>
                </transition>
            </div>
        </draggable>
        <div class="total-price"
             v-if="isNaN(projectPrice)">
            {{ projectPrice }}
        </div>
        <div class="total-price"
             v-else>
            &euro; {{ numberFormat(projectPrice) }}
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import HorizontalSelect from '../../../components/HorizontalSelect';

    export default {
        name: 'frame-details',
        components: {
            HorizontalSelect,
            draggable,
        },
        data() {
            return {
                showPrices: false,
            };
        },

        methods: {
            getFrameName(frame) {
                let brandName = frame.parameters && frame.parameters.hasOwnProperty('Kozijnmerk - Kozijnmerk')
                    ? frame.parameters['Kozijnmerk - Kozijnmerk']
                    : frame.name;
                const mirrored = frame.parameters && frame.parameters.hasOwnProperty('isMirrored') && frame.parameters.isMirrored === '1'
                    ? '(gespiegeld)'
                    : '';

                return `${brandName} ${mirrored}`;
            },
            getFrameFilling(frame) {
                const frameSetup = Object.entries(frame.parameters)
                                         .filter(([parameter]) => parameter.includes('Type vulling'))
                                         .map(([, value]) => value);

                return frameSetup.join(', ');
            },
            getFrameParameterDetails(frame) {
                const details = [];

                Object.entries(frame.parameters)
                      .forEach(([key, value]) => {
                          if (key.startsWith('checkbox-')) {
                              return;
                          }

                          let parameterName = this.getParameterName(key);

                          if (parameterName === 'isMirrored') {
                              return;
                          }

                          details.push([
                              parameterName,
                              value,
                          ]);
                      });

                return details.sort(([keyA], [keyB]) => {
                    return keyA.localeCompare(keyB);
                });
            },
            getParameterName(key) {
                const translationKey = `parameters.segments.option-types.${key}`;
                const translation = this.ucf$t(translationKey);

                if (translation === this.ucfirst(translationKey)) {
                    return key;
                }

                return translation;
            },
            updateModule(priceOption) {
                this.$http.get(`/projects/${this.project.id}/module/${priceOption.module.id}/warnings`)
                    .then((response) => {
                        const warnings = response.data.warnings;

                        let confirmedAllWarnings = warnings.length === 0 || warnings.every((warning) => {
                            return confirm(warning);
                        });

                        if (warnings.length === 0 || confirmedAllWarnings) {
                            this.$http.put(`/projects/${this.project.id}/module`, { module: priceOption.module.id })
                                .then(() => {
                                    this.project.module = priceOption.module;
                                })
                                .catch(error => this.handleErrors(error.response));
                        }
                    })
                    .catch(error => this.handleErrors(error.response));
            },

            getPriceDifference(module) {
                return module.price - this.projectPrice;
            },

            openFrameDetail(frame) {
                this.showPrices = false;
                const route = this.$route.query.frame == frame.id ?
                    {
                        ...this.$route,
                        query: null,
                    } :
                    {
                        ...this.$route,
                        query: {
                            frame: frame.id,
                        },
                    };

                this.$router.push(route);
            },

            dynamicPrices(framePrice) {
                return Object.keys(framePrice.price).filter(key => ![
                    'sections',
                ].includes(key));
            },

            setShowPrices(value) {
                this.showPrices = value;
            },
        },

        computed: {
            project() {
                return this.$parent.project;
            },

            activeFrame() {
                return this.$parent.activeFrame;
            },

            projectPrice() {
                return this.project.prices.find(price => price.module.id === this.project.module.id).price;
            },

            framePrices() {
                return this.$parent.framesPrices;
            },

            frames() {
                return this.framePrices.map((framePrice) => framePrice.frame);
            },
        },
    };
</script>
