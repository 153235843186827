<template>
    <div :key="$route.params.status"
         class="parameter-container frame-form">
        <div class="header">
            <h1>
                {{ $t('Edit frame') }}
            </h1>
            <button @click="store"
                    class="primary"
                    type="button">
                {{ $t('Update') }}
            </button>
        </div>
        <div class="tabs">
            <router-link :to="{ ...$route, name: 'window-frames.copy.general' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('General') }}
            </router-link>
            <router-link :to="{ ...$route, name: 'window-frames.copy.parameters' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Parameters') }}
            </router-link>
            <router-link :to="{ ...$route, name: 'window-frames.copy.families' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Families') }}
            </router-link>
            <router-link :to="{ ...$route, name: 'window-frames.copy.formulas' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Formulas') }}
            </router-link>
        </div>

        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'WindowFrameCopy',

        data() {
            return {
                revitOptions: {},
            };
        },

        methods: {
            store() {
                const formData = new FormData();

                this.appendToFormData(formData, 'frameGroup', this.frame);
                Object.entries(this.frame).forEach(([key, value]) => {
                    this.appendToFormData(formData, key, value);
                });

                this.$http.post('/frames/create', formData)
                    .then(() => {
                        this.$router.replace({
                            name: 'window-frame-groups.edit.frames',
                            params: this.$route.params,
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },

        beforeMount() {
            this.$http.get('/forms')
                .then((response) => {
                    this.forms = response.data.data;
                });
        },

        onModelLoaded() {
            delete this.frame.id;
            delete this.frame.createdAt;
            delete this.frame.updatedAt;
            delete this.frame.name;
            delete this.frame.module;
            delete this.frame.preview;
            this.frame.families.forEach((family) => {
                delete family.name;
                delete family.isDefault;
                delete family.id;
                delete family.rfaFile;
                delete family.cncFile;
            });
        },
    };
</script>
