<template>
    <div class="form-element-fields">
        <div class="row"
             v-for="(option, index) in formElement.options"
             :key="`formElement-radio-${uuid}-option-${index}`">
            <div class="radio-icon" />
            <big-input id="name"
                       name="name"
                       :label="$t('Name')"
                       v-model="option.name" />
            <big-input id="value"
                       name="value"
                       :label="$t('Value')"
                       v-model="option.value" />
        </div>
        <div class="row">
            <div class="radio-icon" />
            <div class="form-group">
                <input id="name"
                       name="name"
                       title="Name"
                       :placeholder="$t('Name')"
                       class="big-input"
                       ref="newRadioName"
                       v-model="radio.name">
            </div>
            <div class="form-group">
                <input id="value"
                       name="value"
                       v-model="radio.value"
                       title="Value"
                       :placeholder="$t('Value')"
                       class="big-input"
                       @keydown.tab.prevent="addRow">
            </div>
        </div>
    </div>
</template>

<script>
    import BigInput from './BigInput';

    export default {
        name: 'FormElementGroupRadioFields',
        components: { BigInput },
        props: {
            value: {},
        },
        data() {
            return {
                radio: {},
            };
        },
        methods: {
            addRow() {
                if (!this.empty(this.radio.name) && !this.empty(this.radio.value)) {
                    this.formElement.options.push(Object.assign({}, this.radio));

                    this.radio = {};

                    this.$refs.newRadioName.focus();
                }
            },
        },
        computed: {
            formElement: {
                get() {
                    return this.value;
                },
            },
        },
    };
</script>
