<template>
    <base-overview slug="modules"
                   ref="overview">
        <template v-slot:head>
            <h1>{{ $t("Modules") }}</h1>
            <router-link :to="{ name: 'modules.create' }"
                         class="btn primary">
                {{ $t("Create module") }}
            </router-link>
        </template>
        <template v-slot:table-head>
            <div @click="$refs.overview.setOrderBy('name')"
                 class="column pointer">
                {{ $t("Name") }}
            </div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>
                    {{ item.name }}
                </span>
            </div>
        </template>
        <template v-slot:no-more>
            {{ $t('These were all modules') }}
        </template>
        >
    </base-overview>
</template>

<script>
    import BaseOverview from '../../components/BaseOverview';

    export default {
        name: 'ModuleIndex',
        components: {
            BaseOverview,
        },
        methods: {
            getRoute(module) {
                return {
                    name: 'modules.edit',
                    params: {
                        moduleId: module.id,
                    },
                };
            },
        },
    };
</script>
