<template>
    <form-panel @overlay-clicked="close"
                @submit="submit"
                ref="panel">
        <template v-slot:header>
            <h1>
                {{ $t('Create module') }}
            </h1>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="name">
                    {{ $t("Name") }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="module.name">
            </div>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ $t('Store') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from '../../components/FormPanel';

    export default {
        name: 'ModuleCreate',
        components: {
            FormPanel,
        },
        data() {
            return {
                module: {},
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'modules.index',
                    params: this.$route.params,
                });
            },
            submit() {
                this.$http.post('modules/create', this.module)
                    .then(() => {
                        this.close();
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        },
    };
</script>
