<template>
    <div>
        <router-view />
        <div class="search-container">
            <div class="searchbar">
                <img src="/images/icons/search-icon.svg" title="search-icon" />
                <input
                        :placeholder="$t('Search..')"
                        :title="$t('Search..')"
                        @input="setSearch"
                        name="search"
                        v-model="search"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="table">
                    <div class="row header">
                        <div class="column"></div>
                        <div class="column">{{ $t('Name') }}</div>
                        <div class="column">{{ $t('Parameter name') }}</div>
                        <div class="column">{{ $t('Form name') }}</div>
                    </div>
                    <div
                            :key="parameter.id"
                            class="row"
                            v-for="parameter in parameters"
                            @click="toggleParameter(parameter)"
                    >
                        <div class="column">
                            <input
                                    type="checkbox"
                                    class="checkbox"
                                    :checked="frame.parameters.some(e => e.id === parameter.id)"
                            />
                        </div>
                        <div class="column">{{ parameter.name }}</div>
                        <div class="column">{{ parameter.parameter_name }}</div>
                        <div class="column">{{ parameter.form.name }}</div>
                    </div>
                    <infinite-loading @infinite="loadParameters" ref="infiniteLoading" spinner="spiral">
                        <div class="no-results" slot="no-more">{{ $t('These were all parameters') }}</div>
                    </infinite-loading>
                </div>
            </div>
            <div class="column">
                <div class="table">
                    <div class="row header">
                        <div class="column"></div>
                        <div class="column">{{ $t('Name') }}</div>
                        <div class="column">{{ $t('Parameter name') }}</div>
                        <div class="column">{{ $t('Form name') }}</div>
                    </div>
                    <draggable v-model="frame.parameters" draggable=".row">
                        <div :key="parameter.id" class="row" v-for="(parameter,index) in frame.parameters">
                            <div class="column">{{ index }}</div>
                            <div class="column">{{ parameter.name }}</div>
                            <div class="column">{{ parameter.parameter_name }}</div>
                            <div class="column">{{ parameter.form.name }}</div>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseOverview from "../../components/BaseOverview";
    import Draggable from "vuedraggable";

    export default {
        name: "WindowFrameParameters",
        components: {
            BaseOverview,
            Draggable
        },
        data() {
            return {
                search: "",
                parameters: [],
                page: 1
            };
        },
        methods: {
            loadParameters($state) {
                this.$http
                    .get("/parameters", { page: this.page, search: this.search })
                    .then(({ data }) => {
                        if (data.data.length === 0) $state.complete();
                        this.parameters = this.parameters.concat(data.data);
                        this.page++;
                        $state.loaded();
                    });
            },
            setSearch: debounce(function _(e) {
                this.parameters = [];
                this.page = 1;
                this.$refs.infiniteLoading.stateChanger.reset();
            }, 500),
            toggleParameter(parameter) {
                let parameters = this.frame.parameters;
                let index = parameters.findIndex(e => e.id === parameter.id);
                if (index === -1) {
                    parameters.push(parameter);
                } else {
                    parameters.splice(index, 1);
                }
            }
        },
        computed: {
            frame: {
                get() {
                    return this.$parent.frame;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .columns {
        display : flex;

        .column {
            width   : 50%;
            padding : 10px;

            .table .column:nth-child(1) {
                width : 30px;

                .checkbox {
                    position : absolute;
                    width    : 20px;
                }
            }
        }
    }
</style>