export const FORM_ELEMENT_TYPES = {
    select: 'select',
    input: 'input',
    checkbox: 'checkbox',
    calculation: 'calculation',
};

export default class FormElement {
    constructor(data = {}) {
        this.label = data.label || '';
        this.type = data.type || undefined;
        this.default = data.default || undefined;
        this.min = data.min || null;
        this.max = data.max || null;
        this.segment = data.segment || undefined;
        this.options = data.options || [];
        this.updatesParameter = data.updatesParameter || true;
        this.visibleWhen = data.visibleWhen || [];
        this.alwaysVisible = data.alwaysVisible || true;

        this.children = (data.children || []).map(child => new FormElement(child)) || [];
    }
}
