<template>
    <base-modal ref="modal">
        <template v-slot:header>
            {{ ucf$t("projects.agreed") }}
        </template>
        <p>
            {{ ucf$t("projects.agreed_description") }}
        </p>
        <template v-slot:footer>
            <button @click="close"
                    class="primary">
                {{ $t('Ok') }}
            </button>
        </template>
    </base-modal>
</template>

<script>
import BaseModal from "../../components/BaseModal";

export default {
    name: "ProjectApprovedModal",
    components: {
        BaseModal
    },
    methods: {
        close() {
            this.$router.push({
                name: 'projects.show',
                params: {
                    projectId: this.$route.params.projectId
                },
            });
        }
    },
    mounted() {
        this.$refs.modal.open();
    },
}
</script>

<style scoped>

</style>