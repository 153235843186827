<template>
    <base-overview ref="overview"
                   slug="categories">
        <template v-slot:head>
            <h1>{{ $t("Categories") }}</h1>
            <router-link :to="{ name: 'categories.create', query: $route.query }"
                         class="btn primary">
                {{ $t("Create category") }}
            </router-link>
        </template>
        <template v-slot:table-head>
            <div class="column pointer"
                 @click="$refs.overview.setOrderBy('name')">
                {{ $t("Name") }}
            </div>
            <div class="column"></div>
        </template>
        <template v-slot:list-row="{item}">
            <category-row :key="item.id"
                          :category="item" />
        </template>
        <template v-slot:no-more>
            {{ $t('These were all categories') }}
        </template>
    </base-overview>
</template>

<script>
    import BaseOverview from '../../components/BaseOverview';
    import CategoryRow from "./Partials/CategoryRow";

    export default {
        name: 'CategoryIndex',
        components: {
            CategoryRow,
            BaseOverview,
        },
    };
</script>
