import { roles } from '../../configuration/variables';
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import FormCreate from '../../views/Form/FormCreate';
import FormEdit from '../../views/Form/FormEdit';
import ModelBoundView from '../utils/ModelBoundView';
import FormService from '../../services/FormService';
import IndexView from '../utils/IndexView';
import FormIndex from '../../views/Form/FormIndex';

export default [
    {
        name: 'forms.index',
        path: '/forms',
        component: IndexView(new FormService(), FormIndex),
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
        },
    },
    {
        name: 'forms.create',
        path: '/forms/create',
        component: FormCreate,
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
        },
    },
    {
        name: 'forms.edit',
        path: '/forms/:formId/edit',
        component: ModelBoundView(new FormService(), FormEdit),
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
        },
    },
];
