<template>
    <form-panel @overlay-clicked="close"
                @submit="submit"
                panel-class="sidebar price-matrix"
                ref="panel">
        <template v-slot:header>
            <h1>
                {{ $t('Upscale prices') }}
            </h1>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="percentage">
                    {{ $t('Percentage') }}
                </label>
                <input v-model="percentage" id="percentage">
            </div>
            <div class="form-group">
                <label for="remark">
                    {{ $t('Remark') }}
                </label>
            </div>
            <textarea v-model="remark" id="remark"></textarea>
            <button class="primary"
                    type="submit">
                {{ $t('Upscale prices') }}
            </button>
        </template>
        <template v-slot:footer>
            <base-overview ref="overview"
                           slug="/price-matrices/mutations"
                           :searchEnabled="false">
                <template v-slot:head>
                    <h2>
                        {{ $t('History') }}
                    </h2>
                </template>
                <template v-slot:table-head>
                    <div class="column">{{ $t('Percentage') }}</div>
                    <div class="column">{{ $t('Remark') }}</div>
                    <div class="column">{{ $t('Updated by') }}</div>
                    <div class="column">{{ $t('Date') }}</div>
                </template>
                <template v-slot:table-row="{item}">
                    <div class="column">
                        {{ item.percentage }}
                    </div>
                    <div class="column">
                        {{ item.remark }}
                    </div>
                    <div class="column">
                        {{ item.createdBy }}
                    </div>
                    <div class="column">
                        {{ item.createdAt }}
                    </div>
                </template>
                <template v-slot:no-more>
                    {{ $t('These were all price matrix mutations') }}
                </template>
            </base-overview>
        </template>
    </form-panel>
</template>

<script>
    import PriceMatrixInput from '../../components/PriceMatrixInput';
    import FormPanel from '../../components/FormPanel';
    import BaseCheckbox from '../../components/FormElements/BaseCheckbox';
    import BaseOverview from '../../components/BaseOverview';

    export default {
        name: "PriceMatrixUpscale",
        components: {
            BaseCheckbox,
            FormPanel,
            PriceMatrixInput,
            BaseOverview,
        },
        data() {
            return {
                percentage: 0,
                remark: "",
            }
        },

        methods: {
            close() {
                this.$router.replace({
                    name: 'price-matrices.index',
                    query: this.$route.query,
                });
            },
            submit() {
                const data = {
                    percentage: this.percentage,
                    remark: this.remark
                }
                this.$http.post(`/price-matrices/mutations`, data)
                    .then(() => {
                        this.$router.replace({
                            name: 'price-matrices.index',
                            params: this.$route.params,
                        });
                    })
                    .catch((e) => {
                        this.handleErrors(e.response);
                    });
            }
        }
    }
</script>

<style scoped>
    .full-width {
        width : 100%;
    }
</style>