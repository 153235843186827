<template>
    <transition name="fade">
        <div @mousedown.self.exact="overlayClicked"
             class="overlay"
             v-show="opened">
            <div :class="panelClass"
                 class="panel">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'BasePanel',
        props: {
            panelClass: {
                type: String,
                required: false,
                default() {
                    return '';
                },
            },
        },
        data() {
            return {
                opened: false,
            };
        },
        methods: {
            remount() {
                document.body.appendChild(this.$el);
            },
            open() {
                this.remount();
                this.opened = true;
                document.body.style.overflow = 'hidden';
            },
            close() {
                this.opened = false;
                document.body.style.overflow = null;
            },

            overlayClicked(e) {
                if (e.target.classList.contains('overlay')) {
                    this.$emit('overlay-clicked')
                }
            },

            beforeRouteLeave(to, from, next) {
                this.close();
                return next();
            },
        },
        mounted() {
            document.body.appendChild(this.$el);
        },

        beforeDestroy() {
            this.$el.remove();
        },
    };
</script>

<style lang="scss">
    .overlay {
        position         : fixed;
        z-index          : 1;
        top              : 0;
        left             : 0;
        display          : block;
        width            : 100vw;
        height           : 100vh;
        background-color : rgba(0, 0, 0, .16);
    }
</style>
