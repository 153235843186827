<template>
    <form-panel @overlay-clicked="close"
                @submit="submit"
                ref="panel">
        <template v-slot:header>
            <h1>
                {{ $t('Create %{name}', { name: 'Revit type' }) }}
            </h1>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="name">
                    {{ $t("Name") }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="formData.name">
            </div>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ $t('Store') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from "../../components/FormPanel";

    export default {
        name: "RevitTypeCreate",

        components: {
            FormPanel,
        },

        data() {
            return {
                formData: {}
            }
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'revit-types.index',
                });
            },

            submit() {
                const { formData } = this;

                this.$http.post('/revit-types/create', formData)
                    .then(() => {
                        this.close();
                        this.$root.$emit('RevitTypeChanged');
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },

        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        },
    }
</script>
