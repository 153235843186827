<template>
    <div class="form-element"
         :class="{hovered}">
        <div class="row">
            <big-input :id="`label-${uuid}`"
                       :name="`label-${uuid}`"
                       :label="$t('Label')"
                       v-model="formElement.label"
                       v-show="! (formElement.type || '').includes('calculation')" />
            <big-select :id="`type-${uuid}`"
                        :name="`type-${uuid}`"
                        :label="$t('Select a type')"
                        v-model="formElement.type"
                        :options="formElementTypes" />
        </div>
        <form-element-group-radio-fields v-if="formElement.type === 'radio'"
                                         v-model="formElement" />
        <form-element-group-checkbox-fields v-if="formElement.type === 'checkbox'"
                                            v-model="formElement" />
        <form-element-group-input-fields v-if="formElement.type === 'input'"
                                         v-model="formElement" />
        <form-element-group-calculations v-if="(formElement.type || '').includes('calculation')"
                                         v-model="formElement" />
        <form-element-group-select-fields v-if="(formElement.type || '').includes('segment')"
                                          v-model="formElement" />
        <big-input :id="`information-${uuid}`"
                   :name="`information-${uuid}`"
                   :label="$t('Extra information')"
                   :placeholder="$t('Extra information')"
                   v-model="formElement.information"
                   v-show="! (formElement.type || '').includes('calculation')" />
        <form-element-visibility v-if="showDeleteButton" :form-element="formElement" />

        <div class="children">
            <form-element-group v-for="(child, index) in formElement.children"
                                :key="`form-element-${uuid}-child-${index}`"
                                :form-element="child"
                                :segments="segments"
                                :form-element-types="formElementTypes"
                                @delete="removeChild(index)" />
        </div>
        <div class="row align-right"
             @mouseover="hovered = true"
             @mouseleave="hovered = false">
            <button type="button"
                    class="delete-child-button"
                    v-if="showDeleteButton"
                    @click="$emit('delete')">
                <img src="/images/icons/delete.svg"
                     alt="Delete icon">
            </button>
            <button type="button"
                    class="add-child-button"
                    @click="addChild">
                {{ $t('Add child element') }}
            </button>
        </div>
    </div>
</template>

<script>
    import FormElement from '../../models/FormElement';
    import BigInput from './BigInput';
    import BigSelect from './BigSelect';
    import FormElementGroupRadioFields from './FormElementGroupRadioFields';
    import FormElementGroupSelectFields from './FormElementGroupSelectFields';
    import FormElementGroupInputFields from './FormElementGroupInputFields';
    import FormElementGroupCheckboxFields from './FormElementGroupCheckboxFields';
    import FormElementGroupCalculations from './FormElementGroupCalculations';
    import FormElementVisibility from './FormElementVisibility';

    export default {
        name: 'FormElementGroup',
        components: {
            FormElementVisibility,
            BigInput,
            BigSelect,
            FormElementGroupInputFields,
            FormElementGroupRadioFields,
            FormElementGroupCalculations,
            FormElementGroupSelectFields,
            FormElementGroupCheckboxFields,
        },
        props: {
            formElement: {
                type: Object,
                required: true,
            },
            segments: {
                type: Array,
                required: true,
            },
            formElementTypes: {
                type: Array,
                required: true,
            },
            showDeleteButton: {
                type: Boolean,
                required: false,
                default() {
                    return true;
                },
            },
        },
        data() {
            return {
                hovered: false,
            };
        },
        methods: {
            addChild() {
                this.formElement.children.push(new FormElement());
            },
            removeChild(index) {
                this.formElement.children.splice(index, 1);
            },
        },
        computed: {
            optionTypes: {
                get() {
                    return this.$parent.optionTypes;
                },
            },
            calculations: {
                get() {
                    return this.$parent.calculations;
                },
            },
        },
        watch: {
            'formElement.type': {
                handler(type) {
                    this.formElement.updatesParameter = true;
                    this.formElement.default = null;
                    this.formElement.segment = null;
                    this.formElement.options = [];

                    if (type.includes('segment') || type.includes('calculation')) {
                        this.formElement.segment = parseInt(type.split('-')[1]);

                        if (type.includes('segment')) {
                            this.formElement.options = this.optionTypes.find(optionType => optionType.class === this.segments[this.formElement.segment].optionType)
                                .data
                                .map(option => ({
                                    name: option.name,
                                    value: option.value,
                                }));
                        }
                    }
                },
            },
        },
    };
</script>
