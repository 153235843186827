<template>
    <div class="form-elements-form"
         v-if="!empty(form.id) && !empty(optionTypes)">
        <div class="head">
            <h1>
                {{ $t('Edit form') }}
            </h1>
            <button class="secondary"
                    @click="confirmDestroy"
                    type="button">
                {{ $t('Delete form') }}
            </button>
        </div>
        <base-input id="name"
                    name="name"
                    :label="$t('Name')"
                    v-model="form.name" />
        <div class="segmenting">
            <h2>
                {{ $t('Segments') }}
            </h2>
            <segment-group v-for="(segment,index) in form.segments"
                           :key="`${uuid}-segment-${index}`"
                           :segment="segment"
                           :option-types="optionTypes"
                           @delete="form.segments.splice(index, 1)" />
            <button type="button"
                    class="add-segment-button"
                    @click="form.segments.push({})">
                {{ $t('Add segment') }}
            </button>
        </div>
        <div class="form">
            <h2>
                {{ $t('Form') }}
            </h2>
            <form-element-group :form-element="form.formElement"
                                :segments="form.segments"
                                :show-delete-button="false"
                                :form-element-types="formElementTypes" />
        </div>

        <button class="primary"
                @click="store"
                type="button">
            {{ $t('Store') }}
        </button>

        <confirm-modal ref="confirmDeleteModal"
                       :title="$t('Delete form')"
                       :question="$t('Are your sure you want to delete this form?')"
                       :cancel-text="$t('Cancel')"
                       :confirm-text="$t('Confirm')" />
    </div>
</template>

<script>
import BaseInput from '../../components/FormElements/BaseInput';
import FormElementGroup from '../../components/FormElements/FormElementGroup';
import SegmentGroup from '../../components/Segments/SegmentGroup';
import ConfirmModal from '../../components/ConfirmModal';

export default {
    name: 'FormCreate',
    components: {
        ConfirmModal, SegmentGroup, FormElementGroup, BaseInput,
    },
    data() {
        return {
            optionTypes: [],
            calculations: [],
        };
    },
    methods: {
        store() {
            this.$http.post(`/forms/${this.form.id}/edit`, this.deepClone(this.form))
                .then(() => {
                    this.$router.replace({ name: 'forms.index' });
                });
        },
        confirmDestroy() {
            this.$refs.confirmDeleteModal.confirm()
                .then(this.destroy)
                .catch((e) => {
                    this.handleErrors(e.response);
                });
        },
        destroy() {
            this.$http.delete(`/forms/${this.form.id}`)
                .then(() => {
                    this.$router.push({ name: 'forms.index' });
                })
                .catch((e) => {
                    this.handleErrors(e.response);
                });
        },
    },
    computed: {
        formElementTypes: {
            get() {
                const baseTypes = [
                    {
                        value: 'input',
                        name: 'Input field',
                    },
                    {
                        value: 'checkbox',
                        name: 'Checkbox',
                    },
                    {
                        value: 'radio',
                        name: 'Radio buttons',
                    },
                ];

                this.form.segments.forEach((segment, index) => {
                    if (!segment || !segment.optionType) {
                        return;
                    }

                    baseTypes.push({
                        value: `segment-${index}-${segment.optionType}`,
                        name: this.$t('Segment:') + ' ' + this.$t(`${this.optionTypes.find(optionType => optionType.class === segment.optionType).translation}`),
                    });

                    baseTypes.push({
                        value: `calculation-${index}-${segment.optionType}`,
                        name: this.$t('Calculation:') + ' ' + this.$t(`${this.optionTypes.find(optionType => optionType.class === segment.optionType).translation}`),
                    });
                });

                return baseTypes;
            },
        },
    },
    beforeMount() {
        this.$http.get('/parameters/revit-options')
            .then((response) => {
                this.optionTypes = response.data;
            });

        this.$http.get('/calculations')
            .then((response) => {
                this.calculations = response.data.data;
            });
    },
};
</script>

<style scoped
       lang="scss">
@import "../../../sass/variables";

.head {
    display   : flex;
    flex-flow : row nowrap;

    h1 {
        flex-grow : 1;
    }

    .secondary {
        display       : block;
        margin        : auto 0;
        border-radius : 4px;
        outline       : none;
        height        : 48px;
        padding       : 0 24px;
        line-height   : 48px;
    }
}
</style>
