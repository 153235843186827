<template>
    <div>
        <table v-if="columns.length > 0 || rows.length > 0"
               :class="{'without-vertical-axis': !verticalAxisEnabled}"
               class="matrix-table">
            <thead>
                <tr>
                    <th v-show="verticalAxisEnabled"
                        class="">
                        &nbsp;
                    </th>
                    <th v-for="(column, index) in columns"
                        class=""
                        @click="openHeight(column, index, $event)">
                        <span>{{ column.height }}</span>
                    </th>
                    <th v-if="addHeightEnabled">
                        <button class="add-to-matrix-button"
                                type="button"
                                @click="openHeight()">
                            {{ ucf$t('price-matrices.add-height') }}
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in rows">
                    <th v-show="verticalAxisEnabled"
                        class=""
                        @click="openWidth(row, index, $event)">
                        <span>{{ row.width }}</span>
                    </th>
                    <td v-for="cell of getRowCells(row)"
                        class="price-matrix-cell">
                        <span v-if="showEuroSign">&euro;</span>
                        <input :id="`${row.min_width} - ${row.max_width}/${cell.min_height} - ${cell.max_height}`"
                               :value="cell.price"
                               pattern="[0-9,\.]+"
                               placeholder="..."
                               type="text"
                               @focus="onFocusElement"
                               @input="onCellInput"
                               @keyup.down.prevent="onDownPressed"
                               @keyup.up.prevent="onUpPressed">
                        <span v-if="!showEuroSign">%</span>
                    </td>
                </tr>
                <tr v-if="addWidthEnabled && verticalAxisEnabled">
                    <th>
                        <button class="add-to-matrix-button"
                                type="button"
                                @click="openWidth()">
                            {{ ucf$t('price-matrices.add-width') }}
                        </button>
                    </th>
                </tr>
            </tbody>
        </table>

        <base-panel ref="panel"
                    panel-class="modal price-matrix-table-modal"
                    @overlay-clicked="panel.close">
            <div v-if="panelData.exists"
                 class="header">
                {{
                    panelData.isHeight
                        ? ucf$t('Change height measure')
                        : ucf$t('Change width measure')
                }}
                <div class="close"
                     @click="panel.close">
                    <img alt="close"
                         src="/images/icons/close-icon.svg">
                </div>
            </div>
            <div v-else
                 class="header">
                {{
                    panelData.isHeight
                        ? ucf$t('price-matrices.add-to-matrix-height')
                        : ucf$t('price-matrices.add-to-matrix-width')
                }}
                <div class="close"
                     @click="panel.close">
                    <img alt="close"
                         src="/images/icons/close-icon.svg">
                </div>
            </div>
            <div class="body" v-if="panel">
                <div class="form-group">
                    <label for="min">
                        {{ ucf$t('global.min') }}
                    </label>
                    <input id="min"
                           ref="minInput"
                           v-model="panelData.min"
                           :placeholder="ucf$t('global.min')"
                           name="min"
                           type="text"
                           @keyup.enter.prevent="onPanelPressEnter">
                </div>
                <div class="form-group">
                    <label for="max">
                        {{ ucf$t('global.max') }}
                    </label>
                    <input id="max"
                           v-model="panelData.max"
                           :placeholder="ucf$t('global.max')"
                           name="max"
                           type="text"
                           @keyup.enter.prevent="onPanelPressEnter">
                </div>
            </div>
            <div class="footer">
                <button :class="panelData.exists ? 'primary' :'secondary'"
                        type="button"
                        @click="addToMatrixAndClose">
                    {{
                        panelData.exists ? ucf$t('price-matrices.update-in-matrix') :
                            ucf$t('price-matrices.add-to-matrix-and-close')
                    }}
                </button>
                <button v-show="!panelData.exists"
                        class="primary"
                        type="button"
                        @click="addToMatrix">
                    {{ ucf$t('price-matrices.add-to-matrix') }}
                </button>
            </div>
        </base-panel>
    </div>
</template>

<script>
    import BasePanel from './BasePanel';

    export function column(min, max, label = null) {
        return {
            height: label ?? `${min} - ${max}`,
            min_height: min,
            max_height: max,
        };
    }

    export function row(min, max, label = null) {
        return {
            width: label ?? `${min} - ${max}`,
            min_width: min,
            max_width: max,
        };
    }

    export default {
        name: 'PriceMatrixInput',
        components: {BasePanel},
        props: {
            verticalAxisEnabled: {
                type: Boolean,
                required: false,
                default: () => {
                    return false;
                },
            },
            addHeightEnabled: {
                type: Boolean,
                required: false,
                default: () => {
                    return true;
                },
            },
            addWidthEnabled: {
                type: Boolean,
                required: false,
                default: () => {
                    return true;
                },
            },
            showEuroSign: {
                type: Boolean,
                required: false,
                default: () => {
                    return true;
                },
            },
        },
        params: {
            translationPrefix: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                panelData: {
                    min: '',
                    max: '',
                    exists: false,
                    isHeight: false,
                },
                priceMatrix: this.$attrs.value || {cells: []},
            };
        },
        methods: {
            openHeight(data = false, index = undefined) {
                if (!this.addHeightEnabled) {
                    return;
                }

                this.panelData = {
                    min: '',
                    max: '',
                    isHeight: true,
                    exists: false,
                };

                if (data !== false) {
                    this.panelData.min = data.min_height;
                    this.panelData.max = data.max_height;
                    this.panelData.exists = true;
                    this.panelData.index = index;
                }

                this.panel.open();
            },
            openWidth(data = false, index = undefined) {
                if (!this.addWidthEnabled) {
                    return;
                }

                this.panelData.isHeight = false;
                this.panelData = {
                    min: '',
                    max: '',
                    isHeight: false,
                    exists: false,
                };

                if (data !== false) {
                    this.panelData.min = data.min_width;
                    this.panelData.max = data.max_width;
                    this.panelData.exists = true;
                    this.panelData.index = index;
                }

                this.panel.open();
            },
            addToMatrixAndClose() {
                this.addToMatrix();
                this.panel.close();
            },
            addToMatrix() {
                if ((this.empty(this.panelData.min) && this.panelData.min !== 0) || this.empty(this.panelData.max)) {
                    this.$flash.flash(
                        this.panelData.isHeight
                            ? this.ucfirst(this.$t('price-matrices.fill-both-height'))
                            : this.ucfirst(this.$t('price-matrices.fill-both-width')),
                    );
                    return;
                }

                if (parseInt(this.panelData.min, 10) >= parseInt(this.panelData.max, 10)) {
                    this.$flash.flash(this.ucfirst(this.$t('price-matrices.min-less-than-max')));
                    return;
                }

                if (this.panelData.exists === true) {
                    this.updateInMatrix();
                    this.panelData = {
                        min: '',
                        max: '',
                        isHeight: this.panelData.isHeight,
                    };
                    return;
                }

                const prop = this.panelData.isHeight ? 'columns' : 'rows';
                const func = this.panelData.isHeight ? column : row;
                const currentArray = this[prop];
                const {min, max} = this.panelData;

                currentArray.push(func(parseInt(min, 10), parseInt(max, 10)));

                this.priceMatrix[prop] = currentArray;

                this.panelData = {
                    min: '',
                    max: '',
                    isHeight: this.panelData.isHeight,
                };
                this.$refs.minInput.focus();
            },
            updateInMatrix() {
                const prop = this.panelData.isHeight ? 'columns' : 'rows';
                const cellProp = this.panelData.isHeight ? 'height' : 'width';
                const func = this.panelData.isHeight ? column : row;
                const min = parseInt(this.panelData.min, 10);
                const max = parseInt(this.panelData.max, 10);

                this.priceMatrix.cells
                    .forEach((cell) => {
                        if (cell[`min_${cellProp}`] === this.priceMatrix[prop][this.panelData.index][`min_${cellProp}`]
                            && cell[`max_${cellProp}`] ===
                            this.priceMatrix[prop][this.panelData.index][`max_${cellProp}`]) {
                            cell[`min_${cellProp}`] = min;
                            cell[`max_${cellProp}`] = max;
                        }
                    });

                this.priceMatrix[prop][this.panelData.index] = func(min, max);
                this.$emit('input', this.priceMatrix.cells);

                this.panelData = {
                    min: '',
                    max: '',
                    isHeight: false,
                };
            },
            onPanelPressEnter() {
                if (this.panelData.exists === true) {
                    return;
                }

                this.addToMatrix();
            },
            onCellInput(e) {
                const [width, height] = e.target.id.split('/');
                const row = this.rows.find(r => `${r.min_width} - ${r.max_width}` === width);
                const column = this.columns.find(c => `${c.min_height} - ${c.max_height}` === height);

                if (this.empty(e.target.value)) {
                    let index = this.priceMatrix.cells.findIndex(cell => cell.min_height === column.min_height
                        && cell.max_height === column.max_height
                        && cell.min_width === row.min_width
                        && cell.max_width === row.max_width);

                    if (index < 0) {
                        return;
                    }

                    this.priceMatrix.cells.splice(index, 1);
                    this.$emit('input', this.priceMatrix.cells);
                    return;
                }

                const cell = this.priceMatrix.cells.find(c => c.min_height === column.min_height
                    && c.max_height === column.max_height
                    && c.min_width === row.min_width
                    && c.max_width === row.max_width);

                if (this.empty(cell)) {
                    this.priceMatrix.cells.push({
                        price: e.target.value,
                        min_width: row.min_width,
                        max_width: row.max_width,
                        min_height: column.min_height,
                        max_height: column.max_height,
                    });
                }
                else {
                    cell.price = e.target.value;
                }

                this.$emit('input', this.priceMatrix.cells);
            },
            onDownPressed(e) {
                const [width, height] = e.target.id.split('/');
                const rowIndex = this.rows.findIndex(r => r.width === width);

                if ((rowIndex + 1) >= this.rows.length) {
                    return;
                }

                const inputRow = this.rows[rowIndex + 1];
                const inputElement = document.getElementById(`${inputRow.width}/${height}`);

                inputElement.focus();
            },
            onUpPressed(e) {
                const [width, height] = e.target.id.split('/');
                const rowIndex = this.rows.findIndex(row => row.width === width);

                if (rowIndex === 0) {
                    return;
                }

                const inputRow = this.rows[rowIndex - 1];
                const inputElement = document.getElementById(`${inputRow.width}/${height}`);

                inputElement.focus();
            },
            onFocusElement(e) {
                e.target.setSelectionRange(0, e.target.value.length);
            },
            getRowCells(row) {
                return this.columns.map(column => this.getCell(row, column));
            },
            getCell(row, column) {
                const cells = this.priceMatrix.cells.filter(cell => cell.min_height === column.min_height
                    && cell.max_height === column.max_height
                    && cell.min_width === row.min_width
                    && cell.max_width === row.max_width);

                if (cells.length === 1) {
                    return cells[0];
                }

                return {
                    min_height: column.min_height,
                    max_height: column.max_height,
                    min_width: row.min_width,
                    max_width: row.max_width,
                };
            },
            setPriceMatrix(priceMatrix) {
                this.priceMatrix = priceMatrix;
            },
        },
        computed: {
            panel: {
                get() {
                    return {
                        open: () => {
                            this.$refs.panel.open();
                        },
                        close: () => {
                            this.$refs.panel.close();
                        },
                    };
                },
            },
            columns: {
                get() {
                    const columns = this.priceMatrix.columns || [];

                    return columns.sort((a, b) => {
                        if (a.min_height < b.min_height) {
                            return -1;
                        }
                        if (a.min_height > b.min_height) {
                            return 1;
                        }
                        return 0;
                    });
                },
            },
            rows: {
                get() {
                    const rows = this.priceMatrix.rows || [];

                    return rows.sort((a, b) => {
                        if (a.min_width < b.min_width) {
                            return -1;
                        }
                        if (a.min_width > b.min_width) {
                            return 1;
                        }
                        return 0;
                    });
                },
            },
        },
        mounted() {
        },
    };
</script>
