import snakeCase from 'lodash/snakeCase';
import pluralize from 'pluralize';

export default function IndexView(service, vueConfig = {}) {
    const entities = pluralize(service.getEntity());

    return {
        ...vueConfig,
        data() {
            const dataFunction = vueConfig.data || (() => {
            });
            const data = dataFunction();

            return {
                ...data,
                [entities]: [],
                search: '',
                page: 1,
                maxPage: 2,
                orderBy: {
                    field: `${snakeCase(entities)}.id`,
                    sequence: 'DESC',
                },
            };
        },
        methods: {
            ...vueConfig.methods,
            setOrderBy(field) {
                const sequence = (field === this.orderBy.field && this.orderBy.sequence === 'DESC')
                    ? 'ASC'
                    : 'DESC';

                this.orderBy = {
                    field,
                    sequence,
                };

                this.reset();
            },
            loadItems($state) {
                service.index({
                    page: this.page,
                    orderBy: this.orderBy.field,
                    sequence: this.orderBy.sequence,
                    search: this.search,
                })
                    .then((response) => {
                        if (response.data.length === 0) {
                            $state.complete();
                            return;
                        }

                        response.data.forEach((customer) => {
                            this[entities].push(customer);
                        });

                        this.maxPage = response.meta.last_page;
                        $state.loaded();
                    });
                this.page++;
            },
        },
        watch: {
            ...vueConfig.watch,
            search: {
                async handler(search) {
                    await this.$router.replace({
                        ...this.$route,
                        query: {
                            ...this.$route.query,
                            search,
                        },
                    });
                },
            },
        },
    };
}
