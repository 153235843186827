<template>
    <base-overview class="customer-container"
                   search-enabled
                   :tabs="tabs"
                   slug="customers/missing-isah">
        <template v-slot:head>
            <h1>
                {{ ucf$t("customers.customers") }}
            </h1>
            <router-link :to="{ name: 'customers.create', query: $route.query }"
                         class="btn primary">
                {{ ucf$t("customers.new") }}
            </router-link>
        </template>
        <template v-slot:table-head="{setOrderBy}">
            <div class="column pointer" @click="setOrderBy('customers.name')">
                {{ ucf$t("customers.name") }}
            </div>
            <div class="column pointer" @click="setOrderBy('users.first_name')">
                {{ ucf$t("customers.contact") }}
            </div>
            <div class="column" />
            <div class="column" />
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>
                    {{ item.name }}
                </span>
            </div>
            <div class="column">
                <span>
                    {{ item.contactPerson.fullName }}
                </span>
            </div>
            <div class="column font-light">
                <span>
                    {{ item.contactPerson.email }}
                </span>
            </div>
            <div class="column font-light">
                <span>
                    {{ item.contactPerson.phoneNumber }}
                </span>
            </div>
        </template>
    </base-overview>
</template>

<script>
    import BaseOverview from '../../components/BaseOverview';

    export default {
        name: 'CustomerMissingIsah',
        components: { BaseOverview },
        data() {
            return {
                tabs: [
                    {
                        route: {
                            name: 'customers.index',
                        },
                        title: this.$t('All customers'),
                    },
                    {
                        route: {
                            name: 'customers.missing-isah',
                        },
                        title: this.$t('Customers without ISAH'),
                    },
                ],
            };
        },
        methods: {
            getRoute(item) {
                return {
                    name: 'customers.show',
                    params: {
                        customerId: item.id,
                    },
                };
            },
        },
    };
</script>
