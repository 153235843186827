<template>
    <form-panel @overlay-clicked="close"
                @submit="submit"
                ref="formPanel">
        <template v-slot:header>
            <h1>
                {{ $t('Edit %{name}', { name: 'Revit type' }) }}
            </h1>
            <div class="edit"
                 @click="toggleDrop">
                <img alt="edit"
                     src="/images/icons/dots-icon.svg">
                <div :class="{'active' : showDrop}"
                     class="drop">
                    <router-link
                            :to="{ name: 'revit-types.delete', params: $route.params }"
                            tag="button">
                        {{ $t('Delete %{name}', { name: 'Revit type' }) }}
                    </router-link>
                </div>
            </div>
            <div class="name">
                <h2>
                    {{ revitType.name }}
                </h2>
            </div>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="name">
                    {{ $t("Name") }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="revitType.name">
            </div>
            <button class="primary"
                    type="submit">
                {{ $t('Store') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from '../../components/FormPanel';

    export default {
        name: 'RevitTypeEdit',

        components: {
            FormPanel,
        },

        data() {
            return {
                showDrop: false,
            };
        },

        methods: {
            toggleDrop() {
                this.showDrop = !this.showDrop;
            },

            close() {
                this.$router.replace({
                    name: 'revit-types.index',
                });
            },

            submit() {
                this.$http.post(`revit-types/${this.revitType.id}/edit`, this.revitType)
                    .then(() => {
                        this.close();
                        this.$root.$emit('RevitTypeChanged');
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },

        mounted() {
            this.$refs.formPanel.open();
        },

        beforeRouteLeave(to, from, next) {
            this.$refs.formPanel.beforeRouteLeave(to, from, next);
        },
    };
</script>
