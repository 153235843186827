<template>
    <transition name="fade">
        <div class="overview">
            <router-view />

            <div class="head">
                <h1>
                    {{ $t('Parameters') }}
                </h1>
                <router-link :to="{ name: `project-parameters.create` }"
                             class="primary with-margin-top"
                             tag="button">
                    {{ $t('Create parameter') }}
                </router-link>
            </div>
            <div class="table">
                <div class="row header">
                    <div class="column">
                        {{ $t('Name') }}
                    </div>
                    <div class="column">
                        {{ $t('Form name') }}
                    </div>
                </div>
                <draggable draggable=".row"
                           :list="parameters"
                           @change="updateOrder">
                    <router-link class="row"
                                 :key="`${parameter.id}_${parameter.name}_${parameter.form.name}`"
                                 :to="{
                                    name: `project-parameters.edit`,
                                    params: {
                                             projectParameterId: parameter.id,
                                         }
                                 }"
                                 v-for="(parameter) in parameters">
                        <div class="column">
                            {{ parameter.name }}
                        </div>

                        <div class="column">
                            {{ parameter.form.name }}
                        </div>
                    </router-link>
                </draggable>
            </div>
        </div>
    </transition>
</template>

<script>
    import Draggable from "vuedraggable";

    export default {
        name: "ProjectParameterIndex",

        components: {
            Draggable,
        },

        data() {
            return {
                parameters: []
            }
        },

        methods: {
            updateOrder() {
                const order = this.parameters.map(function (param, index) {
                    return {
                        param: param.id,
                        order: index + 1,
                    }
                });
                const data = {
                    parameters: order
                };

                this.$http.post('project-parameters/order', data)
            },

            getParameters() {
                this.$http.get('project-parameters')
                    .then((response) => {
                        this.parameters = response.data.data;
                    });
            },
        },

        beforeMount() {
            this.getParameters();
        }
    }
</script>