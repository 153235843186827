import auth from '../../middleware/_auth';
import role from '../../middleware/_role';
import {roles} from '../../../configuration/variables';
import ModelBoundView from '../../utils/ModelBoundView';
import RevitOptionIndex from '../../../views/RevitOptions/RevitOptionIndex';
import RevitTypeService from '../../../services/RevitTypeService';
import RevitOptionCreate from '../../../views/RevitOptions/RevitOptionCreate';
import RevitOptionService from '../../../services/RevitOptionService';
import RevitOptionEdit from '../../../views/RevitOptions/RevitOptionEdit';
import RevitOptionDelete from '../../../views/RevitOptions/RevitOptionDelete';

export default [
    {
        name: 'revit-options.index',
        path: '/revit-options/:revitTypeId',
        component: RevitOptionIndex,
        children: [
            {
                name: 'revit-options.create',
                component: RevitOptionCreate,
                path: 'create',
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'revit-options.edit',
                path: ':revitOptionId/edit',
                component: ModelBoundView(new RevitOptionService(),
                    RevitOptionEdit),
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'revit-options.delete',
                path: ':revitOptionId/delete',
                component: RevitOptionDelete,
                props: true,

                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
        ],
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
            forbiddenRoute: {name: 'revit-types.index'},
        },
    },
];
