<template>
    <div class="no-products">
        <img alt="no project"
             src="/images/no-project.svg">

        <h2>
            {{ ucf$t("projects.no_products_title") }}
        </h2>
        <p>
            {{ ucf$t("projects.no_products_description") }}
        </p>
        <div class="button-row">
            <a class="btn primary"
               href="/revit-plugin/download">
                {{ ucf$t("projects.revit_sync_button") }}
            </a>
            <button class="secondary">
                {{ ucf$t("projects.webshop_button") }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "empty-state"
    }
</script>