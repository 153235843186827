<template>
    <base-overview slug="forms"
                   ref="overview">
        <template v-slot:head>
            <h1>{{ $t("Forms") }}</h1>
            <router-link :to="{ name: 'forms.create' }"
                         class="btn primary"
                         tag="button">
                {{ $t("Add form") }}
            </router-link>
        </template>
        <template v-slot:table-head>
            <div @click="$refs.overview.setOrderBy('name')"
                 class="column pointer">
                {{ $t("Name") }}
            </div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>
                    {{ item.name }}
                </span>
            </div>
        </template>
        <template v-slot:no-more>
            {{ $t('These were all forms') }}
        </template>
    </base-overview>
</template>

<script>
    import BaseOverview from "../../components/BaseOverview";

    export default {
        name: 'FormIndex',

        methods: {
            getRoute(item) {
                return {
                    name: 'forms.edit',
                    params: {
                        formId: item.id,
                    }
                };
            },
        },

        components: {
            BaseOverview
        },
    };
</script>