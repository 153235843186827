<template>
    <base-panel @overlay-clicked="$emit('overlay-clicked', $event)"
                class="sidebar-container"
                :panel-class="panelClass"
                ref="panel">
        <div class="header">
            <slot name="header"></slot>
        </div>
        <form @submit.prevent="$emit('submit', $event)"
              class="body">
            <div class="form">
                <slot name="form"></slot>
            </div>
            <div class="footer">
                <slot name="footer"></slot>
            </div>
        </form>
    </base-panel>
</template>

<script>
    import BasePanel from "./BasePanel";

    export default {
        name: "FormPanel",
        components: { BasePanel },
        props: {
            panelClass: {
                required: false,
                type: String,
                default: 'sidebar',
            },
        },
        methods: {
            open() {
                this.$refs.panel.open();
            },
            close() {
                this.$refs.panel.close();
            },
            beforeRouteLeave(to, from, next) {
                this.$refs.panel.beforeRouteLeave(to, from, next);
            },
        },

        mounted() {
            this.open();
        },
    };
</script>
