import Vue from 'vue';
import moment from 'moment';
import VueFlashes from 'vue-flashes';
import uniqueId from 'lodash/uniqueId';
import VueInfiniteLoading from 'vue-infinite-loading';
import NavBar from '../components/NavBar';
import router from '../router';
import i18n from '../trans';
import Api, { ApiClient } from './api';
import { VueAuthenticator } from './auth';
import Base from './Base';
import config from './config';
import Helpers from './methods';
import { formulaTypes, notificationTypes, operators, roles, statuses, } from './variables';

Vue.mixin({
    components: { 'nav-bar': NavBar, 'infinite-loading': VueInfiniteLoading },
    data() {
        return {
            uuid: uniqueId(),
            roles,
            statuses,
            notificationTypes,
            formulaTypes,
            operators,
        };
    },
    methods: Helpers,
    computed: {
        $http: {
            get() {
                return Api;
            },
        },
        authUser: {
            get() {
                return this.$root.$user;
            }
        },
    },
});

Vue.use(VueFlashes);
Vue.use(VueAuthenticator, config.auth);

moment.locale('nl');

export default {
    i18n,
    router,
    el: '#app',
    render: h => h(Base),
    data: {
        $user: { roles: [] },
        $notificationAmount: 0,
    },
    methods: {
        setUser() {
            const item = localStorage.getItem('webo_bim_user_data');
            this.$user = item !== 'undefined' && item !== null
                ? JSON.parse(item)
                : { roles: [] };

            if (typeof this.$children[0] !== 'undefined' && typeof this.$children[0].$refs.navbar !== 'undefined') {
                this.$children[0].$refs.navbar.updateUser();
            }
        },
        reloadNotifications() {
            if (!this.$auth.isAuthenticated()) {
                return;
            }

            this.$http.get('notifications/unread')
                .then((response) => {
                    this.$notificationAmount = response.data;
                });
        },
        reloadUser() {
            if (!this.$auth.isAuthenticated()) {
                return;
            }

            window.loadProfile()
                .then(() => {
                    this.setUser();
                });
        },
    },
    beforeMount() {
        this.setUser();
        this.reloadNotifications();
        this.reloadUser();

        ApiClient.handlers[401] = () => {
            this.$flash.flash(this.$t('You are not logged in'));
            this.$router.replace({ name: 'auth.login' });
        };
    },
    update() {
        this.$children[0].$refs.navbar.$forceUpdate()
    },
};
