import Service from "./Service";
import camelCase from "lodash/camelCase";

export default class CategoryService extends Service {
    constructor(name = 'category') {
        super('category');

        this.singular = name;
    }

    getEntity() {
        return camelCase(this.singular);
    }
};
