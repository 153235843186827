<template>
    <div class="form-element-fields">
        <div class="form-group">
            <vue-multiselect :close-on-select="false"
                             :options="optionTypeOptions"
                             :placeholder="$t('Select the options')"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             :id="`options-${uuid}`"
                             label="name"
                             multiple
                             name="options[]"
                             select-label=""
                             selected-label=""
                             show-labels
                             track-by="value"
                             v-model="formElement.options" />
        </div>
        <big-select :id="`default-${uuid}`"
                    :name="`default-${uuid}`"
                    :label="$t('Select a default value')"
                    v-model="formElement.default"
                    :options="formElement.options" />
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect';
    import BigSelect from './BigSelect';

    export default {
        name: 'FormElementGroupSelectFields',
        components: { BigSelect, VueMultiselect },
        props: {
            value: {},
        },
        computed: {
            optionTypes: {
                get() {
                    return this.$parent.optionTypes;
                },
            },
            segments: {
                get() {
                    return this.$parent.segments;
                },
            },
            optionType: {
                get() {
                    return this.optionTypes.find(optionType => optionType.class === this.segments[this.formElement.segment].optionType);
                },
            },
            optionTypeOptions: {
                get() {
                    return this.optionType.data.map(option => ({ name: option.name, value: option.value, image: option.image }));
                },
            },
            formElement: {
                get() {
                    return this.value;
                },
            },
        },
    };
</script>
