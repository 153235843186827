<template>
    <transition name="fade">
        <div class="overview">
            <div class="head">
                <slot name="head" />
            </div>
            <base-tabs :tabs="tabs" />
            <div v-if="searchEnabled"
                 class="search-container">
                <div class="searchbar">
                    <img src="/images/icons/search-icon.svg"
                         title="search-icon" />
                    <input :placeholder="$t('Search..')"
                           name="search"
                           @input="setSearch" />
                </div>
            </div>
            <slot name="table-title" />
            <div class="table"
                 :key="`infinite-scroll-${infiniteScrollId}`">
                <div :class="{'auto-layout': autoLayout}"
                     class="row header">
                    <slot :set-order-by="setOrderBy"
                          name="table-head" />
                </div>
                <transition-group name="list">
                    <template v-for="item in items">
                        <slot name="list-row"
                              :item="item">
                            <router-link :key="item.id"
                                         v-if="!$slots['list-row']"
                                         :class="{'auto-layout': autoLayout}"
                                         :to="typeof $parent.getRoute === 'function' ? $parent.getRoute(item) : {}"
                                         class="row">
                                <slot :item="item"
                                      name="table-row" />
                            </router-link>
                        </slot>
                    </template>
                </transition-group>
                <infinite-loading
                    ref="infiniteLoading"
                    :distance="400"
                    :identifier="infiniteScrollId"
                    spinner="spiral"
                    @distance="1"
                    @infinite="loadItems"
                >
                    <div slot="no-more"
                         class="no-results">
                        <slot name="no-more" />
                    </div>
                </infinite-loading>
            </div>

            <router-view></router-view>
        </div>
    </transition>
</template>

<script>
    import debounce from 'lodash/debounce';
    import BaseTabs from './BaseTabs';

    export default {
        name: 'BaseOverview',
        components: { BaseTabs },
        props: {
            slug: {
                required: true,
                type: String,
            },
            tabs: {
                required: false,
                type: Array,
                default() {
                    return [];
                },
            },
            defaultSearchColumn: {
                required: false,
                type: String,
                default() {
                    return 'id';
                },
            },
            defaultSearchSequence: {
                required: false,
                type: String,
                default() {
                    return 'DESC';
                },
            },
            searchEnabled: {
                required: false,
                type: Boolean,
                default() {
                    return true;
                },
            },
            autoLayout: {
                required: false,
                type: Boolean,
                default() {
                    return true;
                },
            },
        },
        data() {
            return {
                search: '',
                page: 1,
                maxPage: 2,
                items: [],
                orderBy: {
                    field: null,
                    sequence: null,
                },
                infiniteScrollId: 1,
            };
        },
        methods: {
            reset() {
                this.page = 1;
                this.maxPage = 2;
                this.items = [];
                this.infiniteScrollId += 1;
            },
            setOrderBy(field) {
                const sequence =
                          field === (this.orderBy.field || this.defaultSearchColumn) && (this.orderBy.sequence || this.defaultSearchSequence) === 'DESC'
                              ? 'ASC'
                              : 'DESC';

                this.orderBy = {
                    field,
                    sequence,
                };

                this.reset();
            },
            setSearch: debounce(function _(e) {
                this.search = e.target.value;
                this.reset();
                this.$emit('input', this.search);
            }, 500),

            loadItems($state) {
                if (!this.$auth.isAuthenticated()) {
                    $state.complete();
                    return;
                }

                this.$http
                    .get(this.slug, {
                        page: this.page,
                        orderBy: this.orderBy.field || this.defaultSearchColumn,
                        sequence: this.orderBy.sequence || this.defaultSearchSequence,
                        search: this.search,
                    })
                    .then(({ data }) => {
                        if (data.data.length === 0) {
                            $state.complete();
                        }

                        this.items = [...this.items, ...data.data];
                        this.maxPage = data.meta.last_page;
                        $state.loaded();
                    });
                this.page++;
            },
        },
        watch: {
            $route: {
                handler(to, from) {
                    if (!to.name.includes('create') && !to.name.includes('edit')) {
                        this.reset();
                    }
                },
            },
        },
    };
</script>
