<template>
    <form-panel
            @overlay-clicked="close"
            @submit.prevent="submit"
            class="sidebar-container"
            panel-class="sidebar"
            ref="panel"
    >
        <template v-slot:header>
            <h1>{{ $t('Create conversion') }}</h1>
        </template>
        <template v-slot:form>
            <h3>{{ $t('From') }}</h3>
            <div class="form-group">
                <label for="from_parameter">{{ $t('Parameter') }}</label>
                <select id="from_parameter" name="from_parameter" v-model="update.from_parameter_id">
                    <option
                            :value="parameter.id"
                            v-for="parameter of parameters"
                            :key="parameter.id"
                    >{{ parameter.name }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="from_module">{{ $t('Module') }}</label>
                <select id="from_module" name="from_module" v-model="update.from_module_id">
                    <option :value="module.id" v-for="module of modules" :key="module.id">{{ module.name }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="from_value">{{ $t('Value') }}</label>
                <input
                        id="from_value"
                        name="from_value"
                        ref="from_value"
                        type="text"
                        v-model="update.from_value"
                />
            </div>
            <h3>{{ $t('To') }}</h3>
            <div class="form-group">
                <label for="to_parameter">{{ $t('Parameter') }}</label>
                <select id="to_parameter" name="to_parameter" v-model="update.to_parameter_id">
                    <option
                            :value="parameter.id"
                            v-for="parameter of parameters"
                            :key="parameter.id"
                    >{{ parameter.name }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="to_module">{{ $t('Module') }}</label>
                <select id="to_module" name="to_module" v-model="update.to_module_id">
                    <option :value="module.id" v-for="module of modules" :key="module.id">{{ module.name }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="to_value">{{ $t('Value') }}</label>
                <input id="to_value" name="to_value" ref="to_value" type="text" v-model="update.to_value" />
            </div>

            <div class="form-group">
                <label for="warning">{{ $t('Warning') }}</label>
                <textarea
                    id="warning"
                    name="warning"
                    ref="warning"
                    type="text"
                    v-model="update.warning"
                />
            </div>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ $t('Save') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from "../../components/FormPanel";

    export default {
        name: "ParameterLibraryCreate",
        components: { FormPanel },
        data() {
            return {
                update: {},
                parameters: [],
                modules: []
            };
        },

        methods: {
            submit() {
                this.$http.post("parameter-updates", this.update).then(({ data }) => {
                    this.$parent.items.unshift(data.data);
                    this.update = {};
                }).catch((e) => {
                    this.handleErrors(e.response);
                });
            },

            close() {
                this.$router.replace({
                    name: "parameter-library.conversions"
                });
            }
        },

        beforeMount() {
            this.$http
                .get("/parameters", { take: 0 })
                .then(({ data }) => (this.parameters = data.data));
            this.$http
                .get("/modules", { take: 0 })
                .then(({ data }) => (this.modules = data.data));
        },

        mounted() {
            this.$refs.panel.open();
        },

        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        }
    };
</script>
