<template>
    <div class="nav-sub-dropdown">
        <div class="dropdown-item"
             @click="toggle"
             style="cursor:pointer;">
            <slot name="title" />
        </div>

        <div class="sub-dropdown"
             v-show="open">
            <slot name="dropdown" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NavBarSubDropdown',
        props: {
            forceOpen: {
                type: Boolean,
                required: false,
                default() {
                    return false;
                },
            },
        },
        data() {
            return {
                isOpened: false,
            };
        },
        computed: {
            open: {
                get() {
                    return this.forceOpen || this.isOpened;
                },
            },
        },
        methods: {
            toggle() {
                this.isOpened = !this.isOpened;
            },
        },
    };
</script>
