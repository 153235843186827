<template>
    <form-panel @overlay-clicked="close"
                @submit="submit"
                ref="formPanel">
        <template v-slot:header>
            <h1>
                {{ $t("Notification emails") }}
            </h1>
            <div class="name">
                <h2>
                    {{ $t("Emails that will receive accepted notification") }}
                </h2>
            </div>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="emails">
                    {{ $t(", separated emails") }}
                </label>
                <input id="emails"
                       name="emails"
                       ref="emails"
                       type="text"
                       placeholder="info@webo.nl,service@webo.nl"
                       v-model="emails">
            </div>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ $t('Store') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from "../../../components/FormPanel";

    export default {
        name: "EditProjectAcceptationEmails",

        data() {
            return {
                emails: ''
            }
        },

        components: {
            FormPanel
        },

        methods: {
            submit() {
                this.$http.post('settings/project-accepted-notification-emails', {
                    'emails': this.emails
                })
                    .then((response) => {
                        this.$router.back();
                    });
            },

            close() {
                this.$router.back();
            },
        },

        beforeMount() {
            this.$http.get('settings/project-accepted-notification-emails')
                .then((response) => {
                    this.emails = response.data.data;
                });
        },

        beforeRouteLeave(to, from, next) {
            this.$refs.formPanel.beforeRouteLeave(to, from, next);
        },
    }
</script>
