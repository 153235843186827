<template>
    <div class="tabs"
         v-if="tabs.length > 0">
        <router-link :key="`${index}-tab`"
                     :to="route"
                     class="tab-item"
                     exact-active-class="active"
                     active-class=""
                     v-for="({route,title}, index) of tabs">
            {{ title }}
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'BaseTabs',
        props: {
            tabs: {
                required: false,
                type: Array,
                default() {
                    return [];
                },
            },
        },
    };
</script>
