<template>
    <base-overview slug="prioritizations"
                   ref="overview">
        <template v-slot:head>
            <h1>{{ $t('Prioritizations') }}</h1>
            <button class="btn secondary with-icon"
                    type="button"
                    ref="importButton"
                    @click="selectImportFile">
                <img src="/images/icons/upload.svg" />
                <input ref="importInput"
                       style="display:none;"
                       type="file"
                       @change="importFile">
            </button>
            <button class="btn secondary with-icon"
                    type="button"
                    @click="downloadExport">
                <img src="/images/icons/download.svg" />
            </button>
        </template>
        <template v-slot:table-head>
            <div class="column">
                {{ $t('Combination') }}
            </div>

            <div class="column">
                {{ $t('Prioritizations') }}
            </div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>
                    {{ item.combination }}
                </span>

            </div>
            <div class="column">
                <span>
                    {{ item.priorities }}
                </span>
            </div>
        </template>
        <template v-slot:no-more>
            {{ $t('These were all %{name}', {name: 'prioriteiten'}) }}
        </template>
    </base-overview>
</template>

<script>
    import BaseOverview from '../../components/BaseOverview';

    export default {
        name: 'PrioritizationIndex',
        components: {
            BaseOverview,
        },
        methods: {

            selectImportFile() {
                this.$refs.importInput.click();
            },
            importFile(e) {
                this.$refs.importButton.disabled = true;

                let files = Array.from(e.target.files);

                if (files.length === 0) {
                    this.$refs.importButton.disabled = false;

                    return;
                }

                const file = files[0];
                const formData = new FormData();
                formData.append('csv', file);

                this.$http.post('/prioritizations/import', formData)
                    .finally(() => {
                        this.$refs.importButton.disabled = false;

                        this.$refs.overview.reset();
                    });
            },
            downloadExport(e) {
                e.target.disabled = true;

                this.$http
                    .get('/prioritizations/export', {
                        responseType: 'blob',
                    })
                    .then((response) => {
                        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        const fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'export.csv');
                        document.body.appendChild(fileLink);

                        fileLink.click();

                        document.body.removeChild(fileLink);
                    })
                    .finally(() => e.target.disabled = true);
            },
        },
    };
</script>

