<template>
    <div class="form-group">
        <label for="fixed">{{ $t('Price') }}</label>
        <input id="fixed" name="fixed" type="text" v-model="formula.price" />
    </div>
</template>

<script>
    export default {
        props: ["formula"]
    };
</script>
