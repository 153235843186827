import auth from "../../middleware/_auth";
import role from "../../middleware/_role";
import { roles } from "../../../configuration/variables";
import ModelBoundView from "../../utils/ModelBoundView";
import RevitTypeIndex from "../../../views/RevitTypes/RevitTypeIndex";
import RevitTypeCreate from "../../../views/RevitTypes/RevitTypeCreate";
import RevitTypeService from "../../../services/RevitTypeService";
import RevitTypeEdit from "../../../views/RevitTypes/RevitTypeEdit";
import RevitTypeDelete from "../../../views/RevitTypes/RevitTypeDelete";

export default [
    {
        name: 'revit-types.index',
        path: '/revit-types',
        component: RevitTypeIndex,
        children: [
            {
                name: 'revit-types.create',
                component: RevitTypeCreate,
                path: 'create',
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'revit-types.edit',
                path: ':revitTypeId/edit',
                component: ModelBoundView(new RevitTypeService(), RevitTypeEdit),
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'revit-types.delete',
                path: ':revitTypeId/delete',
                component: RevitTypeDelete,
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
        ],
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
            forbiddenRoute: { name: 'revit-types.index' },
        },
    }
]