import moment from 'moment';
import pluralize from 'pluralize';
import { Flashes } from 'vue-flashes';
import Api from '../api';

/**
 * Set the number format
 *
 * @param price
 * @param decimals
 *
 * @returns string|Number
 */
export function numberFormat(price, decimals = 2) {
    if (decimals === 0) {
        return parseInt(price, 10);
    }

    return Number(price).toLocaleString('nl-NL', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
}

/**
 * Set the date format
 *
 * @param d
 * @param hourTranslation
 *
 * @returns string
 */
export function dateFormat(d, hourTranslation) {
    const date = moment(d);
    const now = moment();

    if (date.diff(now, 'days') >= -1) {
        return `${date.calendar()} ${hourTranslation}`;
    }

    return date.calendar();
}

/**
 * First letter uppercase
 *
 * @param string
 *
 * @returns {string}
 */
export function ucfirst(string) {
    if (empty(string)) {
        return string;
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Makes an deep clone of the object.
 *
 * @param object
 *
 * @returns {any}
 */
export function deepClone(object) {
    return JSON.parse(JSON.stringify(object));
}

/**
 * Checks if the project has a certain role.
 *
 * @param project
 * @param role
 *
 * @return {boolean}
 */
export function projectHasRole(project, role) {
    if (typeof project.role === 'undefined') {
        return false;
    }

    return project.role.id === role;
}

/**
 * Checks if value is empty.
 *
 * @param value
 *
 * @return {boolean}
 */
export function empty(value) {
    if (typeof value === 'function') {
        return false;
    }

    return typeof value === 'undefined'
        || value === null
        || (
            typeof value.length !== 'undefined'
            && value.length === 0
        )
        || value === 0;
}

/**
 * Debounces the call.
 *
 * @param {function} fn
 * @param {number} ms
 *
 * @return {boolean}
 */
export function debounce(fn, ms) {
    let timeout;

    return function executed(...args) {
        const context = this;

        function later() {
            timeout = null;
            fn.apply(context, args);
        }

        clearTimeout(timeout);

        timeout = setTimeout(later, ms);
    };
}

/**
 * @param {string} entity
 * @param {number|string} id
 * @param {Object} id
 *
 * @return {Promise}
 */
export function resolveBinding(entity, id, t, map) {
    const data = {
        t,
    };

    if (map[entity]) {
        return Api.get(map[entity].replace(`:${entity}`, id), data).then(response => response.data.data);
    }

    const plural = pluralize(entity)
        .replace(/\.?([A-Z])/g, (x, y) => `-${y.toLowerCase()}`)
        .replace(/^-/, '');

    return Api.get(`/${plural}/${id}`, data).then(response => response.data.data);
}

/**
 * First letter uppercase and translate
 *
 * @param {string} key
 *
 * @returns {string}
 */
export function ucf$t(key) {
    return ucfirst(this.$t(key));
}

/**
 * Append the given data to the given form data.
 */
export function appendToFormData(formData, key, value) {
    if (value == null) {
        return;
    }

    if (value instanceof File) {
        formData.append(key, value);
        return;
    }

    if (Array.isArray(value)) {
        value.forEach((val, index) => {
            appendToFormData(formData, `${key}[${index}]`, val);
        });
        return;
    }

    if (typeof value === 'object' && !empty(value)) {
        Object.entries(value).forEach(([k, val]) => {
            appendToFormData(formData, `${key}[${k}]`, val);
        });
        return;
    }

    formData.append(key, value);
}

window.debounce = debounce;

export default {
    numberFormat,
    dateFormat,
    appendToFormData,
    ucfirst,
    ucf$t,
    debounce,
    deepClone,
    projectHasRole,
    empty,
    /**
     * Shows flash messages for the response errors.
     *
     * @param {Response} response
     */
    handleErrors(response) {
        if (!empty(response.data.errors) && response.status === 422) {
            Object.entries(response.data.errors)
                .map(error => error[1])
                .flat()
                .forEach((error) => {
                    const index = Flashes.messages.findIndex(m => m.message === error);

                    if (index < 0) {
                        Flashes.flash(error, 'error');
                    }
                });
        }
    },
    showCustomError(message) {
        Flashes.flash(message, 'error')
    },
    login(user) {
        return new Promise((resolve, reject) => {
            this.$auth.login(user)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    Flashes.flash(this.$t('auth.failed'), 'error');
                    reject();
                });
        });
    },
};
