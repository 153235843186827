import CategoryIndex from '../../../views/Category/CategoryIndex';
import auth from '../../middleware/_auth';
import role from '../../middleware/_role';
import { roles } from '../../../configuration/variables';
import CategoryCreate from '../../../views/Category/CategoryCreate';
import ModelBoundView from '../../utils/ModelBoundView';
import CategoryService from '../../../services/CategoryService';
import CategoryEdit from '../../../views/Category/CategoryEdit';
import CategoryDelete from '../../../views/Category/CategoryDelete';

export default [
    {
        name: 'categories.index',
        path: '/categories',
        component: CategoryIndex,
        children: [
            {
                name: 'categories.create',
                path: 'create',
                component: ModelBoundView(new CategoryService('parentCategory'), CategoryCreate),
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'categories.subCategory.create',
                path: ':parentCategoryId/create',
                component: ModelBoundView(new CategoryService('parentCategory'), CategoryCreate),
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'categories.edit',
                path: ':categoryId/edit',
                component: ModelBoundView(new CategoryService(), CategoryEdit),
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'categories.delete',
                path: ':categoryId/delete',
                component: CategoryDelete,
                meta: {
                    isRevitOption: true,
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
        ],
        meta: {
            isRevitOption: true,
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    if (!to.params.category) {
                        return next({ name: 'categories.index' });
                    }

                    return next();
                },

            ],
            forbiddenRoute: { name: 'categories.index' },
        },
    },
];
