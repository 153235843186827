<template>
    <form-panel @overlay-clicked="close"
                @submit.prevent="submit"
                class="sidebar-container"
                panel-class="sidebar"
                ref="panel">
        <template v-slot:header>
            <h1>
                {{ $t('Create parameter') }}
            </h1>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="name">
                    {{ $t('Name') }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="parameter.name">
            </div>
            <div class="form-group">
                <label for="formName">
                    {{ $t('Form') }}
                </label>
                <select id="formName"
                        name="formName"
                        v-model="parameter.form">
                    <option :value="undefined" />
                    <option :value="form.id"
                            v-for="(form, index) of forms"
                            :key="index">
                        {{ form.name }}
                    </option>
                </select>
            </div>
        </template>
        <template v-slot:footer>
            <button class="primary"
                    type="submit">
                {{ $t('Create') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from "../../components/FormPanel";

    export default {
        name: "ProjectParameterCreate",
        components: { FormPanel },
        data() {
            return {
                parameter: {},
                forms: [],
            };
        },

        methods: {
            submit() {
                this.$http.post('project-parameters/create', this.parameter)
                    .then(() => {
                        this.close();
                    })
                    .catch((e) => {
                        this.handleErrors(e.response);
                    });
            },

            close() {
                this.$router.replace({
                    name: 'project-parameters.index',
                });
                this.$parent.getParameters();
            },
        },

        beforeMount() {
            this.$http.get('/forms')
                .then((response) => {
                    this.forms = response.data.data;
                });
        },

        mounted() {
            this.$refs.panel.open();
        },

        beforeRouteLeave(to, from, next) {
            this.$refs.panel.beforeRouteLeave(to, from, next);
        },
    }
</script>